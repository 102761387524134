@import'../../../../../../styles/darktheme/utilities/variables';

.all-wells-day-change {
    display: flex;
    justify-content: center;
    .document-body {
        &.table-opacity {
            background: rgba(0, 0, 0, 0.5);
            filter: blur(4px);
        }
    }
    .square-bracket-style {
        display: flex;
        padding: 4px 10px;
        border: 2px solid #12B76A;
        border-radius: 4px;
        font-size: 16px;
        font-weight: bold;
        color: #12B76A;
        align-items: center;
        justify-content: center;  
        min-width: 75px;      
        &.up {
            border: 2px solid #32D583;
            color: #32D583;
        }

        &.down {
            border: 2px solid #F97066;
            color: #F97066;
        }

        &.none {
            border: 2px solid #B8C5CC;
            color: #B8C5CC;
        }
        & .img-points{
            margin-right: 4px;
        }
        & .points {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            & .pvalue{
                margin-right: 4px;
            }
        }
    }
}

.health-score-circle {
    width: 40px;
    height: 40px;
    padding: 8px 0px 8px 0px;
    border-radius: 200px;
    background: var(--Darkmode-700, #131F2E);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #F7F9F9;
}

.blur-background{
    filter: blur(4px);
    overflow: hidden;
}

.well-name-link{
    color: var(--Primary-Indigo-400, var(--Primary-Indigo400, #60BFDA));
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;
}

.favourite-icon{
    cursor: pointer;
}

.intake-pressure {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    width: 100%;

    &__dropdown-field {
        display: flex;
        padding: 10px 14px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border: 1px solid var(--Dark-mode-300, $dark-light-shade3);
        background: var(--Dark-mode-700, $dark-secondary);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--Neutral-Grey-25, $dark-text-primary);

        option {
            display: flex;
            width: 320px;
            padding: 12px 16px;
            flex-direction: column;
            align-items: flex-start;
            border-bottom: 1px solid var(--Dark-mode-400, $primary-dark-border);
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
            font-family: Mulish;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            border-radius: 8px;
        }
    }

    &__input-group {
        display: inline-flex;
        transform: rotate(-0.221deg);
        padding: 10px 8px 10px 16px;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        position: relative;
        left: 0;
        border-radius: 8px;
        border: 1px solid var(--Dark-mode-400, $primary-dark-border);
        background: var(--Dark-mode-800, $dark-primary);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        width: 230px;
        height: 40px;

        & .input-date {
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
            font-family: Mulish;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            border: none;
            background: none;
            width: 200px;

            &:focus {
                outline: none;
            }
        }
    }

    &__dropdown {
        position: relative;
        width: 200px;
        img {
            position: relative;
            right: 1rem;
        }
    }

    &__dropdown-input-container {
        display: flex;
        padding: 10px 14px;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--Dark-mode-400, $primary-dark-border);
        background: var(--Darkmode-700, $dark-secondary);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        width: 100%;
        cursor: pointer;

        img.mr-3 {
            position: relative;
            pointer-events: visible;
            cursor: pointer;
        }
    }

    &__dropdown-input {
        border: none !important;
        cursor: pointer;
        background-color: transparent;
        color: #f7f9f9;

        &:focus {
            outline: none;
        }
    }

    &__dropdown-list {
        position: absolute;
        z-index: 2;
        top: 100%;
        left: 0;
        width: 200px;
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1px;
        border-radius: 8px;
        border: 1px solid var(--Dark-mode-400, $primary-dark-border);
        background: var(--Darkmode-800, $dark-primary);
        box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    }

    &__dropdown-item {
        display: flex;
        padding: 12px 16px;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        border-bottom: 1px solid var(--Dark-mode-400, $primary-dark-border);
        cursor: pointer;
        &:last-child {
            border: none;
        }
        &:hover, &:focus {
            background: var(--darkmode-700, $dark-secondary);
            &:first-child {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }         
            &:last-child {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }
    }

    &__header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
    }
}

.table-container thead.document-head, .table-container tfoot.document-foot {
    position: sticky;
    top: 0;
    right: 0;
    background: #fff;
    width: max-content;
    z-index: 10;
}
.document-head tr {
    height: 44px;
    th {
        padding: 16px 8px;
    }
}
.document-body tr {
    height: 64px;
        td {
            padding: 12px;
        }
}


.multiwell-table-wrapper {
    & .document-table {
        border-collapse: separate;
        border-spacing: 0;
    }
        & .table-container {
        & thead {
            &.document-head {
                z-index: 10;
                & th:nth-child(2) {
                    position: sticky;
                    left: 0;
                    z-index: 5;
                    background: #1c2837;
                }
            }
        }
            & tbody {
            td:nth-child(2) {
                position: sticky;
                left: 0;
                z-index: 5;
                background: #1c2837;
            }
        }
    }
    
}


.controlroom-snapblock {
    position: absolute;
    top: 0;
    left: -500%;
    width: 100%;
   // right: 0;
    & .grid-container.well-charts-container {
        background: transparent;
        padding: 0;
        & .layout {
            //background: transparent;
        }
    }

}
.uptime-downtime-graph-block {
    & .expand-btn{
        margin-left: 5px;
        opacity: 0;
        transition: all 0.3s;
    }
    &:hover .expand-btn{
            opacity: 1;
    }
}
.intake-pressure-graph-block {
    & .expand-btn{
        margin-left: 5px;
        opacity: 0;
        transition: all 0.3s;
    }
    &:hover .expand-btn{
            opacity: 1;
    }
}

.ReactModalPortal .ReactModal__Overlay--after-open .uptimeModal .modal-header h3 {
    color: #F7F9F9;
    font-weight: normal;
    font-size: 28px;
}