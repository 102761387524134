.ui-tip {
    display: flex;
    width: 29.0625rem;
    padding: 1rem;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 0.5rem;
    background: #00789E;
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.10);
}
.ui-tip-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
}
.ui-tip-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}
.ui-tip-body-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
}
.ui-tip-title {
    color: #FFF;
    font-family: Mulish;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.07rem;
}
.ui-tip-title-icon {
    width: 1.75rem;
    height: 1.75rem;
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(337deg) brightness(104%) contrast(103%); /* TODO: Remove filter when multiple image colors are added */
}
.ui-tip-description {
    color: #FFF;
    font-family: Mulish;
    font-size: 1.1875rem;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.02375rem;
    margin-top: auto;
}
.ui-tip-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: auto;
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(337deg) brightness(104%) contrast(103%); /* TODO: Remove filter when multiple image colors are added */
}
.ui-tip-button {
    font-family: Mulish;
    font-size: 1.1875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4375rem; /* 121.053% */
    letter-spacing: -0.02375rem;
    text-decoration-line: underline;
    color: #FFF;
    background: none;
    border: none;
}
.ui-tip-button-off {
    color: #C3E7F1;
    margin-left: auto; 
    margin-right: 0;
}
.ui-tip-button:hover{
    cursor: pointer;
}
.ui-tip-button-body {
    display: flex;
    align-items: flex-start;
    gap: 6rem;
    align-self: stretch;
}
.ui-tip-nav-button-body {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
}
.ui-tip-nav-button-item {
    display: flex;
    padding-right: 0px;
    justify-content: center;
    align-items: center;
}