.card-viewer-data {
  color: #323F46;
  font-family: Mulish;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
}
.card-viewer-title {
  width: 8.25rem;
  text-align: left;
  margin-left: 0.25rem;
}
.card-viewer-value {
  width: 7.0625rem;
  text-align: right;
  margin-right: 0.25rem;
}
