.toast-top-position {
  margin-top: 48px;
}

.toaster-wrapper {
  max-width: 55.4%;
  height: 68px;
  background: #001023;
  border: 1px solid #4A5463;
  z-index: 1000;

  .check-crcle {
    fill: #32D583
  }

  p {
    color: #32D583 !important;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 700;

  }

  img {
    width: 20px;
    height: 20px;
  }
}

.error-toast {
  p {
    color: #F97066 !important;
  }
}

.set-point-toast {
  margin-right: 30px;
  margin-bottom: 40px;

  .toaster-wrapper {
    max-width: none !important;
    width: auto;
  }

  p {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
  }

  .loading {
    color: #60BFDA !important
  }

  .read {
    color: #32D583 !important
  }

  .error {
    color: #F97066 !important
  }
}