@import '/src/styles//darktheme/utilities/variables';

.control-room-ticket-dropdown {
    width: 100%;

    .ticket-drop-down-btn {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #F7F9F9;

        .ticket-title {
            color: var(--Neutral-Grey-25, #F7F9F9);
            font-family: Mulish;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            /* 142.857% */
        }

        .select-title {
            color: var(--Neutral-Grey-25, #F7F9F9);
            text-align: right;
            font-family: Mulish;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            /* 142.857% */
        }
    }

    .ticket-filter-options {
        padding: 12px 20px;
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: start;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0%;
        color: #F7F9F9;
        border-radius: 8px;

        &:hover {
            background: var(--darkmode-700, #001023);
        }
    }

    .show-more-div {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0%;
        color: #60BFDA;
        cursor: pointer;

    }
}