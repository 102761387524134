.mobile-warning {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    z-index: 99999;
    background-color: #001023;
    color: #fff;
    font-size: 16px;
    padding-top: 170px;
    @media (min-width:769px){
        display: none;
    }
    & .img-warning {
        margin-bottom: 16px;
        max-width: 236px;
    }
    & .heading-warning {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        max-width: 220px;
        text-align: center;
        font-family: "Mulish", sans-serif;
    }
}