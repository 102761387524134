@import '../../../styles/darktheme/utilities/variables';

$nav-content-width: 330.92px;
$nav-content-height: 44px;

.navigation-bar-container {
  display: flex;
  flex-direction: row;
  position: fixed;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  width: 68px;
  max-width: 68px;
  left: 0;
  top: 0;
  z-index: 999;
  bottom: 0;
  background: #003747;
}

.navigation-bar-items {
  width: 365px;
  height: 100%;
  // left: 45px;
  gap: 16px;
  position: relative;
  z-index: 9;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.navigation-bar-items-content {
  width: 365px;
  height: 100%;
  padding: 36px 16px 16px 16px;
  gap: 16px;
}

.navigation-bar-top-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 24px;
  width: 4.25rem;
  // height: 211.26px;
  height: 100%;
  position: relative;
}

.navigation-bar-logo-image {
    width: 48px;
    height: 48px;
  margin-bottom: 1rem;
}

.navigation-bar-navigation-section-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  // padding: 0px 12px 0px 12px;
  gap: 8px;
}

.navigation-bar-slide-out-section-container {
  position: absolute;
  //margin-top: 160vh;
  text-align: center;
  bottom: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navigation-bar-navigation-image-container {
  padding: 6px;
  border-radius: 6px;
  border: solid #003747;

  &:hover {
    cursor: pointer;
    background-color: #226174;
  }
  &.clicked {
    border: solid skyblue;
  }
}

.navigation-header {
  display: flex; 
  align-items: center; 
  justify-content: 'space-between';
}

.navigation-content-headline {
  font-family: Mulish;
  font-size: 19px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #191f23;
  margin-bottom: 10px;
}

.navigation-content-search-and-filter {
  width: 350px;
  height: $nav-content-height;
  gap: 9px;
  margin-bottom: 10px;
}

.navigation-content-search {
  width: 350px;
  height: $nav-content-height;
  gap: 6px;
  display: flex;
}

.navigation-content-search-input {
  width: $nav-content-width;
  height: $nav-content-height;
  padding: 12px 14px 12px 14px;
  border-radius: 8px;
  border: 1px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #b8c5cc;
}

.input-with-icon {
  width: $nav-content-width;
  height: $nav-content-height;
  padding: 12px 14px 12px 40px;
  border-radius: 8px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #b8c5cc;
  &::placeholder {
    color: var(--Neutral-Grey-200, var(--Neutral-Grey200, #B8C5CC));
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  &:hover, &:focus, &:active, &:focus-visible {
    outline: 2px solid #33b5e8;
    box-shadow: 0 0 7px #33b5e8;
  }
}

.navigation-content-search-btn {
  width: 44px;
  height: 44px;
  padding: 12px;
  border-radius: 8px;
  border: 1px;
  gap: 8px;
  background: linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #b8c5cc;
}

.filter-icon {
  width: 20.000000103277475px;
  height: 20.000000103277475px;
  padding: 4.166666507720947px 2.5px 5.833333492279053px 2.5px;
}

.navigation-content-drawer-container {
  width: 365px;
  height: 100%;
  padding: 36px 16px 16px 16px;
  gap: 16px;
}

.navigation-content-frame {
  width: 350px;
  height: auto;
  max-height: calc(100% - 80px);
  overflow-y: auto;
  top: 0.9154052734375px;
  gap: 4px;
}

.navigation-content-frame-item {
  width: 350px;
  height: 40px;
  padding: 8px 12px 8px 12px;
  border-radius: 6px;
  gap: 8px;
  display: flex;
  cursor: pointer;
}

.navigation-content-frame-item-content {
  width: 332px;
  min-height: 24px;
  gap: 8px;
  display: flex;
}

.navigation-content-frame-item-carrot {
  // width: 20px;
  // height: 20px;
  padding: 0px 5px 0px 5px;
  display: flex;
  align-items: center;
}

.navigation-content-frame-item-dataflow {
  width: 22px;
  height: 22px;
  margin: 2px 3px 2px 3px;
}

.navigation-content-frame-item-text {
  //styleName: Paragraph 2 [P2]/Regular;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #323f46;
  word-wrap: break-word;
  max-width: 80%;
  & span {
      word-wrap: break-word;
      max-width: 100%;
      word-break: break-all;
  }
}


.navigation-content-frame-asset {
  width: 350px;
  min-height: 40px;
  gap: 5px;
  padding: 8px 12px 8px 48px;
  border-radius: 6px;
  background: #ffffff;
  cursor: pointer;
  & .navigation-content-frame-item-text {
    max-width: 92%;
    word-wrap: break-word;
    word-break: break-all;
  }
}

.item-selected {
  background: #f4fbfc;
}

.chevron {
  width: 20px;
  height: 20px;
}

.navigation-content-search-input-div {
  position: relative;
  display: inline-block;
}

.search-icon {
  position: absolute;
  left: 10px;
  /* Adjust the space on the left of the icon */
  top: 50%;
  transform: translateY(-50%);
}

.placeholder-image {
  position: absolute;
  top: 50%;
  left: 20px; /* Adjust the position of the image based on your preference */
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-image: url('../../../images/search-icon.png'); /* Replace with the path to your image */
  background-size: cover;
}

.navigation-content-search-input::placeholder {
  padding: 35px;
}

.notification-content-button-filter {
  padding-top: 5px;
}

.notification-content-filter {
  margin-left: auto;
  margin-right: 0;
  position: relative;
}

.navigation-bar-bottom-container {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  padding-bottom: 24px; // Adjust as needed
}

.navigation-bar-bottom-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 16px; // Adjust for space between icons
  cursor: pointer;

  &:hover {
    opacity: 0.7; // Optional hover effect
  }
}

.level0 {
  & > .navigation-header{
    & .navigation-content-frame-item-text {
      max-width: 200px;
    }
  }
}