.rodliftanalysis-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.rodliftanalysis-header {
  display: flex;
  padding: 0.6875rem 0.8125rem;
  align-items: center;
  gap: 0.9375rem;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  border: 1px solid #d0d8dd;
  background: #fff;
  width: 100%;
}
.rodliftanalysis-body {
  display: flex;
  height: 20rem;
  padding: 1rem;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  border: 1px solid #d0d8dd;
  background: #f7f9f9;
  overflow-y: auto;
  scrollbar-width: none;
  width: 100%;
}
.rodliftanalysis-body::-webkit-scrollbar {
  display: none;
}
.card-viewer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid #e7ecee;
  background: #fff;
  box-shadow:
    0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}
.card-viewer-header {
  display: flex;
  padding: 1.25rem 1.5rem 0rem 1.5rem;
  align-items: flex-start;
  gap: 1rem;
  flex: 1 0 0;
  border-bottom: 1px solid #d0d8dd;
  width: 100%;
}
.card-viewer-card-title {
  flex: 1 0 0;
  color: #191f23;
  font-family: Mulish;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
}
.card-viewer-body {
  display: flex;
  padding: 0.125rem 1.5rem 1.25rem 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.1875rem;
  flex: 1 0 0;
  width: 100%;
}
.card-viewer-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.card-viewer-date-list {
  height: 33.25rem;
  flex-shrink: 0;
  width: 35%;
  overflow-y: auto;
  scrollbar-width: none;
  border-radius: 4px;
  border: 1px solid var(--neutral-grey-50, #E7ECEE);
}
.card-viewer-date-list::-webkit-scrollbar {
  display: none;
}
.card-viewer-card {
  align-items: flex-start;
  gap: 0.5rem;
  height: 33.25rem;
  width: 100%;
}
.card-viewer-chart {
  width: 65%;
  height: 33.25rem;
  flex-shrink: 0;
}
.card-viewer-timeline {
  width: 65%;
  flex-shrink: 0;
}
.card-viewer-button-container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}
.card-viewer-button {
  display: flex;
  padding: 0.25rem;
  align-items: center;
  gap: 0.25rem;
  background: none;
  border: none;
}
.card-viewer-button:hover {
  cursor: pointer;
}
.card-viewer-data {
  border-bottom: 1px solid #d0d8dd;
}
.card-viewer-calendar{
  display: flex;
  padding: 0.25rem;
  align-items: center;
  gap: 0.25rem;
  background: none;
  border: none;
  height: 20px;
}
.card-viewer-calendar:hover {
  cursor: pointer;
}