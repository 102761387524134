@import '../../../../../../styles/darktheme/utilities/variables';

.alarm-header {
    padding: 20px 24px 20px 24px;
}

.icon-text {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin-right: 15px;
    gap: 8px;
    align-items: center;
    font-size: 28px;
    border: 5px solid #ffffff;
    background: #0094BD;
}

.alarm-des {
    font-size: 16px;
    color: #B8C5CC;
}

.alarm-heading {
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
}

.row-input {
    display: flex;
    gap: 10px;
}

.form-group {
    width: 100%;
    margin-bottom: 15px;
}

.form-control {
    display: block;
    width: 100%;
    height: 44px;
    padding: 10px 12px 10px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    color: #B8C5CC;
    // background-color: #fff;
    background-image: none;
    border: 1px solid #4A5463;
    border-radius: 8px;
    margin-top: 10px;
}

.form-border {
    width: 100%;
    border: 1px solid #4A5463;
    border-radius: 8px;
    padding: 24px;
    @media #{$miniDesktop}, #{$tabletMiniDesktop} {
        height: auto;
        max-height: calc(100vh - 200px);
        overflow-y: auto;
    }
}

.input-lable {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #F7F9F9;
}