@import '../../../styles/darktheme/utilities/variables';

.delete-modal-page {
  .modal {
    width: 414px;

  }

  .title {
    font-size: 19px;
    font-weight: 600;
    margin-bottom: 12px;
    border-bottom: 0;
    display: flex;
    justify-content: center;
    color: var(--Neutral-Grey-25, $dark-text-primary);
    border-bottom: 0;
  }

  .normal-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $neutral-light-shade3;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
  }

  .btn-dark-orange {
    color: #fff;
  }

  .btn-delete {
    display: flex;
    gap: 14px;
    margin-bottom: 15px;
    justify-content: center;
  }

  .btn {
    width: 162px;
    text-align: center;
    display: block;
  }
}

.document-delete {
  .modal {

    .feature-img,
    .title,
    .normal-text {
      margin: 0px !important;
    }

    .delete-modal__footer {
      display: flex;
      justify-content: space-around;
    }

    .btn {
      width: 140px !important;
      height: 40.27px !important
    }
  }
}

.header-delete {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 20px 15px 20px;
  border-bottom: 1px solid var(--dark-mode-400, $primary-dark-border);
  margin-bottom: 16px;
}

.feature-img {
  display: flex;
  justify-content: center;
  margin-bottom: 14px;
}

.delete-modal {
  &__background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  &__container {
    position: absolute;
    background-color: $dark-secondary;
    border: 1px solid $primary-dark-border;
    border-radius: 8px;
    width: 27%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__header {
    padding: 1.25rem 0px 0px 0px;
  }

  &__body {
    padding: 1rem 1rem 1.5rem;
  }

  &__separator {
    border: 1px solid $primary-dark-border;
    width: 100%;
  }

  &__title {
    font-size: 19px;
    font-weight: 600;
    padding-bottom: 5px;
    text-align: center;
    line-height: 23px;
    color: $dark-text-primary;
  }

  &__content {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--Neutral-Grey-200, var(--Neutral-Grey200, $neutral-light-shade3)) !important;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
  }

  &__footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    button {
      display: flex;
      justify-content: center;
      width: 50%;
      color: $white;
    }
  }

}