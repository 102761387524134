$tooltip-text-color: white;
/* $tooltip-background-color: #003747; */
$tooltip-background-color:#001023;
.tooltip-wrapper {
  display: inline-block;
  position: relative;
}
.tooltip-tip {
  position: absolute;
  border-radius: 4px;
  left: 10%;
  transform: translateX(-50%);
  padding: 6px;
  color: $tooltip-text-color;
  background: $tooltip-background-color;
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 9999999999999999999;
  white-space: nowrap;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.tooltip-tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  // pointer-events: none;
  border-width: 6px;
  margin-left: calc(6px * -1);
}
.tooltip-tip.top {
  top: calc(30px * -1);
  left: calc(10% + 10px);
}
.tooltip-tip.top::before {
  top: 100%;
  border-top-color: $tooltip-background-color;
}
.tooltip-tip.right {
  left: calc(65% + 30px);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
.tooltip-tip.right::before {
  left: calc(6px * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: $tooltip-background-color;
}
.tooltip-tip.bottom {
  bottom: calc(30px * -1);
}
.tooltip-tip.bottom::before {
  bottom: 100%;
  border-bottom-color: $tooltip-background-color;
}
.tooltip-tip.left {
  left: auto;
  right: calc(50% + 30px);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
.tooltip-tip.left::before {
  left: auto;
  right: calc(6px * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: $tooltip-background-color;
}
