@import '../../../../styles/darktheme/utilities/variables';

.heding-margin {
  margin-top: 0px;
}

.ticket-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  pointer-events: visible;
  padding: 1rem;

  &:hover,
  &:focus,
  &:active {
    background-color: $dark-primary;
    color: $white;
    border-radius: 0.5rem;
  }

  .link {
    text-decoration: none;
  }

  img {
    width: 16px;
    height: 16px;
    transition: transform 0.3s ease;
  }

}

.dashboard-profile-image-container {
  border-radius: 400px;
  border: 5px solid var(--Shades-White, #FFF);
  background: var(--Primary-Indigo500, #0094BD);
  margin-right: 12px;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;

  & .profile-image-text {
    color: #FFF;
    font-family: Mulish;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
  }
}

.profile-image-container {
  border-radius: 400px;
  border: 5px solid var(--Shades-White, #FFF);
  background: var(--Primary-Indigo500, #0094BD);
  margin-right: 12px;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  &.inactive-image-container {
    background: var(--Neutral-Grey400, $neutral-light-shade1);
  }

  & .profile-image-text {
    color: #FFF;
    font-family: Mulish;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
  }
}