@import "/src/styles//darktheme/utilities/variables";

.watch-list-card {
    .dropdown-container {
        display: inline-block;
        position: absolute;
        right: 14px;
        top: 14px;
    }
    .dropdown-btn {
        align-items: center;
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        width: 15px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}



.dot {
    background-color: #333;
    border-radius: 50%;
    height: 4px;
    margin: 2px 0;
    width: 4px;
}

.dropdown-options {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 #0003;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 100%;
    width: 320px;
    z-index: 9;
    button.show-element{
        border-bottom: none;
        padding: 10px;
        font-size: 16px;
        cursor: pointer;
        } 
}

.control-room-component {
    display: flex;
    flex-direction: column;
    width: calc(100% - 68px);
    height: 100%;
    margin-left: 68px;

}
