.filter-dropdown-container {
  position: relative;
  display: inline-block;
  text-align: left;
}
.option-label-container {
  padding: 0.8rem;
  border-bottom: 1px solid var(--neutral-grey-50, #e7ecee);
}
.option-label {
  color: var(--neutral-grey-800, #191f23);
  font-weight: 600;
  line-height: 20px;
}
.filter-checkbox {
  padding-left: 10px;
}
.filter-options {
  display: flex;
  margin-top: 8px;
}
