@import "fonts/mulish.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

/* Common/global settings */
* 
{ 
	box-sizing: border-box; 
	-webkit-box-sizing: border-box; 
	-moz-box-sizing: border-box;
}

html, body { margin:0; padding:0; overflow:hidden; }
html, body, input, button, select, option, textarea, table, th, tr, td, tbody, tfoot, ul, li, ol 
{ 
	font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
                "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
                sans-serif;
	font-weight: normal;
  font-style: normal;
  font-size: 14px;
  color: #333;
  line-height: 130%;
	-webkit-font-smoothing: antialiased; 
  -moz-osx-font-smoothing: grayscale;
}

a { text-decoration: none; }
a:hover { text-decoration: underline; }

body { background-color: #F1F4F5; max-width: 100%; }
body.ReactModal__Body--open { overflow-y: hidden; }

html.modal-open {
  overflow-y: hidden;
}

code { font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }


/* Utility classes */
.p-absolute { position: absolute; }
.p-relative { position: relative; }

.semi-bold { font-weight: 600; }
.align-right { text-align: right; }
.flex { display: flex; }
.flex-column { flex-direction: column; }
.flex-row { flex-direction: row; }
.flex-stretch { align-items: stretch; }
.flex-grow { flex-grow: 1; }
.flex-grow-0 { flex-grow: 0; }
.flex-shrink { flex-shrink: 1; }
.flex-shrink-0 { flex-shrink: 0; }




/* Generic card styles */
.card {
  margin:0.5em;
  background: #ffffff;
  border: 1px solid #d0d8dd;
  border-radius: 8px;
}
.card.m-0 {
  margin: 0;
}

.card .header {
  padding: 1em;
  border-bottom: 1px solid #d0d8dd;
}

.card .header .title { font-weight: 600; font-size:1.2em; color: #458CA2; }
.card .header .sm-highlt {
  font-size: 1rem;
  color : #60BFDA;
  border: 1px solid #60BFDA;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  margin-left: 1rem;
}
.card .body { padding: 1em; }

.list-inline-items li {
    display: inline-block;
    margin-right: 1rem;
}

.inner-head .list-inline-items li {
  color: #B8C5CC
}

.paragraph-3 {
  font-size: 18px;
  color: #005f7c;
  flex: none;
  order: 0;
  flex-grow: 1;
}


.paragraph-2 {
  font-size: 16px;
  color: #323f46;
  order: 1;
  flex-grow: 0;
}
/* 
h1,h2,h3,h4,h5,h6, p {
  pointer-events: none;
}

div.title {
    pointer-events: none;
} */
