.well-watch-list-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 320px;
    min-width: 320px;
    height: 157px;
    border-radius: 8px;
    border: 1px solid var(--Darkmode-400, #4A5463);
    background: var(--Dark-Mode-Gradient-Cards, linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.40) 35.14%));
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 11px 18px;
    cursor: pointer;
    position: relative;

 .well-watch-list-card-title {
    color: #F7F9F9;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    padding: 11px 17px;
}

.well-watch-list-card-description {
    color: #F4FBFC;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 9px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.well-watch-list-count h3 {
    color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
    font-size: 39px;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: -1.56px;
    text-align: right;
    padding-right: 7px;
}

.header-right{
    display: flex;
    height: 36px;
}
}


.watch-list-card-active {
border-color: #60BFDA !important;
}