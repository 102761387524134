.card-performance{
    border-bottom: 1px solid #d0d8dd;
    background: var(--Darkmode-700, #131F2E);
}

.performance-snapshot {
    background: var(--Darkmode-700, #131F2E);
    border-radius: 8px 8px 0 0;
    .card-performance {
        border-radius: 8px 8px 0 0;
    }
}