@import '../../../../styles/darktheme/utilities/variables';

.card {
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.nav-margin-top {
  margin-top: 79px;
}

.profile-block {
  margin-top: 20px;
}

.header {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #ccc;
}

.title {
  font-size: 20px;
  font-weight: bold;
}

.select-buttons {
  display: flex;
}

.select-button {
  border: none;
  cursor: pointer;
}

.select-button.active {
  background-color: #131f2e;
}

.date-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 20px;
}

.body {
  padding: 20px;
}

.chartdiv {
  height: 500px;
  margin-top: 20px;
  // margin-left: 200px;
  width: 100%;
}

.asset-history {
  & .chart-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  & .select-buttons {
    display: flex;
    height: 44px;
    align-items: flex-start;
    border-radius: 8px;
    border: 1px solid var(--Dark-mode-400, #4A5463);
    background: var(--Dark-mode-700, #131F2E);
    width: 172px;
    overflow: hidden;

    button {
      &:nth-child(1) {
        border-right: 1px solid var(--Dark-mode-300, #5D6675);
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        padding: 10px 16px;
        border-radius: 0;
      }

      &:nth-child(2) {
        padding: 10px 16px;
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }

      &.active,
      &:hover,
      &:focus {
        color: var(--Primary-Indigo-400, #60BFDA);
      }
    }
  }

  & .input-div {
    height: 44px;
  }

  & .date-input {
    width: 270px;
  }

  & .history-filter {
    width: 123px;
    height: 44px;
    overflow: hidden;
    border-radius: 8px;
    border-radius: 8px;
    border: 1px solid var(--Dark-mode-400, #4A5463);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    background-color: transparent;

    & .w-full {
      padding: 10px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
    }
  }

  & .history-date-range {
    position: relative;
    top: -99px;
    right: 20px;

    & .date-picker__container {
      position: absolute;
      left: unset;
      right: 0;
    }
  }

  & .trends-filter-modal-container {
    position: absolute;
    right: 0;

    & .history-search {
      width: 100%;
      padding: 4px 12px;
      background: var(--Darkmode-800, #001023);
      border-top: 1px solid var(--Dark-mode-400, #4A5463);
    }

    & .history-search-icon {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 16px;
      left: 16px;
    }

    & .history-filter-search {
      width: 100%;
      height: 40px;
      border-radius: 8px;
      padding-left: 1.75rem;
      background: var(--Darkmode-800, #001023);
      border: none;

      &:focus-visible {
        outline: none;
      }
    }
  }
}

.p-multiselect.p-component.p-inputwrapper {
  background: var(--Darkmode-800, #001023);
  color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));

  & .p-multiselect-trigger {
    svg {
      color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
    }
  }

  & .p-multiselect-label-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

.p-multiselect-panel {
  border-radius: 8px;
  border: 1px solid var(--Dark-mode-400, #4A5463);
  background: var(--Darkmode-800, #001023);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  overflow: hidden;
  max-width: 320px;

  // width: 320px;
  // left: 990px !important;
  & .p-multiselect-header {
    background: var(--Darkmode-800, #001023);
    border-bottom: 1px solid var(--Dark-mode-400, #4A5463);

    & .p-multiselect-close {
      color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
    }
  }

  & .p-multiselect-items-wrapper {
    border-bottom: 1px solid var(--Dark-mode-400, #4A5463);

    & .p-multiselect-items {
      li {
        background: var(--Darkmode-800, #001023);
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
        padding: 10px 16px;
      }
    }
  }

  & .p-multiselect-footer {
    display: flex;
    justify-content: flex-end;

    div {
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      & .p-button-text {
        border-radius: 8px;
        border: 1px solid var(--Dark-mode-400, #4A5463);
        background: var(--Dark-mode-800, #001023);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        span {
          color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
          font-family: Mulish;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          padding: 10px 16px;
        }
      }

      button {
        &:last-child {
          border-radius: 8px;
          border: 1px solid var(--Primary-Indigo-700, #005F7C);
          background: var(--Primary-Indigo-600, #00789E);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

          span {
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
            font-family: Mulish;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            padding: 10px 16px;
          }
        }
      }
    }
  }
}

.trends-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background-color: $dark-primary;
  border-radius: 8px;

  .react-grid-layout {
    .react-grid-item {
      left: -6px !important;
    }
  }
}

.card {
  width: 100%;
  color: white;
  border-radius: 8px;
  // overflow: hidden;
  padding: 8px;
  background-color: #131F2E !important;
}

.card-header {
  .title {
    font-size: 18px;
    font-weight: 600;
    color: #F4FBFC;
  }


}

.card-body {
  padding: 20px;
  display: flex;
}

.metrics {
  margin-top: 10px;
  font-size: 12px;
  opacity: 0.8;
}

.psi {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  padding: 0;
  color: #F7F9F9;
}

.cont {
  line-height: 60px;
}

.graph {
  float: right;
  margin-right: 900px;
}

.threshold {
  color: white !important;
  font: 24px;
  margin-right: 15px;
}

.margin-top {
  margin-top: 20px;
}

.thresholdcontainer {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.left-container-history {
  min-width: 15%;
  width: 15%;
  max-height: 200px;
  overflow-y: auto;
  color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.rigth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 85%;
  width: 85%;
  border: 1px solid #384252;

  .spark-line-loader .loader-container {
    margin-top: 0;
  }
}

.history-container-date-range {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: -20px;
  top: -71px;

  & .input-group {
    width: 230px;
    height: 44px;
    position: relative;
    z-index: 999;
    &.hideheader {
      z-index: 0;
    }
  }

  & .date-range-input {
    width: 100%;
    height: 44px;
    padding: 10px;
    padding-left: 35px;
    border: 1px solid var(--Dark-mode-400, #4A5463);
    border-radius: 8px;
  }

  & .date-picker__container {
    position: absolute;
    left: unset;
    top: 42px;
    height: auto;
    right: 0;
    z-index: 10;
  }

  & #filter-btn {
    width: 155px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 44px;
    margin-left: 25px;
    padding: 10px 16px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
  }
}

.history-container-filter {
  display: flex;
  justify-content: flex-end;

  & .input-group {
    width: 230px;
    height: 44px;
    position: relative;
    z-index: 999;
  }

  & .date-range-input {
    width: 100%;
    padding: 10px;
    padding-left: 35px;
    border: 1px solid var(--Dark-mode-400, #4A5463);
    border-radius: 8px;
  }

  & .date-picker__container {
    position: absolute;
    left: calc(100% - 800px) !important;
    top: 42px;
    bottom: -500px !important;
    height: auto;
    max-height: calc(100vh - 100px);
    max-width: 650px;

    @media #{$largeDesktop} {
      position: absolute;
      bottom: -560px !important;
      max-height: 600px;
    }

    @media #{$miniDesktop} {
      position: absolute;
      left: calc(100% - 600px) !important;
      top: 42px;
      bottom: -640px !important;
      height: auto;
      max-height: calc(100vh - 100px);
      max-width: 600px;
    }
  }

  & #filter-btn {
    width: 155px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 44px;
    margin-left: 24px;
    padding: 10px 16px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
  }

  .trends-filter-search {
    border-top: 1px solid #4a5463;
    padding-bottom: 5px;
    width: 100%;
    position: relative;
  }

  input.filter-search {
    padding: 5px;
    background: transparent;
    width: 100%;
    border: 0;
    padding-left: 40px;
    color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding-top: 7px;
  }

}

.histry-filter-modal-container {
  display: flex;
  width: 596px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  border-radius: 8px;
  border: 1px solid var(--Dark-mode-400, #4A5463);
  background: var(--Darkmode-800, #001023);
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  position: absolute;
  z-index: 999;
  top: 46px;
  right: 0px;

  .trends-filter-search {
    width: 100%;
    border-top: 1px solid var(--Dark-mode-400, #4A5463);
    position: relative;
  }

  .search-icon {
    position: absolute;
    left: 8px;
    top: 20px;
  }

  .filter-search {
    width: 100%;
    height: 40px;
    border: none;
    color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
    background-color: inherit;
    padding-left: 40px;
  }

  .filter-search:focus {
    outline: none;
  }

  .trends-filter-modal-header {
    padding: 12px 16px;
    color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  .history-filter-modal-body {
    width: 100%;
    padding: 20px 16px;
    border-top: 1px solid var(--Dark-mode-400, #4A5463);
    border-bottom: 1px solid var(--Dark-mode-400, #4A5463);

    .history-body-container {
      border-radius: 8px;
      border: 1px solid var(--Dark-mode-400, #4A5463);

      .history-body-trends {
        margin: 1px;
        border-radius: 6px;
        padding: 8px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: var(--Dark-mode-700, #131F2E);
      }
    }

    .checkbox-main-container {
      margin-left: 32px;
    }

    .checkbox-container {
      display: block;
      position: relative;
      cursor: pointer;
      font-size: 16px;

      &:hover,
      &:focus {
        background-color: var(--Dark-mode-800, $dark-primary);
        ;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        width: 20px;
        height: 20px;
      }

      .checkbox-checkmark {
        &::after {
          left: 7px;
          top: 3px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }

    .checkbox-checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 4px;
      border: 1px solid var(--Dark-mode-300, $dark-light-shade3);
      background: var(--Dark-mode-800, $dark-primary);

      &::after {
        content: "";
        position: absolute;
        display: none;
        border: none;
      }
    }

    .checkbox-label {
      margin-left: 26px;
    }
  }

  .trends-filter-modal-footer {
    display: flex;
    width: 100%;
    height: 75px;
    padding: 20px 24px;
    justify-content: end;
    align-items: flex-end;
    gap: 12px;
    flex-shrink: 0;

    .footer-btn {
      color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      padding: 10px 16px;
      border-radius: 8px;
      background: var(--Dark-mode-800, #001023);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      border: 1px solid var(--Dark-mode-400, #4A5463);
    }

    .footer-btn:last-child {
      background: var(--Primary-Indigo-600, #00789E);
    }

    .disable-btn {
      cursor: not-allowed;
    }

  }
}

.history-chart-container {
  .chart-loader {
    position: absolute;
    top: 50%;
    width: 100%;
  }

  .absolute {
    position: absolute;
    bottom: 10%;
    width: 100%;
    height: 100%;
  }
}

.history-search {
  width: 100%;
  padding: 4px 12px;
  background: var(--Darkmode-800, #001023);
  border-top: 1px solid var(--Dark-mode-400, #4A5463);
}

.history-search-icon {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 16px;
  left: 16px;
}

.history-filter-search {
  width: 100%;
  height: 40px;
  border-radius: 8px;
  padding-left: 1.75rem;
  background: var(--Darkmode-800, #001023);
  border: none;

  &:focus-visible {
    outline: none;
  }
}

.history-chart-container .no-data {
  margin-top: -30px;
}

.sparkline-chart {
  .am5-html-container {
    overflow: visible !important;
  }
}