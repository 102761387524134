.toast-container {
  z-index: 9999;
  pointer-events: none;
  position: fixed;
  inset: 0;
  display: flex;
  align-items: flex-end;
  padding: 1.5rem;

  @media (min-width: 640px) {
    align-items: flex-start;
    padding: 1.5rem;
  }
}

.toast-top-position {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 1rem;

  @media (min-width: 640px) {
    align-items: flex-end;
  }
}

.toast {
  pointer-events: auto;
  width: 100%;
  max-width: 24rem;
  overflow: hidden;
  border-radius: 0.375rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);

  &-content {
    padding: 1rem;
    display: flex;
    align-items: start;
  }

  &-message {
    flex: 1;
    padding-top: 0.125rem;
    text-align: left;
    font-size: 0.875rem;
    font-weight: 500;
    color: #fff;
  }
  &-icon-text {
    display: flex;
    padding-top: 0.125rem;
    align-items: center;
    gap: 0.5rem;
  }
}
