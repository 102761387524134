@import '../../../../styles/darktheme/utilities/variables';

.trends-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background-color: $dark-primary;
  min-height: 340px;
  border-radius: 0 0 8px 8px;

  .trend-loader {
    position: absolute;
    top: 50% !important;
    margin-top: -30px;
  }
}

.card {
  width: 100%;
  color: white;
  border-radius: 8px;
  // overflow: hidden;
  padding: 8px;
  background-color: #131F2E !important;
}

.card-header {
  .title {
    font-size: 18px;
    font-weight: 600;
    color: #F4FBFC;
  }


}

.card-body {
  padding: 20px;
  display: flex;
}

.metrics {
  margin-top: 10px;
  font-size: 12px;
  opacity: 0.8;
}

.psi {
  // white-space: nowrap;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  padding: 0;
  color: #F7F9F9;
}

.cont {
  line-height: 60px;
}

.graph {
  float: right;
  margin-right: 900px;
}

.threshold {
  color: white !important;
  font: 24px;
  margin-right: 15px;
}

.margin-top {
  margin-top: 20px;
}

.thresholdcontainer {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.left-container {
  min-width: 350px;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 220px !important;
  max-height: 220px !important;
  overflow-y: auto !important;
}

.trends-right {
  border: none !important;
  min-width: calc(100% - 350px) !important;
  width: calc(100% - 350px) !important;

}

.rigth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 85%;
  border: 1px solid #384252;

  .no-data {
    margin-top: 0;
  }
}

.trends-container-date-range {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: -53px;

  & .input-group {
    width: 230px;
    height: 44px;
    position: relative;
    z-index: 8;
    &.hideheader {
      z-index: 0;
    }
  }

  & .date-range-input {
    width: 100%;
    padding: 10px;
    padding-left: 35px;
    border: 1px solid var(--Dark-mode-400, #4A5463);
    border-radius: 8px;

    &:focus-visible,
    &:focus,
    &:active {
      outline: 2px solid #33b5e8;
      box-shadow: 0 0 7px #33b5e8;
    }
  }

  & .date-picker__container {
    position: absolute;
    top: 42px;
    right: 1.5rem;
    left: unset;
    height: auto;
    z-index: 10;
  }

  & #filter-btn {
    width: 155px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    height: 44px;
    margin: 0px 25px;
    padding: 10px 16px;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    cursor: pointer;
  }
}

.trends-filter-modal-container {
  display: flex;
  width: 320px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  border-radius: 8px;
  border: 1px solid var(--Dark-mode-400, #4A5463);
  background: var(--Darkmode-800, #001023);
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  position: absolute;
  z-index: 999;
  top: 46px;
  right: 25px;

  .trends-filter-modal-header {
    padding: 12px 16px;
    color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
    /* Paragraph 1 [P1]/Semibold */
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    /* 142.857% */
  }

  .trends-filter-modal-body {
    width: 100%;
    border-top: 1px solid var(--Dark-mode-400, #4A5463);
    border-bottom: 1px solid var(--Dark-mode-400, #4A5463);

    .checkbox-main-container {
      margin: 10px 16px;
    }

    .checkbox-container {
      display: block;
      position: relative;
      cursor: pointer;
      font-size: 16px;

      &:hover,
      &:focus {
        background-color: var(--Dark-mode-800, $dark-primary);
        ;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        width: 20px;
        height: 20px;
      }

      .checkbox-checkmark {
        &::after {
          left: 7px;
          top: 3px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }

    .checkbox-checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 4px;
      border: 1px solid var(--Dark-mode-300, $dark-light-shade3);
      background: var(--Dark-mode-800, $dark-primary);

      &::after {
        content: "";
        position: absolute;
        display: none;
        border: none;
      }
    }

    .checkbox-label {
      margin-left: 26px;
    }
  }

  .trends-filter-modal-footer {
    display: flex;
    width: 100%;
    height: 75px;
    padding: 20px 24px;
    justify-content: end;
    align-items: flex-end;
    gap: 12px;
    flex-shrink: 0;

    .footer-btn {
      color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      padding: 10px 16px;
      border-radius: 8px;
      background: var(--Dark-mode-800, #001023);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      border: 1px solid var(--Dark-mode-400, #4A5463);
    }

    .footer-btn:last-child {
      background: var(--Primary-Indigo-600, #00789E);
    }

    .disable-btn {
      cursor: not-allowed;
    }

  }
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;

  .loader-container {
    width: auto;
  }
}

.trends-calender-filter-open {
  min-height: 600px;
  justify-content: normal !important;
}