.details-header-section {
    width: 100%;
    border: 1px solid #4A5463;
    border-radius: 8px;
    background-color: #1C2838;
    overflow: hidden;
}

.title {
    width: 100%;
    height: 44px;
    border-bottom: 1px;
    border-color: #4A5463;
    border-style: solid;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    & h1 {
        font-size: 14px;
    }
}

.details-section {
    overflow-x: scroll;
    max-height: 263px;
    background-color: #1C2838;
}

.details-table-row {
    padding: 0px 16px;
    color: #F7F9F9;
}

.details-table-column {
    display: flex;
    justify-content: space-between;
    margin: 12px 6px;
    color: #F7F9F9;
}
.details-table-column > :nth-child(1) {
    padding-right: 8px;
}

.details-table {
    display: flex;
    justify-content: space-between;
    margin: 12px 6px;
}
.details-table-body{
    height: 190px;
    overflow-y: auto;
    & .details-table {
        color: #F7F9F9;
        > :nth-child(1) {
            padding-right: 5px;
        }
        > :nth-child(3) {
            padding-left: 5px;
        }
    }
}