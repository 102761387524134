.walkthrough-container {

    .left-div {
        background-color: #001023;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        .content {
            width: 408px;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .walkthrough-header {
                font-size: 39px;
                font-weight: 700;
                line-height: 47px;
                letter-spacing: -0.04em;
                text-align: left;
                margin-top: 80px;
                color: #F7F9F9;
                line-height: 47px;
            }

            .walkthrough-description {
                margin-top: 24px;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                text-align: left;
                color: #F7F9F9;
                line-height: 19px;
            }

            .button-div {
                margin-top: 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .button {
                    height: 40px;
                    padding: 10px 16px 10px 16px;
                    gap: 8px;
                    border-radius: 8px;
                    border: 1px 0px 0px 0px;
                    color: #F7F9F9;
                    display: flex;
                    align-items: center;

                    &.back-button {
                        background: #131F2E;
                        border: 1px solid #4A5463;
                    }

                    &.continue-button {
                        background: #00789E;
                        border: 1px solid #005F7C;
                    }

                    &.disable {
                        cursor: not-allowed;
                        pointer-events: none;
                    }
                }

                .skip-button {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 19px;
                    text-decoration: underline;
                    color: #F7F9F9;
                    cursor: pointer;
                }
            }

            .slider-div {
                display: flex;
                gap: 10px;
                margin-top: 80px;
                justify-content: center;

                .slider-circle {
                    display: block;
                    width: 10px !important;
                    height: 10px !important;
                    border-radius: 8px;
                    background-color: #E7ECEE;
                    cursor: pointer;

                    &.active {
                        background-color: #60BFDA !important;
                    }
                }
            }
        }

        .footer {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            height: 96px;
            width: 100%;
            color: #B8C5CC;
            display: flex;
            align-items: center;
            padding-left: 32px;
        }
    }

    .right-div {
        height: 100vh;
        background: linear-gradient(45deg, #131F2E, #253040, #384252);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

    }
}