@import '../../styles/darktheme/utilities/variables';

.inactive-page {
    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background-color: var(--Darkmode-800, $dark-primary);
        margin: 0 auto;
    }

    &__content {
        width: 30%;
        padding-top: 1.5rem;
    }

    &__text {
        color: var(--Neutral-Grey25, $dark-text-primary);
        text-align: center;
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    &__email-link {
        color: var(--Primary-Indigo-400, $light-primary-shade5);
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;
        &:focus, &:hover, &:active {
            text-decoration: underline;
        }
    }
}