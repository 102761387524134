.control-room-ticket-filter-drop-down {
    width: 100%;
    margin-bottom: 14px;

    .ticket-dropdown-btn {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0%;
        color: #F7F9F9;
        border-bottom: 1px solid #4A5463;
    }

    .ticket-filter-options {
        padding: 12px 20px;
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: start;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0%;
        color: #F7F9F9;

        // .checkbox-checkmark,
        // .checkbox {
        //     top: -10px !important
        // }
    }

    .ticket-filter-search {
        background: #001023 !important;
        width: 95% !important;
        margin: 14px;
    }

    .show-more-div {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0%;
        color: #60BFDA;
        cursor: pointer;

    }

    .filter-option-list {
        max-height: 400px;
        min-height: 150px;
        overflow-y: auto;
    }
}

.control-room-ticket-filter-drop-down .loader-container {
    position: absolute;
    height: 70%;
}