@import '../../../../styles/darktheme/utilities/variables';

.filter-modal-container {
    position: absolute;
    right: 13px;
    width: 900px;
    border: 1px solid #4A5463;
    background: var(--Dark-mode-800, #001023);
    border-radius: 8px;

    .filter-modal-header {
        width: 100%;
        height: 52px;
        padding: 0px 33px;
        display: flex;
        justify-content: start;
        align-items: center;
        border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
    }

    .filter-modal-body {
        width: 100%;
        // min-height: 225px;
        padding: 24px 24px;

        .body-container {
            border-radius: 8px;
            border: 1px solid var(--Dark-mode-400, #4A5463);
            background: var(--Darkmode-800, #001023);
            padding: 31px 24px;

            .body-input-container {
                display: flex;
                align-items: end;
                width: 100%;
                gap: 16px;
                margin-bottom: 38px;

                .delete-btn {
                    padding: 14px;
                    border-radius: 8px;
                    height: 44px;
                    border: 1px solid var(--Dark-mode-400, #4A5463);
                }
            }

            .filter-input-container {
                width: 261px;
                height: 100%;
                display: flex;
                flex-direction: column;

                gap: 6px;
                color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                position: relative;

                .search-icon {
                    position: absolute;
                    top: 48px;
                    left: 12px;
                }

                .down-icon {
                    position: absolute;
                    top: 40px;
                    right: 12px;
                }


                .filter-input {
                    height: 44px;
                    border-radius: 8px;
                    border: 1px solid var(--Dark-mode-400, #4A5463);
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    padding-left: 40px;
                    background: var(--Dark-mode-800, #001023);
                    color: #fff;
                }
            }

            .filter-input-custom {
                width: 184px;
                height: 100%;
                display: flex;
                flex-direction: column;
                gap: 6px;
                color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                position: relative;

                .down-icon {
                    position: absolute;
                    top: 40px;
                    right: 12px;
                }

                .filter-input {
                    height: 44px;
                    border-radius: 8px;
                    border: 1px solid var(--Dark-mode-400, #4A5463);
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    padding-left: 12px;
                    background: var(--Dark-mode-800, #001023);
                }
            }

            .filter-add-btn {
                width: 100%;
                border-radius: 8px;
                padding: 8px 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border: 1px solid var(--Dark-mode-400, #4A5463);
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
            }
        }
    }

    .filter-modal-footer {
        display: flex;
        width: 100%;
        height: 75px;
        padding: 20px 24px;
        justify-content: end;
        align-items: flex-end;
        gap: 12px;
        flex-shrink: 0;

        .footer-btn {
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            padding: 10px 16px;
            border-radius: 8px;
            background: var(--Dark-mode-800, #001023);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            border: 1px solid var(--Dark-mode-400, #4A5463);
        }

        .footer-btn:last-child {
            background: var(--Primary-Indigo-600, #00789E);
        }

    }

    .contain_dropdown {
        width: 184px;

        .asset_event__dropdown-list {
            width: 184px;
        }
        img{
            right: 1.5rem;
           }
    }

    .event_type_dropdown {
        width: 261px;

        .asset_event__dropdown-list {
            width: 261px;
        }
        .search_icon{
            margin-left: 10px;
        }
       .asset_event__dropdown-input{
        margin-left: -30px;
       }
       .arrow-icon{
        right: 0px;
       }

    }


}

.asset_event {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    width: 100%;

    &__dropdown-field {
        display: flex;
        padding: 10px 14px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border: 1px solid var(--Dark-mode-300, $dark-light-shade3);
        background: var(--Dark-mode-700, $dark-secondary);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--Neutral-Grey-25, $dark-text-primary);

        option {
            display: flex;
            width: 320px;
            padding: 12px 16px;
            flex-direction: column;
            align-items: flex-start;
            border-bottom: 1px solid var(--Dark-mode-400, $primary-dark-border);
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
            font-family: Mulish;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            border-radius: 8px;
        }
    }

    &__input-group {
        display: inline-flex;
        transform: rotate(-0.221deg);
        padding: 10px 8px 10px 16px;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        position: relative;
        left: 0;
        border-radius: 8px;
        border: 1px solid var(--Dark-mode-400, $primary-dark-border);
        background: var(--Dark-mode-800, $dark-primary);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        width: 230px;
        height: 40px;

        & .input-date {
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
            font-family: Mulish;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            border: none;
            background: none;
            width: 200px;

            &:focus {
                outline: none;
            }
        }
    }

    &__dropdown {
        position: relative;
        width: 200px;

        img {
            position: relative;
            right: 1rem;
        }
    }

    &__dropdown-input-container {
        display: flex;
        padding: 10px 14px;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--Dark-mode-400, $primary-dark-border);
        // background: var(--Darkmode-700, $dark-secondary);
        background:var(--Dark-mode-800, #001023);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        width: 100%;
        cursor: pointer;

        img.mr-3 {
            position: relative;
            width: 12px;
            height: 8px;
            pointer-events: visible;
            cursor: pointer;
        }
        input{
            background:var(--Dark-mode-800, #001023);
        }
    }

    &__dropdown-input {
        border: none !important;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    &__dropdown-list {
        position: absolute;
        z-index: 2;
        top: 100%;
        left: 0;
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1px;
        border-radius: 8px;
        // border: 1px solid var(--Dark-mode-400, $primary-dark-border);
        // background: var(--Darkmode-800, $dark-primary);
        // box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
        border: 1px solid var(--Dark-mode-400, #4A5463);
        background: var(--Darkmode-700, #131f2e);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }

    &__dropdown-item {
        display: flex;
        padding: 12px 16px;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        border-bottom: 1px solid var(--Dark-mode-400, $primary-dark-border);
        cursor: pointer;

        &:last-child {
            border: none;
        }

        &:hover,
        &:focus {
            background: var(--Dark-mode-800, #001023);

            &:last-child {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }

            &:first-child {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }
        }
    }

    &__header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
    }
    
}

.marker-detail-container {
    width: 395px;
    padding: 16px 20px;
    align-items: flex-start;
    gap: 10px;
    margin-top: 10px;
    margin-right: 10px;

    border-radius: 8px;
    border: 1px solid var(--Darkmode-700, #131F2E);
    background: var(--Darkmode-800, #001023);

    /* Shadows/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    .head-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
    }

    .wellname {
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));

        /* Paragraph 3 [P3]/Semibold */
        font-family: Mulish;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        /* 155.556% */
    }

    .marker-info-container {
        display: flex;
        padding: 12px;
        gap: 12px;
        border-radius: 4px;
        background: var(--Darkmode-700, #131F2E);
        margin: 12px 0;

        .api-text {
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
            font-family: Mulish;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 14px;

            .api-value {
                font-weight: 400 !important;
            }
        }


    }

    .marker-table {
        border-radius: 4px;
        background: var(--Darkmode-700, #131F2E);
        border: 1px solid #4A5463;
        overflow-y: scroll;
        max-height: 281px;

        .marker-table-column {
            display: flex;
            justify-content: space-between;
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
            font-family: Mulish;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 19px;
            padding: 10px 16px;
            border-bottom: 1px solid #4A5463;
            > div:nth-child(2) {
                width: 132px;
                padding-left: 10px;
            }
        }
    }
}
