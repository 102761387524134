@import '../utilities/variables.scss';

.theme-dark {
  .table-container {
    thead.document-head,
    tfoot.document-foot {
      background: #0f1d2f;
    }
    tfoot.document-foot {
      .pagination-link {
        &.active {
          background-color: #4a5463;
        }
      }
    }
  }
  .btn.btn-transparent {
    border-radius: 0.25rem;
    background-color: #1a2635;
    color: #b8c5cc;

    &:disabled {
      background-color: #5d6675;
      color: #22262c;
    }
  }

  .category-checkboxes {
    border: 1px solid #4a5463;
    background: #131f2e;
    z-index: 999;
  }

  .delete-modal-page {
    &.file-error {
      .modal {
        .notify-card {
          border: 1px solid #4a5463;

          .title {
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #f7f9f9));
          }

          p {
            color: #b8c5cc;
          }

          .header-delete {
            border-color: #4a5463;
          }
        }
      }
    }
  }

  .Schduled-speed {
    .heading {
      color: #fff;
    }
  }

  .right-modal {
    background: #131f2e;
  }

  .table-container.w-full {
    color: #fff;

    .table-header-cell {
      background: transparent;
    }

    .document-table {
      width: 100%;

      th,
      td {
        color: #fff;
        border-bottom: 1px solid #4a5463;
      }
    }
  }

  /* For WebKit Browsers (Chrome, Safari) */
  .overlay-section {
    .card {
      background: #001023 !important;
    }
  }

  .date-input {
    border-color: #4a5463;
  }

  .card.asset-location {
    .table-cell {
      border: 1px solid #4a5463;
      /* Border color */
    }

    .table-body-section {
      td {
        color: $white;
      }
    }
  }

  .set-point-wrap {
    .userblock > .card {
      background-color: #0f1d2f;
      border-color: #4a5463;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: #4a5463;
    /* color of the scrollbar thumb */
    border-radius: 6px;
    /* roundness of the scrollbar thumb */
  }

  ::-webkit-scrollbar-track {
    background-color: #1c2838;
    /* color of the scrollbar track */
  }

  /* Optional: Handle on hover state */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #5d6675;
  }

  .selected-option {
    color: $white;
    border-color: #4a5463;

    > span {
      float: right;
    }
  }

  .dropdown-list {
    background-color: #1c2838;
    border-color: #4a5463;

    li {
      color: #b8c5cc;

      &:hover {
        background-color: #5d6675;
      }
    }
  }

  .setpoint-btn {
    border-color: $dark-light-shade3;
    background: $dark-secondary;
    color: $dark-light-shade3;
  }

  .ico {
    &.plus {
      background: url('../../../images/plus-square.png') no-repeat 0 0;
    }

    &.tool {
      background: url('../../../images/tool-02 (1).png') no-repeat 0 0;
    }

    &.chevrondown {
      background: url('../../../images/chevron-down20.png') no-repeat 0 0;
    }

    &.GearIcon {
      background: url('../../../images/gearIcon-white.png') no-repeat 0 0;
    }
  }

  .cust-drop {
    & > div {
      min-width: 10rem;
      padding: 2px;
      background-color: #131f2e;
      border: 1px solid #5d6675;
      border-radius: 5px;

      & > div:last-child {
        background-color: #131f2e;
      }

      &:hover {
        border-color: #131f2e;
      }
    }

    & > div > div > div > div {
      color: #fff;
    }
  }

  background-color: $dark-primary;

  & .navigation-bar-container {
    background: $dark-tertiary;
    & .tooltip-wrapper {
      padding: 4px;
    }

    & .navigation-bar-navigation-image-container {
      border: none;
      padding: 12px;
      &:hover,
      &:focus,
      &:active {
        border: 0 solid $dark-secondary;
        background-color: $dark-secondary;
        border-radius: 6px;
      }
    }
  }

  & .navigation-bar-items {
    background-color: $dark-primary;

    & .navigation-content-frame-item-text,
    & .navigation-content-headline {
      color: $dark-text-primary;
    }

    & .navigation-content-search-btn {
      background: $dark-secondary;
      border: 1px solid $dark-light-shade2;
    }

    & .navigation-content-frame-asset {
      background: $dark-primary;
    }

    & .item-selected {
      background: $dark-tertiary;
    }
  }

  & .margin-left {
    margin-left: 416px;
  }

  & .margin-0 {
    margin-left: 0px;
  }

  & .group-status-container {
    background-color: $dark-primary;
    // border: 1px solid $dark-tertiary;

    @include border-radius(0);

    & .profile-status-header {
      background-color: var(--Dark-mode-800, #001023);
      & .header {
        & .title {
          padding-left: 0;
          border: none;
        }
      }
      & .notification-preferences {
        margin-top: 0;
        background: var(--Dark-mode-800, #001023);
        padding-left: 2rem;
        padding-right: 2rem;
      }

      & .profile-block {
        margin-top: 0;
        background: var(--Dark-mode-800, #001023);
        padding-left: 2rem;
        padding-right: 2rem;
      }

      .modal-overlay {
        .profile-block {
          padding: 0;
        }
      }
    }

    & .admin-profile {
      & .notification-preferences {
        background: transparent;
        padding: 1.25rem 0;
        &.external {
          padding-top: 0;
        }
      }

      & .profile-block {
        background: transparent;
        padding: 1.25rem 0;
      }
    }

    & .header {
      display: flex;
      align-items: flex-start;
      & .normal-text {
        color: var(--Neutral-Grey-200, var(--Neutral-Grey200, $neutral-light-shade3));
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
      }
    }
  }

  & .text {
    color: $dark-text-primary;
  }

  & .divider {
    background: $dark-light-shade3;
  }

 & .ui-button-toolbar {
    background-color: $dark-secondary;
    border-bottom: 1px solid $dark-light-shade2;

    &.color {
      background-color: #001023;
    }
  }

  & .ui-button-toolbar-button {
    color: $dark-text-primary;

    &.ui-button-toolbar-button-active {
      color: $light-primary-shade5;
    }

    &:hover {
      cursor: pointer;
      color: $light-primary-shade5;
    }
  }

  & .card {
    color: $neutral-light-shade3;
    border: 1px solid $dark-light-shade2;
    background: linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%);
    /* Shadows/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 0;

    & .header {
      border-bottom: 1px solid $dark-light-shade2;
      color: $neutral-light-shade3;

      & .title {
        color: $dark-text-primary;
      }
    }

    & .text {
      color: $dark-text-primary;
    }

    & .card {
      border: 1px solid $dark-light-shade2;
      background: $dark-primary;
    }
  }

  & label {
    color: $dark-text-primary;
  }

  & .custom-input.search {
    color: $white;
    background-color: $dark-primary;
    border: 1px solid $dark-light-shade2;
  }
  & .custom-input,
  & input {
    color: $dark-text-primary;
    border: 1px solid $dark-light-shade2;
    background: $dark-secondary;
  }

  & .useravatar-details {
    & .title {
      color: $dark-text-primary;
    }
  }

  & .admin-search-input-div {
    & .toolbar-eye-btn {
      & .chevron-icon {
        left: 100%;
      }
    }
  }

  & .user-checklist {
    & li {
      &:hover {
        color: $neutral-dark;
      }

      & .notfound {
        color: $white;
      }
    }
  }

  & .adminuser-block {
    border: 1px solid $dark-light-shade2;
  }

  & .user-checklist {
    & ul {
      border-right: 1px solid $dark-light-shade2;

      & li {
        border-bottom: 1px solid $dark-light-shade2;
      }
    }
  }

  // & .userblock {
  //   & .user-ui-button-toolbar {
  //     border-bottom: 1px solid $dark-light-shade2;
  //   }
  // }

  & .profile-info {
    .protext p {
      color: $white;
    }
  }

  & .form-col {
    label {
      color: $white;
    }
  }

  .card {
    .form-row {
      .img-invert {
        filter: none;
      }
    }
  }

  & .security-card {
    .password-form {
      .forgot-password {
        color: var(--primary-indigo-400, $light-primary-shade5);
      }
    }

    .username-custom-input,
    .custom-input {
      border-color: $primary-dark-border;
    }
  }

  .profile-info {
    .protext {
      .text {
        color: $white;
      }
    }
  }

  & .btn-secondary {
    border: 1px solid $neutral-light-shade3;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: $white;
    background: transparent;

    &:hover {
      border: 1px solid $neutral-light-shade3;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: $white;
      background: $dark-secondary;
    }

    &:focus {
      border: 1px solid $neutral-light-shade3;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      color: $white;
      background: $dark-secondary;
    }
  }

  & .toolbar-eye-btn {
    background: transparent;
  }

  & .toogle-heading {
    .title {
      color: #fff;
    }
  }

  & .slider {
    background: var(--dark-mode-500, #384252);
  }

  & .notify-card {
    background: var(--darkmode-700, #131f2e);
    border: 1px solid #4a5463;
    border-radius: 0.5rem;

    .header {
      border-color: #4a5463;
      color: $white;
      padding-bottom: 5px;

      .title {
        font-size: 1.2rem;
      }
    }
  }

  & .time-input-field {
    background: var(--dark-mode-800, #001023);
  }

  & p {
    color: $dark-text-primary;
  }

  & h2 {
    color: $dark-text-primary;
  }

  & .selected {
    background: var(--darkmode-700, #131f2e) !important;
    color: #fff;
  }

  & .user-checklist {
    & li {
      color: #fff !important;
    }
  }

  // & .theme-toggle {
  //   background-color: #131f2e;
  // }

  & .assetreduxpage {
    color: white;
  }

  & .calendar-inner {
    border-radius: 4px;
    border: 1px solid var(--dark-mode-400, #4a5463);
    background: var(--dark-mode-600, #253040);
  }

  & .calendar-sechedule-block {
    .card {
      border-radius: 8px;
      border: 1px solid var(--dark-mode-400, #4a5463);
      background: var(--dark-mode-700, #131f2e);
      margin: 0;
      padding: 0;
    }
  }

  & .dropdown-options {
    background-color: #001023;
    border: 1px solid var(--Dark-mode-400, #4a5463);
    border-radius: 6px;
  }

  & .dropdown-options {
    border-radius: 8px;
    & button {
      color: #fff;
      border-bottom: 1px solid var(--dark-mode-400, #4a5463);
      border-radius: 0;

      &:hover {
        border-radius: 0;
        background: var(--darkmode-700, #131f2e);
      }
    }
  }

  .user-details {
    li {
      .namelabel {
        color: $white;
      }

      .info {
        color: $white;
      }
    }
  }

  /*Dashboard*/
  .main-content {
    background: var(--darkmode-800, #001023);
    padding-left: 68px;
    width: calc(100% - 68px);
  }

  .img-border {
    // border-radius: 8px;
    // border: 1px solid var(--Dark-mode-400, $dark-light-shade2);
    // background: var(--Dark-Mode-Gradient-Cards, linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%));
    // box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    // padding: 5px;
    filter: none;
  }

  .table-container-dashboard {
    .table-header-cell {
      border-top: 1px solid var(--Dark-mode-400, $dark-light-shade2);
      border-bottom: 1px solid var(--Dark-mode-400, $dark-light-shade2);
    }
  }

  .performanceSnapshot {
    border: 1px solid var(--Dark-mode-400, #4a5463);
    background: var(--Darkmode-700, #131f2e);
    border-radius: 8px;
    .card-performance {
      border-radius: 8px 8px 0 0;
    }
  }

  .grid-item {
    border: 1px solid var(--dark-mode-300, #5d6675);
    background: var(--dark-mode-gradient-cards, linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%));
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .card-performance {
    // background: var(--dark-mode-gradient-cards, linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%));
    background: var(--Darkmode-700, #131f2e);
    border: none;
  }

  .total-count {
    text-align: center;

    & p {
      color: var(--neutral-grey-25, $dark-text-primary);
    }

    & h2 {
      color: var(--neutral-grey-25, $dark-text-primary);
    }
  }

  .text-para-dash {
    color: #fff;
  }

  .table-header-item {
    color: var(--Neutral-Grey-25, #f7f9f9);
  }

  .table-row-general {
    border-bottom: 1px solid var(--Dark-mode-400, #4a5463);

    & td {
      color: var(--Neutral-Grey-25, #f7f9f9);
      padding-right: 15px;
    }
  }

  .table-header-section {
    .title {
      color: var(--Neutral-Grey-25, #f7f9f9);
    }
  }

  & .wrapper-location {
    .ui-button-toolbar {
      border-bottom: 0;
    }
  }

  .dashboard-first-block {
    width: 100%;
    .top-header {
      background-color: #131f2e;

      .page-title {
        color: #fff;
      }
    }
  }

  .dropdown-btn {
    filter: invert(1);
  }

  select {
    border: 1px solid var(--Dark-mode-300, #5d6675);
    background: var(--Dark-mode-700, #131f2e);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--Neutral-Grey-25, #f7f9f9);
  }

  .filter-img {
    filter: invert(1);
  }

  .selected-dropdown {
    border-radius: 8px;
    border: 1px solid var(--Dark-mode-300, #5d6675);
    background: var(--Dark-mode-800, #001023);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }

  .well-option {
    & button {
      border-bottom: none;
    }
  }

  & .setpoint-block {
    & .selected {
      border-left: 3px solid #dc4405;
      margin: 1px;
    }
  }

  & .dropdown-dashboard {
    filter: none !important;
  }

  & .user-modal {
    & .cust-drop {
      div {
        &:hover {
          border: 1px solid #5d6675;
        }
      }
      & .selected-option {
        &.one {
          border: none;
        }
      }
    }
  }
  & .viewall-url {
    a {
      color: var(--Primary-Indigo-400, var(--Primary-Indigo400, #60bfda));
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      text-decoration: none;
      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
      }
    }
  }
  .Toastify__toast {
    &-theme--light {
      background: $dark-secondary;
    }
    .Toastify__close-button--light {
      color: #f7f9f9;
    }
    &--success {
      .Toastify__toast-body > div:last-child {
        color: var(--Success-Green-400, $success-dark-shade5);
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
    }
    &--error {
      .Toastify__toast-body > div:last-child {
        color: $error-dark-shade5;
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
}

.table-row-general > td:first-child {
  color: #60bfda !important;
  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}
