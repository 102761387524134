.ui-button-toolbar {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  padding-left: 1em;
  padding-top: 1em;
}
.ui-button-toolbar-button {
  border: none;
  color: #4b5e68;
  background-color: transparent;
  /* padding: 0.0625rem 1.25rem 0.6875rem 0.25rem; */
  padding: 0.0625rem 0.25rem 0.6875rem 0.25rem;
  margin-right: 1rem;
}
.ui-button-toolbar-button:hover {
  cursor: pointer;
  color: #191f23;
}
.ui-button-toolbar-button-active {
  border-bottom: 2px solid;
  border-bottom-color: #dc4405;
  color: #005f7c;
}
