@import '../../../styles/darktheme/utilities/variables';

.user-profile {
    .error-message {
        color: $error-dark-shade5;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding: 5px 0px 0px;
    }
}

.phone-input {
    display: flex;
  }
 
  .country-code-input {
    width: 4.5rem;
    margin-right: 5px;
    text-align: center;
  }