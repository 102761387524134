@import '../../../../styles/darktheme/utilities/variables';

.user-modal {
  width: 80%;
  text-align: left;
  background: none;

  & .user-search {
    & .block {
      margin-right: 0;
    }

    input {
      border-radius: 8px;
    }
  }

  .action-filter,
  .custom-text-input {
    height: 44px;
    border-radius: 8px;
    background: none !important;
  }

  & .user-actions-filter-dropdown {
    width: 400px;
    border-radius: 8px;
    z-index: 9999 !important;
    background: var(--Darkmode-800, $dark-primary);
    right: 0;

    & .header {
      color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
      font-family: Mulish;
      font-size: 19px;
      font-style: normal;
      font-weight: 600;
      line-height: 23px;
      padding: 20px 24px;
      background: var(--Darkmode-800, $dark-primary);
      border-radius: 8px 8px 0 0;
    }

    & .card {
      border: none;
      border-bottom: 1px solid var(--Dark-mode-400, $dark-light-shade2);
      border-radius: 0;
      background: var(--Darkmode-800, $dark-primary);
    }

    & .card-body {
      background: var(--Darkmode-800, $dark-primary);
      // min-height: auto !important;
      max-height: 250px !important;
      overflow: auto;
    }

    & .drop-content {
      border: 1px solid var(--Dark-mode-400, $dark-light-shade2);
      border-radius: 8px;
    }

    & .selected-option {
      border: none;
      border-radius: 8px;
      background: var(--Dark-mode-700, $dark-secondary);
      padding: 8px;
      margin: 2px;
      font-size: 16px;
    }

    & .user-arrow-color {
      width: 24px;
      height: 16px;
      padding-left: 8px;
    }

    & .status-dropdown {
      & .category-checkboxes {
        border: none;
      }

      & .dropdown-list {
        background: var(--Dark-mode-800, $dark-primary);
        border-radius: 6px;
        border: none;
        position: initial;

        li {
          padding: 8px 12px;
          background: var(--Dark-mode-700, $dark-secondary);
          border-radius: 6px;
          margin: 1px 2px 2px;

          &:hover {
            background-color: var(--Dark-mode-700, $dark-secondary);
          }
        }
      }

      & .active-label {
        color: var(--Primary-Indigo-400, $light-primary-shade5);
      }
    }

    .radio-container {
      display: block;
      position: relative;
      cursor: pointer;
      padding-left: 2rem;

      &.active-radio-label {
        color: var(--Primary-Indigo-400, $light-primary-shade5);
        ;
      }

      input {
        position: absolute;
        opacity: 0;
        left: 0;
        top: -10px;
        cursor: pointer;
        width: 20px;
        height: 20px;

        &:checked {
          .radio-checkmark {
            background: $dark-primary;
            border: 1px solid var(--Primary-Indigo600, $light-primary-shade3);
            ;
          }
        }
      }

      .radio-checkmark {
        &::after {
          left: 4px;
          top: 4px;
          width: 10px;
          height: 10px;
          background: var(--Primary-Indigo600, $light-primary-shade3);
          ;
          border-radius: 50%;
        }
      }
    }

    .radio-checkmark {
      position: absolute;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 1px solid var(--Dark-mode-300, $dark-light-shade3);
      background: var(--Dark-mode-800, $dark-primary);

      &::after {
        content: "";
        position: absolute;
        display: none;
        border: none;
      }
    }
  }

  & .active-filter {
    background: var(--Darkmode-800, $dark-primary) !important;
  }

  .selected-filter-option {

    height: 32px;
    padding: 6px 4px 6px 10px;
    border-radius: 4px;
    background: #253040;
    border: 1px solid #91A4AA;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #B8C5CC;

    img {
      width: 16px;
      margin-left: 2px;
      cursor: pointer;
    }
  }

  .form-body {
    padding: 0px;
  }

  .footer {
    height: 92px;

    .btn-notify {
      margin: 0px 20px;
    }
  }

  .user-details-body {
    border-bottom: 1px solid var(--Dark-mode-400, $dark-light-shade2);

    // padding: 5px 47.5px !important;
    .selected-count {
      min-width: 70px;
      max-width: 95px;
    }
  }

  .bulk-action-user-profile {
    width: 32px;
    height: 32px;
    border: 2px solid #FFFFFF;
    margin-right: -20px;

    .profile-image-text {
      font-size: 12px;
    }
  }

  .bulk-action-filter-checkbox {
    .checkbox-container {
      display: block;
      position: relative;
      cursor: pointer;
      font-size: 16px;
      padding-left: 30px;
    }

    input {
      margin-right: 15px;
    }

    .checkbox-container:focus {
      background: none !important;
    }

    .checkbox-checkmark {
      position: absolute;
      top: 0px !important;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 4px;
      border: 1px solid var(--Dark-mode-300, #5d6675);
      background: var(--Dark-mode-800, #001023);
    }

    .checkbox-checkmark::after {
      content: "";
      position: absolute;
      display: none;
      border: none;
    }

    .checkbox-container input:checked~.checkbox-checkmark {
      border-radius: 4px !important;
      border: 1px solid var(--Primary-Indigo-700, #005f7c) !important;
      background: var(--Primary-Indigo-600, #00789e) !important;
    }

    .checkbox-checkmark::after {
      left: 7px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

.body {
  width: 100%;
}

.btn-color {
  background-color: red;
  color: white;
}

.user-actions-modal {
  & .header-delete {
    margin-bottom: 0;
  }

  & .title {
    margin-bottom: 0;
  }
}

.user-actions-content {
  .table-container {
    border-radius: 8px;

    @media #{$tabletMiniDesktop} {
      max-height: 380px;
    }

    @media #{$miniDesktop} {
      max-height: 400px;
    }
  }
}

.radio-container input:checked~.radio-checkmark {
  border-radius: 50%;
  border: 1px solid var(--Primary-Indigo600, $light-primary-shade3);
  ;
  background: var(--Darkmode-800, $dark-primary);
}

.radio-container input:checked~.radio-checkmark::after {
  display: block !important;
}