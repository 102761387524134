@import '../../../../../styles/darktheme/utilities/variables';

.error-message {
    padding: 5rem 0;
    text-align: center;
    font-size: 1.2rem;

    .error-icon {
        width: 5rem;
        opacity: 1.0;
        margin: 0 auto 1rem auto;
    }
}

.well-production-trends {
    &__input-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 1rem 0;
    }

    &__input-group {
        display: flex;
        transform: rotate(-0.221deg);
        padding: 10px 8px 10px 16px;
        align-items: center;
        gap: 8px;
        position: relative;
        right: 0;
        border-radius: 8px;
        border: 1px solid var(--Dark-mode-400, $primary-dark-border);
        background: var(--Dark-mode-800, $dark-primary);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        width: 230px;
        height: 40px;

        & .input-date {
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
            font-family: Mulish;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            border: none;
            background: none;
            width: 200px;

            &:focus {
                outline: none;
            }
        }
    }

    &__modal-input-group {
        display: flex;
        transform: rotate(-0.221deg);
        padding: 10px 8px 10px 16px;
        align-items: center;
        gap: 8px;
        float: right;
        border-radius: 8px;
        border: 1px solid var(--Dark-mode-400, $primary-dark-border);
        background: var(--Dark-mode-800, $dark-primary);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        width: 230px;
        height: 40px;

        & .input-date {
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
            font-family: Mulish;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            border: none;
            background: none;
            width: 200px;

            &:focus {
                outline: none;
            }
        }
    }

    &-range {
        .date-picker {
            &__container {
                position: absolute;
                left: unset !important;
                right: 12px;
                top: 110px;
            }
        }
    }
}

.well-production-trend-modal_dropdown-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    gap: 8px;
}

.well-production-trends {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    width: 100%;

    &__dropdown-field {
        display: flex;
        padding: 10px 14px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border: 1px solid var(--Dark-mode-300, $dark-light-shade3);
        background: var(--Dark-mode-700, $dark-secondary);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--Neutral-Grey-25, $dark-text-primary);

        option {
            display: flex;
            width: 320px;
            padding: 12px 16px;
            flex-direction: column;
            align-items: flex-start;
            border-bottom: 1px solid var(--Dark-mode-400, $primary-dark-border);
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
            font-family: Mulish;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            border-radius: 8px;
        }
    }

    &__input-group {
        display: inline-flex;
        transform: rotate(-0.221deg);
        padding: 10px 8px 10px 16px;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        position: relative;
        left: 0;
        border-radius: 8px;
        border: 1px solid var(--Dark-mode-400, $primary-dark-border);
        background: var(--Dark-mode-800, $dark-primary);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        width: 230px;
        height: 40px;

        & .input-date {
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
            font-family: Mulish;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            border: none;
            background: none;
            width: 200px;

            &:focus {
                outline: none;
            }
        }
    }

    &__dropdown {
        position: relative;
        width: 260px;

        img {
            position: relative;
            // right: 4rem;
        }
    }

    &__dropdown-input-container {
        display: flex;
        padding: 10px 14px;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--Dark-mode-400, $primary-dark-border);
        background: var(--Darkmode-700, $dark-secondary);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        width: 100%;
        cursor: pointer;

        img.trend-img {
            position: relative;
            pointer-events: visible;
            cursor: pointer;
        }
    }

    &__dropdown-input {
        border: none !important;
        cursor: pointer;
        background-color: transparent;
        color: #f7f9f9;

        &:focus {
            outline: none;
        }
    }

    &__dropdown-list {
        position: absolute;
        z-index: 2;
        top: 100%;
        left: 0;
        width: 320px;
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1px;
        border-radius: 8px;
        border: 1px solid var(--Dark-mode-400, $primary-dark-border);
        background: var(--Darkmode-800, $dark-primary);
        box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    }

    &__dropdown-item {
        display: flex;
        padding: 12px 16px;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        border-bottom: 1px solid var(--Dark-mode-400, $primary-dark-border);
        cursor: pointer;

        &:last-child {
            border: none;
        }

        &:hover,
        &:focus {
            background: var(--darkmode-700, $dark-secondary);

            &:last-child {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }
    }

    &__modal-calendar-container {
        & .date-picker__container {
            position: absolute;
            z-index: 9999;
            top: 120px;
            right: 0;
            left: calc(100% - 870px);
        }
    }

    &__filter-dropdown {
        position: relative;
        & .trends-filter-modal-container {
            left: 0;
            top: 41px;
            & .trends-filter-modal-header {
                border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
                width: 100%;
            }
            & .trends-filter-modal-body {
                border-top: none;
            }
            & .inline-block {
                color: #F7F9F9;
            }
        }
    }
}

.oil-water-gas {
    display: flex;
    color: #f7f9f9;

    .production-oil {
        position: relative;
        padding-left: 25px;
        padding-right: 18px;

        &:before {
            content: '';
            position: absolute;
            background-color: var(--Success-Green400, #32D583);;
            width: 18px;
            height: 4px;
            top: 7px;
            left: 0;
        }
    }

    .production-gas {
        position: relative;
        padding-left: 25px;

        &:before {
            content: '';
            position: absolute;
            background-color: var(--Error-Red400, #F97066);
            width: 18px;
            height: 4px;
            top: 7px;
            left: 0;
        }
    }

    .production-water {
        position: relative;
        padding-left: 25px;
        padding-right: 18px;

        &:before {
            content: '';
            position: absolute;
            background-color: var(--Dark-blue-400, #4294FF);;
            width: 18px;
            height: 4px;
            top: 7px;
            left: 0;
        }
    }
}

.chart-details{
    margin-top: -30px;
}

.wellProductionTrendsChartSection {
    .no-data {
        margin-top: 20px;
    }
}

.well-production-trends-column {
    display: flex;
    align-content: space-between;
    width: 100%;
    align-items: baseline;
    position: relative;
    margin-bottom: 8px;
    .toggle-switch {
        width: 50%;
        .switch {
            position: absolute;
            top: 50%;
            right: 25px;
            margin-top: -10px;
        }
        h2 {
            position: absolute;
            top: 50%;
            margin-top: -10px;
            right: 35%;
            margin-right: -235px;
            color: #f7f9f9;
        }
    }
}

.well-production-loader {
    width: 100%;
    position: relative;
    margin-top: 40px;
    height: 100%;
}

 .am5exporting.am5exporting-icon.am5exporting-align-right.am5exporting-valign-top {
    display: none !important;
}

.wellProTrends {
    .dropdown-options {
        background-color: #001023;
        border: 1px solid var(--Dark-mode-400, #4a5463);
        border-radius: 8px;
        button {
            color: #fff;
            border-bottom: 1px solid var(--dark-mode-400, #4a5463);
            border-radius: 0;
            &:hover {
                border-radius: 0;
                background: var(--darkmode-700, #131f2e);
            }
        }
    }
}