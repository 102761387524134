.asset-control-room-header {
    padding: 25px 32px 0 32px;

    &.asset-header {
        margin-top: 60px;
    }

    .content {
        min-height: 80px;
        padding: 0px 8px;

        .left-header-section {
            flex-direction: row;
            min-height: 93px;

            .tooltip-wrapper {
                display: block;

                .tooltip-tip {
                    text-wrap: wrap;
                    padding: 8px 12px 8px 12px;
                    background: #001023;
                    text-align: right;
                    color: #F7F9F9;
                }

                .tooltip-tip.bottom::before {
                    border-bottom-color: #001023;
                }
            }

            .ticket-count-card {
                background: var(--Dark-mode-800, #001023) !important;

                width: 196px;
                height: 48px;
                padding: 12px 11.96px 13px 16px;
                cursor: pointer;

                .ticket-number {
                    font-size: 19px;
                    font-weight: 700;
                    letter-spacing: -0.02em;
                    text-align: left;
                    // color: #647980;
                    color: #F4FBFC;
                }

                .ticket-count {
                    min-width: 20px;
                    width: auto;
                    height: 20px;
                    padding: 0px 5px 0px 5px;
                    border-radius: 10px;
                    background: var(--Primary-Indigo600, #00789E);
                    font-size: 12px;
                    font-weight: 600;
                    // color: #B8C5CC;
                    color: #F4FBFC;

                }

                &.expanded {
                    height: 57px;
                    margin-top: 15px;
                    padding: 12px 39.962px 20px 16px;
                    ;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                    z-index: 10;
                    border-bottom: none;
                }
            }

            .health-score-card {
                min-width: 330px;
                max-width: 330px;
                height: 48px;
                padding: 12px 11.96px 13px 16px;
                cursor: pointer;

                .health-score {
                    font-size: 28px;
                    font-weight: 700;
                    line-height: 34px;
                    letter-spacing: -0.04em;
                    text-align: left;
                    color: #F7F9F9;

                    & .loader-container {
                        & .loader {
                            width: 23px;
                            height: 23px;
                            border-width: 3px;
                        }
                    }
                }

                .health-score-label {
                    font-size: 12px;
                }

                .health-score-status {
                    min-width: 140px;
                    height: 22px;
                    padding: 2px 8px 2px 6px;
                    gap: 2px;
                    border-radius: 4px;
                    background: #32D583;
                    border: 1px solid #32D583;
                    color: var(--Neutral-Grey800, #191F23);
                    font-size: 12px;
                    font-weight: 600;

                    & .loader-container {
                        max-width: 18px;

                        & .loader {
                            width: 16px;
                            height: 16px;
                            border: 2px solid #4a5463;
                            border-left-color: rgba(255, 255, 255, 0.1);
                        }
                    }

                    &.red {
                        background: #F97066;
                        border: 1px solid #F97066;
                        color: var(--Neutral-Grey800, #191F23);
                    }

                    &.green {
                        background: #32D583;
                        border: 1px solid #32D583;
                        color: var(--Neutral-Grey800, #191F23);
                    }

                    &.gray {
                        background: #B8C5CC;
                        border: 1px solid #B8C5CC;
                        color: var(--Neutral-Grey800, #191F23);
                    }

                    &.border-only {
                        background: transparent;
                        border: 1px solid #B8C5CC;
                        color: #B8C5CC;
                        opacity: 0.5;
                    }
                }

                .health-img {
                    &.up {
                        transform: rotate(0deg);
                        -webkit-transform: rotate(0deg);
                        -moz-transform: rotate(0deg);
                        transition: all 0.3sease-in-out;
                        -webkit-transition: all 0.3sease-in-out;
                        -moz-transition: all 0.3sease-in-out;
                    }

                    &.down {
                        transform: rotate(-180deg);
                        -webkit-transform: rotate(-180deg);
                        -moz-transform: rotate(-180deg);
                        transition: all 0.3sease-in-out;
                        -webkit-transition: all 0.3sease-in-out;
                        -moz-transition: all 0.3sease-in-out;
                    }
                }
            }
        }

        .right-header-section {
            .toggle-section {
                min-width: 319px;
                display: flex;
                justify-content: space-between;

                .vertical-divider {
                    border: 1px solid #4A5463
                }
            }

            .button {
                display: flex;
                justify-content: center;
                gap: 8px;
                align-items: center;
                height: 48px;
                width: 159px;
                padding: 10px 16px 10px 16px;
                cursor: pointer;
            }

            .active {
                color: #60BFDA;

                svg {
                    color: #60BFDA;

                }
            }

            &.squeez-btngroup {
                .toggle-section {
                    min-width: 107px;
                }

                .button {
                    width: 52px;
                    padding: 6px;

                    & img.imgbuttoncard {
                        padding-left: 7px;
                    }
                }
            }

            & .manage-asset {
                max-width: 159px;
            }
        }

    }
}