.dropdown {
  margin: 0 10px;
  width: 320px;
  position: relative;
}

.dropdown .dropdown-btn {
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-grey-100, #d0d8dd);
  background: var(--shades-white, #fff);
  justify-content: space-between;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
}

.fa-caret-down::before {
  content: url('../../../images/Carrot.png');
  text-align: right;
  direction: rtl;
}

.fa-caret-up::before {
  content: url('../../../images/chevron-up.png');
  text-align: right;
  width: 20px;
  height: 20px;
  display: block;
}

.fa-eye::before {
  content: url('../../../images/eye.png');
  text-align: right;
  direction: rtl;
  width: 20px;
  height: 20px;
}
.fa-eye {
  display: flex;
}

.dropdown-content {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px solid var(--neutral-grey-50, #e7ecee);
  background: var(--shades-white, #fff);
  position: fixed;
  width: 320px;
  box-shadow:
    0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.dropdown-content .item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-content .item:hover {
  background: #fcfcfc;
}
.fa-text {
  padding-left: 10px;
}
