@import '../../../../../../styles//darktheme/utilities/variables';

.rdr-buttons-position {
    bottom: 1rem;
    background-color: $dark-primary;
    border-radius: 0 0 8px 8px;
}

.shadow {
    position: absolute;
    z-index: 9999;
    top: '500px'
}

.apply-btn {
    background-color: $light-primary-shade3 !important;
}

.footer-container {
    display: flex;
}

.rdrMonth {
    border: 1px solid $dark-light-shade3;
    border-left: none;
    border-top: none;
}

.rdrStaticRange {
    background: var(--Dark-mode-700, $dark-secondary);
    overflow: hidden;
    color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    &:hover, &:focus {
        border-radius: 6px;
        background: var(--Primary-Indigo-500, $light-primary-shade4);
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
        .rdrStaticRangeLabel {
            background: var(--Primary-Indigo-500, $light-primary-shade4);
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
        }
    }

    &:active {
        background: var(--Primary-Indigo-500, $light-primary-shade4);
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
        .rdrStaticRangeLabel {
            background: var(--Primary-Indigo-500, $light-primary-shade4);
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
        }
    }
}

.rdrWeekDay,
.rdrDayNumber span,
.rdrMonthAndYearPickers select {
    color: $dark-text-primary;
}

.rdrDateDisplayWrapper,
.rdrInputRanges {
    display: none;
}

.date-input {
    width: 136px;
    height: 44px;
    border-radius: 8px;
    padding: 10px 12px 10px 12px;
    margin-top: 2px;
    gap: 8px;
}

.rdrDateRangePickerWrapper {
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
}

.rdrDefinedRangesWrapper {
    border-right: solid 1px $dark-light-shade3 !important;
    background-color: $dark-primary !important;
    width: 192px !important;
    border-top-left-radius: 8px;
    height: 445px;
}

.rdrCalendarWrapper {
    background-color: $dark-primary !important;
    width: 656px !important;
    border-top-right-radius: 8px;
}

.rdrStaticRanges {
    // margin: 4px 0 0 8px;

    button {
        display: flex;
        width: auto;
        align-items: center;
        border-radius: 6px;
        background: var(--Dark-mode-700, $dark-secondary);
        border: none;
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
        // margin: 0 5px 4px 0;

        &:hover,
        &:focus {
            border-radius: 6px;
            background: var(--Primary-Indigo-500, $light-primary-shade4);
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
        }
        &:active {
            background: var(--Primary-Indigo-500, $light-primary-shade4);
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
        }
        
        &.rdrStaticRange.rdrStaticRangeSelected {
            background: var(--Primary-Indigo-500, $light-primary-shade4);
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary)) !important;
        }
    }
}

@media #{$xxlargeDesktop} {
    .date-picker {
        &__container {
            position: absolute !important;
            left: unset !important;
            right: 0;
        }
    }
}

.rdrStartEdge,
.rdrEndEdge {
    border-radius: 20px !important;
    background: var(--Primary-Indigo-500, $light-primary-shade4);
}

.rdrInRange {
    background: var(--Dark-mode-400, $primary-dark-border) !important;
}

.rdrDayNumber {
    span {
        &::after {
            content: none !important;
        }
    }
}

@media #{$desktop} {
    .date-picker {
        &__container {
            position: absolute;
            z-index: 9999;
            right: 0;
            top: 100px;
            border-radius: 8px;
            border: 1px solid var(--Dark-mode-300, $dark-light-shade3);
            background: var(--Dark-mode-800, $dark-primary);
            box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
            width: 848px;
            left: 0;
        }
    
        &__footer-container {
            display: flex;
            background-color: $dark-primary;
            border-radius: 0 0 8px 8px;
        }
    
        &__footer-left-container {
            width: 192px;
            border-right: 1px solid $dark-light-shade3;
            border-radius: 0 0 0 8px;
            display: flex;
            flex: 1;
        }
    
        &__footer-rigth-container {
            width: 656px;
            display: flex;
            padding: 0 1rem 1rem 0;
            margin-top: -66px;
        }
    
        &__input-container {
            display: flex;
            align-items: flex-start;
            gap: 1.5rem;
            flex: 1 0 0;
            width: 50%;
            span {
                margin-top: 0.75rem;
            }
    
            &:nth-child(1) {
                padding-left: 0.75rem;
            }
        }
    
        &__button-container {
            display: flex;
            gap: 8px;
        }
    
        &__input {
            display: flex;
            padding: 10px;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid var(--Dark-mode-400, $primary-dark-border);
            background: var(--Darkmode-800, $dark-primary);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            width: 110px;
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
            font-family: Mulish;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            height: 44px;
        }
    
        &__btn-secondary {
            display: flex;
            height: 44px;
            transform: rotate(-0.11deg);
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            border: 1px solid var(--Neutral-Grey-200, $neutral-light-shade3);
            background: var(--Dark-mode-800, $dark-primary);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
        }
    
        &__btn-primary {
            display: flex;
            height: 44px;
            transform: rotate(-0.11deg);
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            border: 1px solid var(--Primary-Indigo-700, $light-primary-shade2);
            background: var(--Primary-Indigo-600, $light-primary-shade3);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
        }
    }
    .rdrStaticRanges {
        padding: 0.75rem;

        button {
            display: flex;
            width: auto;
            align-items: center;
            border-radius: 6px;
            background: var(--Dark-mode-700, $dark-secondary);
            border: none;
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
            padding-bottom: 0.25rem;
            margin-bottom: 6px;

            &:hover,
            &:focus {
                border-radius: 6px;
                background: var(--Primary-Indigo-500, $light-primary-shade4);
                color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
            }
            &:active {
                background: var(--Primary-Indigo-500, $light-primary-shade4);
                color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
            }
        }
    }
}
