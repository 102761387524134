@import '../../../../styles/darktheme/utilities/variables';

.set-point-wrap {
    // margin-top: 2rem;
    min-height: calc(100vh - 115px);

    .setpoint-userblock {}
}

.all-wells-section {
    .set-point-wrap {
        min-height:  auto;
    }
}

.set-point-loader {
    top: 50%;
    position: absolute;
    margin-left: -50px;
}

.selected-btn {
    border-radius: 8px;

    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: #ffffff;
    padding: 8px 14px;
    margin-right: 11px;
}

.setpoint-btn {
    border-radius: 8px;
    border: 1px solid $neutral-light-shade3;
    background: $dark-text-secondary;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 8px 14px;
    margin-right: 11px;
    cursor: context-menu !important;

    &.selected {
        border: 1px solid $light-primary-shade3;
        background: $light-primary-shade3 !important;
        color: $white;
        cursor: pointer !important;
    }
}

.btn-wrapper {
    margin-bottom: 10px;
}

.card-set-point {
    background: var(--Dark-Mode-Gradient-Cards, linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.40) 35.14%));

}

.site-details {
    .security-card .body {
        min-height: calc(100vh - 372px);
    }
}

.setpoint-info {
    gap: 15px;
    display: flex;
    align-items: center;
}

.synchronize-dropdown {
    width: auto !important;
    position: relative;

    img {
        display: inline;
        margin-left: 5px;
        // width: 8px;
        // height: 5px;
    }

    .synchronize-option {
        background-color: #131F2E;
        border: 1px solid #4A5463;
        left: 0px;
        width: 216px;

        button {
            border-bottom: none;
        }

        button:hover {
            background-color: #001023;
        }
    }

    &.enabled {
        color: #F7F9F9 !important;
        cursor: pointer !important;

    }
}



.w-100 {
    .profile-status-header {
        background-color: var(--Dark-mode-800, $dark-primary) !important;
    }
}

.first-half {
    border-right: 1px solid var(--Dark-mode-400, #4A5463);
}

.setpoint-block {
    ul.setpoint-check-list {
        border-right: 0;
    }

    .setpoint-list-item {
        padding: 12px !important;
    }

    .namedata {
        color: var(--Neutral-Grey-25, #F7F9F9);
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
    }

    .emaildata {
        color: var(--Neutral-Grey-200, var(--Neutral-Grey200, #B8C5CC));
        font-family: Mulish;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    .selected {
        background: var(--darkmode-400, #4A5463) !important; // Assumed selection color
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
        border-left: 3px solid #DC4405;
    }
}

.setpoint-details {
    &-card {
        border-radius: 8px;
        border: 1px solid var(--Dark-mode-400, #4A5463);
        width: 100%;
        background: var(--Dark-Mode-Gradient-Cards, linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.40) 35.14%));
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        overflow: hidden;
    }

    &-div {
        max-height: 500px;
        height: auto;
        overflow-y: auto;

        table {
            thead {
                position: sticky;
                top: 0;
                z-index: 8;
                background: var(--Dark-mode-700, #131F2E);
            }

            .table-row-general {
                &:last-child {
                    border-bottom: 0;
                }

                td {
                    &:first-child {
                        color: var(--Neutral-Grey-500, #647980) !important;
                    }
                }
            }

            th,
            td {
                &.light-color {
                    color: var(--Neutral-Grey-500, #647980) !important;
                }

                color: var(--Neutral-Grey-25, #F7F9F9);
                font-family: Mulish;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 18px;
                padding: 0 12px;
            }

            .table-header-cell {
                background: var(--Dark-Mode-Gradient-Cards, linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.40) 35.14%));
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
                height: 44px;
                padding: 16px 8px;
                align-items: center;
            }

            .back-up {
                color: var(--Neutral-Grey-25, #F7F9F9) !important;

                .select-name {
                    margin-left: 0;

                    & .custom-dropdown {
                        background: var(--Dark-mode-800, #001023);
                        border-radius: 8px;

                        & .dropdown-list {
                            background: var(--Dark-mode-800, #001023);

                            li {
                                color: var(--Neutral-Grey-25, #F7F9F9);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 20px;
                            }
                        }
                    }

                    & .backup-input {
                        width: 100%;
                        height: 40px;
                        border-radius: 8px;
                    }
                }
            }
        }
    }
}