.no-data {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    top: 50%;
    margin-top: 80px;

    .icon,
    .heading,
    .description {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nodata-heading {
        border-bottom: none !important;
        background: none !important;
        z-index: 0 !important;
    }

    .icon {
        display: flex;
        width: 56px;
        height: 56px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
    }

    .heading {
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
        text-align: center;
        font-family: Mulish;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 23px;
        letter-spacing: -0.38px;
    }

    .description {
        color: var(--Neutral-Grey-200, var(--Neutral-Grey200, #B8C5CC));
        text-align: center;
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }


}