.my-ticket-list-section {
    height: 265px;
    width: 100%;
    // margin: 0px px;
    background: var(--Darkmode-800, #001023) !important;
    max-width: 100%;
    overflow-y: auto;
    margin-top: -15px;
    border-top-left-radius: 0px;

    .ticket-card:first-child {
        position: sticky;
        left: 0px;
        z-index: 10;
    }

    .ticket-card {
        width: 340px;
        min-width: 340px;
        max-width: 340px;
        min-height: 195px;
        padding: 16px;
        margin: 18px 3px 24px 17px;

        .ticket-card-header {
            .ticket-id {
                font-weight: 600;
                font-size: 18px;
                line-height: 28px;
                letter-spacing: 0%;
                color: #F4FBFC;
            }

            .ticket-status {
                height: 22px;
                border-radius: 4px;
                padding: 2px 8px;
                font-family: Mulish;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0%;
                text-align: center;

                &.new {
                    color: var(--Success-Green400, #32D583);
                    border: 1px solid var(--Success-Green400, #32D583)
                }
                &.pending {
                    color: #FDB022;
                    border: 1px solid #FDB022;
                }
                &.closed {
                    color: #b8c5cc;
                    border: 1px solid #b8c5cc;
                }
                &.open {
                    color: var(--Success-Green400, #32D583);
                    border: 1px solid var(--Success-Green400, #32D583)
                }
                &.solved {
                    color: #b8c5cc;
                    border: 1px solid #b8c5cc;
                }
            }
        }

        .ticket-card-content {
            padding: 12px 0px !important;

            .ticket-subject {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0%;
                color: #F4FBFC;
                min-height: 60px;
                max-height: 60px;
                overflow: auto;
            }

            .ticket-requestor {
                font-weight: 400;
                font-size: 10px;
                line-height: 12px;
                letter-spacing: 0%;
                color: var(--Neutral-Grey25, #F7F9F9);
                margin-top: 15px;
            }

        }

        .ticket-card-footer {
            padding-top: 12px;
            height: 36px;

            .ticket-assigned-to {
                width: 31px;
                height: 30px;
                border-radius: 30px;
                background: #437EF7;
                border: 1px solid #7FBDC9;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--Neutral-Grey25, #F7F9F9);
            }
            .cancel-btn {
                height: 38px;
                width: 80px;
            }
            .hidden-claim {
                display: none;
            }
        }
    }

}