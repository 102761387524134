.trend-group-button-container {
	display: flex;
	align-items: center;
	gap: 0.75rem;
}
.trend-group-button {
	display: flex;
	padding: 0.25rem;
	align-items: center;
	gap: 0.25rem;
	background: none;
	border: none;
	&:hover {
		cursor: pointer;
	}
}
