@import '/src/styles//darktheme/utilities/variables';

.build-watchlist-filters-modal.modal-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  .modal-content {
    border-radius: 12px;
    border: 1px solid var(--Darkmode-400, #4a5463);
    background: var(--Darkmode-700, #131f2e);
    box-shadow:
      0px 223px 62px 0px rgba(0, 0, 0, 0),
      0px 143px 57px 0px rgba(0, 0, 0, 0.02),
      0px 80px 48px 0px rgba(0, 0, 0, 0.07),
      0px 36px 36px 0px rgba(0, 0, 0, 0.12),
      0px 9px 20px 0px rgba(0, 0, 0, 0.13);
    padding: 20px;
    width: 60%;
    position: relative;
    min-height: 400px;
    height: 85%;
    padding: 32px;
    overflow: hidden;
  }

  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .modal-title {
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 34px;
      letter-spacing: -1.12px;

      color: var(--Primary-Indigo-25, var(--Primary-Indigo25, #f4fbfc));
      margin: 0;
    }

    .close-btn {
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 25px;
      right: 15px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      transition: background-color 0.3s;

      &:hover {
        background-color: var(--Darkmode-600, #1d2a3e);
      }
    }
  }

  .modal-body {
    // overflow: hidden;
    height: 100%;

    .asset-count-div {
      display: flex;
      align-items: center;
      margin-top: 35px;
      gap: 12px;
    }

      .asset-count {
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #f7f9f9));
        font-size: 39px;
        font-weight: 700;
        line-height: 47px;
        letter-spacing: -1.56px;
      }

      .asset-count-label {
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #f7f9f9));
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
      }
    }

    .filters-form-container {
      margin-top: 10px;
      display: flex;
      widows: 100%;
      height: 100%;
      overflow: hidden;

      .watchlist-filters {
        width: 30%;
        height: 100%;
        // background-color: #60BFDA;
        height: 100%;
        overflow: scroll;
        padding-bottom: 130px;
        padding-right: 15px;

        .filter-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid var(--Darkmode-400, #4a5463);
          cursor: pointer;
        }

        .watchlist-filters-heading {
          font-size: 18px;
          font-weight: 600;
          line-height: 28px;
          letter-spacing: -1.12px;
          color: var(--Primary-Indigo-25, var(--Primary-Indigo25, #f4fbfc));
          margin: 0;
          padding: 16px 10px;
        }

        .watchlist-filters-title {
          color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #f7f9f9));
          font-size: 16px;
          line-height: 24px;
          padding: 10px 10px 10px 8px;
        }

        .filter-buttons {
          display: flex;
          justify-content: space-between;
          padding: 0 14px;
        }

        .filter-button {
          color: var(--Primary-Indigo-400, var(--Primary-Indigo400, #60bfda));
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
          cursor: pointer;
        }

        .expanded-filter {
          display: none;
          padding: 16px 0;

          .operators-search-filter {
            input {
              padding-left: 2.125rem;
            }
            input:focus-visible {
              outline: 1px solid #616161;
            }
          }

          .status-search-filter {
            input {
              padding-left: 2.125rem;
            }
            input:focus-visible {
              outline: 1px solid #616161;
            }
          }
          .parameter-search-filter {
            input {
              padding-left: 2.125rem;
            }
            input:focus-visible {
              outline: 1px solid #616161;
            }
          }
        }

        .expanded {
          display: block;
        }

        .filter-checkboxes {
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin-left: 34px;
          margin-top: 16px;

          .filter-checkbox {
            display: flex;
            align-items: center;
            gap: 12px;
          }
        }
      }

      .watchlist-form {
        width: 70%;
        padding: 24px;
        overflow-y: auto;
        height: 68%;

        .name-description-form-group {
          display: flex;
          gap: 24px;
        }

        .form-group.name {
          flex-grow: 1;
          width: unset;
        }

        .form-group.description {
          flex-grow: 3;
          width: unset;
        }

        .form-group {
          margin-bottom: 20px;

          .form-label {
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #f7f9f9));
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
          }

          .form-control {
            border-radius: 8px;
            border: 1px solid var(--Darkmode-400, #4a5463);
            background-color: var(--Darkmode-800, #001023) !important;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            height: unset !important;
            margin-bottom: 24px;
          
            &:focus {
              border-color: #000000 !important;
              outline: none;
              box-shadow: none;
            }

            &:focus-visible {
              border-color: #000000 !important;
              background-color: #000000;
            }
          }

          textarea.form-control {
            margin-bottom: 8px;
          }

          .form-control:focus-visible,
          .form-control:focus {
            border-color: #000000 !important;
          }
        }

        .selected-filters-sec {
          display: flex;
          flex-direction: column;
          gap: 17px !important;

          .selected-filter-div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            border-radius: 8px;
            border: 1px solid var(--Darkmode-400, #4a5463);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

            .selected-filter-div h4 {
              color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #f7f9f9));
              font-size: 18px;
              font-weight: 500;
              line-height: 28px;
            }

            .count-del-btn {
              display: flex;
              gap: 100px;
              align-items: center;

              p {
                color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #f7f9f9));
                font-size: 33px;
                line-height: 40px;
                letter-spacing: -1.32px;
              }
            }

            .fre-filter {
              gap: 0px;
              width: unset;
              margin: 0;
            }
            .border-col {
              border: 1px solid #4a5463;              
              border-radius: 10px;
              background-color: #001023;
            }
            .border {
              border: none;
              width: 0px;
              background-color: #001023;

            }
            & .border:focus {
              outline: none;
              box-shadow: none;
            }
            .gap {
              gap: 10px;
            }
            .unit {
              font-size: 14px;
              color: #f7f9f9;
            }

            .fre-filter div {
              display: flex;
              gap: 14px;
              align-items: center;
            }
            .fre-filter div input {
              margin: 0;
            }

            .fre-input {
              width: 37px;
            }
          }
        }
      }
    }
  }

  .modal-footer {
    // border-top: 1px solid var(--Darkmode-400, #4A5463);
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding-top: 35px;
    position: absolute;
    bottom: 17px;
    right: 50px;

    .CR-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 170px
  }
}


.checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 16px;

  input {
    position: absolute;
    left: -8px;
    opacity: 0;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  p {
    margin-left: 25px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .checkbox-checkmark {
    &::after {
      left: 7px;
      top: 3px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

.checkbox-checkmark {
  position: absolute;
  top: 3px;
  left: -8px;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 1px solid var(--Dark-mode-300, $dark-light-shade3);
  background: var(--Dark-mode-800, $dark-primary);

  &::after {
    content: '';
    position: absolute;
    display: none;
    border: none;
  }
}

.build-watchlist-loader .loader-container {
  position: unset !important;
}

.filters-loading .loader-container {
  position: unset !important;
}

.watchlist-filters .loader-container .loader {
  width: 25px;
  height: 25px;
}