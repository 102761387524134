@import '../../../../styles/darktheme/utilities/variables';

/* Add this CSS to your stylesheets */
.right-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  /* Adjust the width as needed */
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.2);
  /* Shadow on the left side of the modal */
  z-index: 1000;
  /* Adjust the z-index as needed to ensure it's on top of other elements */
  display: flex;
  flex-direction: column;
}

/* Add other styles for your modal content as needed */
.Schduled-speed {
  & .heading {
    font-size: 18px;
    font-weight: 700;
    text-align: start;
  }

  & .pd-20 {
    padding: 15px;
  }
}

.date-time-picker {
  position: relative;
  margin-top: 5px;

  .rmdp-container {
    .rmdp-calendar-container-mobile {
      position: absolute !important;
      top: 50px !important;

      .rmdp-mobile.rmdp-wrapper {
        transform: none !important;
        border: 1px solid #4A5463;
        left: 0% !important;
        top: 5px !important;
      }

      .rmdp-mobile .rmdp-week-day {
        margin: 0px !important;
      }

      .rmdp-mobile .rmdp-header {
        margin-top: 0px !important;
      }
    }

    .rmdp-mobile .rmdp-action-buttons {
      display: flex;
      justify-content: space-between;
      border-top: none !important;

      .rmdp-action-button {
        width: 142px;
        height: 40px;
        padding: 10px 16px 10px 16px;
        gap: 8px;
        border-radius: 8px;
        border: 1px 0px 0px 0px;
        color: white;

      }

      .rmdp-action-button:nth-child(1) {
        background: #001023;
        border: 1px solid #B8C5CC;
      }

      .rmdp-action-button:nth-child(2) {
        background: #00789E;
      }
    }
  }

  .rmdp-wrapper {

    .rmdp-top-class {
      border: none;
    }

    .rmdp-calendar {
      border-right: none;
      border-bottom: 1px solid #4A5463;
    }

    .rmdp-header {
      margin-bottom: 65px;
    }

    .rmdp-toolbar {
      position: absolute;
      right: 32px;
      top: 70px;

      div {
        width: 73px;
        height: 44px;
        padding: 10px 16px 10px 16px;
        gap: 8px;
        border-radius: 8px;
        border: 1px 0px 0px 0px;
        background: #131F2E;
        border: 1px solid #4A5463;
      }
    }

    .focused-date {
      position: absolute;
      top: 75px;
      left: 35px;
      width: 194.92px;
      height: 44px;
      padding: 10px 12px 10px 12px;
      gap: 8px;
      border-radius: 8px;
      border: 1px 0px 0px 0px;
      border: 1px solid #4A5463;
      background: #001023;


    }
  }




}




.rmdp-input {
  height: 100%;
  width: 100%;
}

.rmdp-month-picker,
.rmdp-year-picker {
  background-color: #4a5463;
}

.bg-dark.rmdp-wrapper,
.bg-dark .rmdp-month-picker,
.bg-dark .rmdp-year-picker,
.bg-dark .rmdp-time-picker div input,
.rmdp-container .bg-dark.ep-arrow::after {
  background-color: var(--darkmode-700, #131f2e) !important;
}

.date-time-picker {
  display: flex;
  align-items: center;

  .date-time-container {
    width: 100%;
    display: flex;

    .rmdp-container {
      width: 40%;
      height: 46px !important;
    }

    .calendar-icon {
      margin-left: 1rem;
      width: 10%;

      .calendar-img {
        padding: 0.8rem;
        background-color: #131f2e;
        border: 1px solid #4a5463;
        border-radius: 8px;
        height: 46px;
      }
    }

    .hours-mints-input {
      width: 25%;
    }

    .ampm-button-group {
      width: 25%;
    }
  }

  .rmdp-container {
    height: 100% !important;

    .rmdp-input {
      height: 100%;
    }
  }
}

// .date-input-field,
// .time-input-field {
//   width: 100px;
// }

.calendar-button {
  margin-left: 10px;
}

.ampm-button-group {
  display: flex;
}

.ampm-button {
  margin-left: 5px;
}

.ampm-button.active {
  background-color: transparent;
  color: #60BFDA !important;
  padding: 0;
}

.loader-scan {
  padding: 10rem 0;
  text-align: center;
  font-size: 1.2rem;

  .loader-icon {
    width: 5rem;
    opacity: 0.2;
    margin: 0 auto 1rem auto;
  }
}

.btn.add-btn:disabled {
  border: 1px solid $dark-light-shade2;
  background: #202c3b;
  color: $dark-light-shade2;
  cursor: not-allowed;
}

.scheduleSpeed {
  &.right-modal {
    width: 50%;
  }

  &__add-block {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $dark-primary;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  &__header-bar {
    border-bottom: 1px solid $dark-light-shade2;
    height: auto;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      padding: 0.5rem 1rem;

      span {
        color: #B8C5CC;
      }
    }
  }

  &__profile-block {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-top: 0.5rem;
    height: auto;
    // max-height: 800px;
    overflow-y: auto;
  }

  &__notify-card {
    border: none;
    width: 100%;
  }

  &__header {
    color: $white;
    width: 100%;
    border-bottom: 1px solid $dark-light-shade2;

    span {
      &.title {
        font-size: 23px;
        line-height: 28px;
        font-weight: 700;
        border: none;
        padding: 0 16px;
      }
    }

    div {
      padding: 0 16px 8px;
      font-size: 14px;
      line-height: 20px;
      color: #B8C5CC;
      text-align: left;
    }
  }

  &__form-body {
    margin: 1.5rem;
    border: 1px solid $dark-light-shade2;
    border-radius: 8px;
  }

  &__time-block {
    display: flex;
    padding: 20px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
  }

  &__form-control {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 6%;
    width: 100%;
    padding: 0 1.5rem;

    label {
      width: 20%;
      text-align: left;
      padding-top: 8px;
    }

    input.time-input-field {
      width: 80%;
      padding: 1rem;
      background-color: $dark-secondary;
      color: $neutral-light-shade3;

      &:disabled {
        color: $primary-dark-border !important;
      }
    }

    .input-field__container {
      width: 80%;
      position: relative;

      input {
        width: 100%;
      }

      &.error-container {
        input {
          border: 1px solid $error-dark-shade5;

          &:focus,
          &:hover {
            outline: none;
            border: 1px solid $error-dark-shade5;
            box-shadow: none;
          }
        }
      }

      .error-icon {
        position: absolute;
        top: 17px;
        right: 1rem;
        width: 16px;
        height: 16px;
      }

      .stepped-error-icon {
        position: absolute;
        bottom: 40px;
        right: 1rem;
        width: 16px;
        height: 16px;
      }

      p.error-message {
        color: $error-dark-shade5;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding: 5px 0px 0px;
      }
    }

    textarea.time-input-field {
      width: 80%;
      height: 160px;
      background-color: $dark-secondary;
      color: $neutral-light-shade3;

      &::placeholder {
        color: $neutral-light-shade3;
      }
    }

    .date-time-picker {
      display: flex;
      flex-direction: column;
      width: 80%;
      justify-content: left;

      .date-time-container {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;

        span.date-input-field {
          display: flex;
          width: 50%;
          height: 48px;

          input {
            width: 30%;
            border-radius: 8px;
            padding-left: 8px;
            background-color: $dark-secondary;

            &::placeholder {
              color: $white;
              padding-left: 8px;
            }
          }

          .p-datepicker-trigger {
            width: 20%;
            border: 1px solid $dark-light-shade2;
            border-radius: 8px;
            color: $white;
            margin-left: 8px;
            margin-right: 1rem;
            background-color: $dark-secondary;

            svg {
              width: 20px;
              height: 20px;
            }
          }
        }

        .time-input-field.hours-mints {
          // width: 25%;
          color: $white;

          &::placeholder {
            color: $white;
          }
        }

        .description-input-field {
          min-height: 100px; // Adjust the height as needed
          resize: vertical; // Allow vertical resizing
        }

        .ampm-button-group {
          width: 25%;
          border: 1px solid $dark-light-shade2;
          border-radius: 8px;
          height: 48px;
          display: flex;
          justify-content: space-evenly;
          gap: 8px;
          background-color: $dark-secondary;

          .ampm-button {
            color: $white;
            margin: 0;

            &:hover,
            &:active,
            &:focus {
              color: #60BFDA;
            }

          }
        }
      }

      p.error-message {
        width: 100%;
        color: $error-dark-shade5 !important;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        padding: 5px 0px 0px;
      }

    }
  }

  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $dark-light-shade2;
    color: $neutral-light-shade3;
    padding: 1rem 1.5rem;
  }

  .basis-one {
    flex-basis: 10%;
  }

  .basis-four {
    width: 90%;
  }

  &__description {
    div {
      padding: 10px 14px 10px 14px;
      border: 1px solid $primary-dark-border;
      background-color: $dark-secondary;
      border-radius: 4px;
      margin: 1rem;
      color: $neutral-light-shade3;
      height: 55px;
    }
  }

  &__dropdown-container {
    display: flex;
    // padding-top: 1rem;
    justify-content: flex-end;
    position: relative;

    .dropdown-options {
      position: absolute;
      top: 100%;
      right: 0;
      margin-top: 5px;
      background-color: #001023;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      z-index: 9999;
      width: 300px;
      border-radius: 8px;
      overflow: hidden;

      button {
        padding: 12px 8px;
        border-radius: 0;

        &:hover,
        &:focus,
        &:active {
          border-radius: 0;
        }
      }
    }
  }

  &__dropdown-btn {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    padding: 8px 16px;
    filter: invert(1);

    span {
      height: 2px;
      width: 4px;
      background-color: $neutral-dark-shade3;
      border-radius: 50%;
      margin: 2px 0;
    }

    &:hover,
    &:focus,
    &:active {
      border: none;
      background: rgba(184, 197, 204, 0.2);
      border-radius: 8px;
      color: $neutral-dark-shade3;
    }
  }

  &__card {
    background-color: $dark-primary;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 0;
    border: 1px solid $primary-dark-border;
    border-radius: 8px;
  }

  &__seperator {
    border: 1px solid $dark-light-shade2;
  }

  &__btn-notify {
    display: flex;
    justify-content: end;
    gap: 14px;
    margin: 1.25rem 1.5rem 1.25rem 0;

    .btn-secondary {
      background-color: $dark-secondary;
    }
  }

  &__title {
    border: none;
    padding: 0;
    font-weight: 400;
    color: $white;

    &.two {
      display: flex;
      align-items: center;
    }
  }

  .sm-highlt {
    display: flex;
    gap: 5px;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
    }

    &.Pending {
      border: 1px solid #60BFDA;
      color: #60BFDA;
    }

    &.InProgress {
      border: 1px solid #F68D2E;
      color: #F68D2E;
    }

    &.Completed {
      border: 1px solid #32D583;
      color: #32D583;
    }

    &.Failed,
    &.Cancelled,
    &.Shutdown {
      border: 1px solid #F97066;
      color: #F97066;
    }
  }

  .list-inline-items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding-right: 1rem;
      padding-top: 1rem;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &.add-btn {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    padding: 8px 14px;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    border-radius: 8px;
    border: 1px solid #00789e;
    background: #005f7c;
    color: #f7f9f9;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin-top: 8px;
  }

  &__body {
    text-align: center;
    padding: 1.5rem;
    width: 100%;

    p {
      color: #6F7887;
    }
  }
}