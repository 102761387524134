@import '../../../../../styles/darktheme/utilities/variables';

.well-performance-trends {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    width: 100%;

    &__dropdown-field {
        display: flex;
        padding: 10px 14px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border: 1px solid var(--Dark-mode-300, $dark-light-shade3);
        background: var(--Dark-mode-700, $dark-secondary);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--Neutral-Grey-25, $dark-text-primary);

        option {
            display: flex;
            width: 320px;
            padding: 12px 16px;
            flex-direction: column;
            align-items: flex-start;
            border-bottom: 1px solid var(--Dark-mode-400, $primary-dark-border);
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
            font-family: Mulish;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            border-radius: 8px;
        }
    }

    &__input-group {
        display: inline-flex;
        transform: rotate(-0.221deg);
        padding: 10px 8px 10px 16px;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        position: relative;
        left: 0;
        border-radius: 8px;
        border: 1px solid var(--Dark-mode-400, $primary-dark-border);
        background: var(--Dark-mode-800, $dark-primary);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        width: 230px;
        height: 40px;

        & .input-date {
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
            font-family: Mulish;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            border: none;
            background: none;
            width: 200px;

            &:focus {
                outline: none;
            }
        }
    }

    &__dropdown {
        position: relative;
        width: 200px;
         img {
            position: relative;
            right: 1rem;
         }
    }

    &__dropdown-input-container {
        display: flex;
        padding: 10px 14px;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--Dark-mode-400, $primary-dark-border);
        background: var(--Darkmode-700, $dark-secondary);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        width: 100%;
        cursor: pointer;

        img.mr-3 {
            position: relative;
            pointer-events: visible;
            cursor: pointer;
        }
    }

    &__dropdown-input {
        border: none !important;
        cursor: pointer;
        background-color: transparent;
        color: #f7f9f9;

        &:focus {
            outline: none;
        }
    }

    &__dropdown-list {
        position: absolute;
        z-index: 2;
        top: 100%;
        left: 0;
        width: 320px;
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1px;
        border-radius: 8px;
        border: 1px solid var(--Dark-mode-400, $primary-dark-border);
        background: var(--Darkmode-800, $dark-primary);
        box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    }

    &__dropdown-item {
        display: flex;
        padding: 12px 16px;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        border-bottom: 1px solid var(--Dark-mode-400, $primary-dark-border);
        cursor: pointer;
        &:last-child {
            border: none;
        }
        &:hover, &:focus {
            background: var(--darkmode-700, $dark-secondary);
            &:first-child {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }
            &:last-child {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }
    }

    &__header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
    }
}

.wellPerformanceChartSection {
    .no-data {
        margin-top: 20px;
    }
}
.react-grid-item {
    overflow: visible;
}

.trends-input {
    position: relative;
}

.well-performance-loader {
    width: 100%;
    position: relative;
    margin-top: 40px;
}

.well-performance-modal-loader {
    width: 100%;
    position: relative;
    margin-top: 150px;
}

.modal-content {
    .date-picker__container {
        position: absolute;
        left: calc(100% - 870px);
        top: 132px;
    }
}

.asset-count-loader .loader-container .loader {
    height: 25px;
    width: 25px;
}

.wellperchart-container {
    height: 100%;
    width: 100%;
}