@import '../../../../styles/darktheme/utilities/variables';

.job-schedule-card {
  width: 100%;
  height: auto;
  border-radius: 8px;
  border: 1px solid var(--Dark-mode-400, #4A5463);
  background: var(--Darkmode-800, #001023);
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  // margin: 1.2em 1em 0 1em;
  // padding-bottom: 2rem;

  & .table-header-section {
    & .title {
      padding: 0;
    }
  }

  & .ticket-count {
    margin-bottom: 16px;
  }

  & .table-container-dashboard {
    background: var(--Darkmode-800, #001023);
    width: 100%;
    height: 100%;
  }

  & .job-schedule {
    display: table;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    background: var(--Darkmode-800, #001023);

    & .table-header-cell {
      display: flex;
      width: 100%;
      gap: 8px;
      border-top: none;
    }

    & .job-schedule-body {
      display: block;
      /* max-height: calc(100vh - 450px); */
      min-height: auto;
      max-height: 182px;
      overflow-y: auto;
      // height: auto;


      tr {
        display: flex;
        width: 100%;
        gap: 8px;
        height: auto;
        min-height: 50px;
        max-width: unset;
        border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
      }

      .table-row-general .well-name{
        cursor: pointer;
      }
    }

    & .well-name {
      width: 23%;
      font-weight: 700;
    }

    & .operator,
    & .job-date,
    & .job-created {
      width: 16%;
    }

    & .assigned {
      width: 14%;
      text-transform: capitalize;
    }

    & .job-type {
      width: 15%;
    }
  }
}

table {
  thead {
    tr {
      th {
        pointer-events: visible;
        cursor: pointer;
      }
    }
  }

  .pointer {
    cursor: pointer;
  }
}

.event-schedule-card {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--Dark-mode-300, #5D6675);
  background: linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 332px;
  height: 367px !important;
  & .no-data {
    margin-top: 0;
  }
  & .separator {
    border-top: 1px solid var(--Dark-mode-400, #4A5463);
    margin: 0 16px;
  }

  & .event-schedule {
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;

    & .table-header-cell {
      display: flex;
      width: 100%;
      gap: 8px;

      th {
        padding-right: 0;
      }
    }

    & .event-schedule-body {
      display: block;
      max-height: 215px;
      overflow-y: auto;
      height: auto;
      min-height: 215px;

      tr {
        display: flex;
        width: 100%;
        gap: 16px;
        height: auto;
        max-height: 60px;
        max-width: unset;
        border-bottom: 1px solid var(--Dark-mode-400, #4A5463);

        td {
          padding-right: 0;
          &.event-assigned {
            text-transform: capitalize;
          }
        }
      }
    }

    & .well-name {
      width: 28%;
      font-weight: 700;
    }

    & .operator {
      width: 22%;
    }

    & .event-date {
      width: 16%
    }

    & .event-assigned {
      width: 15%;
    }

    & .event-type-one {
      width: 19%;
    }
  }
}


.view-all-modal {
  position: relative;
  width: 900px;
  text-align: center;

  .modal-header {
    padding: 14px;
    border-bottom: 1px solid var(--Dark-mode-400, #4A5463);

    .title {
      border: none;
      padding-left: 8px;
    }
  }

  .modal-content {
    padding: 14px 14px 20px;
  }

  .chart-wrap-modal.ticket-modal {
    background: var(--Dark-mode-700, #131F2E);
    height: auto;

    // max-height: calc(100vh - 250px);
    .table {
      & .table-header-cell {
        display: flex;
        width: 100%;
        gap: 8px;
        border-top: none;
        background: var(--Dark-mode-700, #131F2E);
        border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
        padding-right: 0;

        th {
          color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
        }
      }

      & .ticket-modal-body {
        height: auto;
        max-height: calc(100vh - 200px);
        overflow-y: auto;
        display: block;

        tr {
          display: flex;
          width: 100%;
          gap: 8px;
          height: auto;
          min-height: 44px;
          max-width: unset;
          border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
          padding-right: 0;

          td {
            color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
          }
        }
      }

      & .well-name {
        width: 28%;
        font-weight: 700;
      }

      & .operator {
        width: 27%;
      }

      & .job-date {
        width: 15%;
      }

      & .assigned {
        width: 15%;
        // text-transform: capitalize;
      }

      & .job-type {
        width: 15%;
      }
    }
  }

  .separator {
    border: 1px solid var(--Dark-mode-400, #4A5463);
    margin: 0 -14px;
  }
}

.operator {
  position: relative;

  &[data-title]:hover::after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
    height: auto;
    min-height: 44px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  &[data-title]::after {
    background-color: #001023;
    color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
    content: attr(data-title);
    border-radius: 8px;
    padding: 8px;
    position: absolute;
    z-index: 99;
    top: 14px;
    left: 25px;
    opacity: 0;
    visibility: hidden;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
    font-size: 14px;
    width: max-content;
    max-width: 320px;
  }

  &[data-title] {
    position: relative;
  }
}