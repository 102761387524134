@import "../../../styles/darktheme/utilities/_variables";

.profile-status-header-admin {
  margin-top: 63px;
}

.title-mark {
  font-size: 11px;
  color: $light-primary-shade3;
  background: $light-primary-shade10;
  padding: 3px 5px;
  border-radius: 3px;
  position: relative;
  top: -3px;
  margin: 0 0 0 7px;
}

.profile-card {
  .body {
    &.user-details-body {
      padding: 0.8rem 1.6rem 1.6rem;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -15px;
  margin-right: -15px;
}

.flex-col {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
}

.col-4 {
  flex: 0 0 auto;
  width: 30%;
}

.col-8 {
  flex: 0 0 auto;
  width: 70%;
}

.btn-icon {
  font-size: 18px;
  margin-right: 5px;
  max-width: 16px;
}

.admin-content-search {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.admin-search-input-div {
  display: flex;
  flex-direction: row;

  .input-group {
    min-width: 330px;
    border-radius: 8px;
    border: 1px solid var(--Dark-mode-400, #4A5463);
    background: var(--Darkmode-800, #001023);
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    height: 44px;
    align-items: center;

    & .search-icon-block {
      width: 15px;
      height: 15px;
      margin-left: 12px;
    }

    input.custom-input {
      border: none;
      background: var(--Darkmode-800, #001023);
      color: var(--Neutral-Grey-200, var(--Neutral-Grey200, #B8C5CC));
      padding: 0 0 0 8px;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    &:focus {
      outline: auto;
    }
  }

  .custom-input {
    height: 37px;
  }

  .toolbar-eye-btn {
    margin-left: 12px;

    img {
      max-width: 18px;
    }

    .chevron-icon {
      max-width: 12px;
      top: 16px;
      left: 80%;
    }
  }
}

.adminuser-block {
  border: 1px solid $neutral-light-shade4;
  border-radius: 8px;
  background: linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%);
}

.userblock {
  padding: 1.25rem;

  .user-ui-button-toolbar {
    margin: 0 0 20px;

    // background: linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%);
    & .ui-button-toolbar {
      background: linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%);
    }
  }

  .card.username-card {
    // margin-top: 0.5rem;
    // width: 96.5%;
    border-radius: 8px;
    border: 1px solid var(--Dark-mode-400, $dark-light-shade2);
    background: linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%);
    overflow: unset;
  }

  .tab-content {
    width: auto;

    // margin: 1rem;
    .notification-preferences {
      margin: 2px;
    }
  }
}

.username-card {

  // margin: 0 0 30px;
  .body {
    padding: 1rem;
   display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .btn-default {
      align-self: flex-start;
    }
  }
}

.useravatar {
  display: flex;
  align-items: center;
}

.avatar-img {
  margin: 0 18px 0 0;
  width: 90px;
  height: 90px;
  border-radius: 500px;
  border: 5px solid var(--primary-indigo-400, #60bfda);
  overflow: hidden; // padding: 4px;

  img {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    border-radius: 500px;
  }
}

.useravatar-details {
  .title {
    font-weight: 700;
    display: inline-block;
    // margin: 0 0 10px;
    font-size: 1.5rem;
    border: none;
    padding-left: 8px;
  }

  .status {
    display: inline-flex;
    padding: 4px 9px;
    justify-content: center;
    align-items: center;
    gap: 3px;
    border-radius: 4px;
    border: 1px solid var(--Neutral-Grey-400, #91A4AA);
    background: var(--Dark-mode-600, #253040);
  }

  .job-role {
    display: flex;
    padding-left: 8px;
  }
}

.info-card {
  margin: 0 0 20px;

  .header-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: unset;
    align-items: center;
    flex-direction: row;
    padding: 0.8rem 1rem !important;

    .title {
      font-weight: 700;
      font-size: 1.1rem;
    }
  }

  .user-details {
    padding: 1.5rem;
  }
}

.user-details ul,
.user-checklist ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.user-checklist ul {
  max-height: calc(100vh - 200px);
  ;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-wrap: nowrap !important;
  flex-direction: column;
}

.user-details {
  li {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .namelabel {
      width: 200px;
      font-weight: 600;
      color: black;
    }

    .info {
      flex-grow: 1;
      color: black;
    }
  }
}

.user-checklist {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-height: 100vh;

  & ul {
    border-right: 1px solid $neutral-light-shade4;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-height: 100%;

    //max-height: calc(100vh - 170px);
    & li {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid $neutral-light-shade4;
      padding: 14px 12px 14px 24px;
      transition: all 0.2s ease-in-out;
      width: 100%;
      max-width: 100%;
      position: relative;
      cursor: pointer;

      &:first-child {
        border-top-left-radius: 8px;
      }
    }

    .namelabel {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 0;
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }

    .namedata {
      font-weight: 700;
      font-size: 1.02rem;
      word-wrap: break-word;
    }

    .info {
      word-wrap: break-word;
      width: 100%;
      max-width: 100%;
    }
  }
}

.user-checklist {
  & li {
    background: transparent;
    box-shadow: none;
    color: $black;

    &:focus,
    &:hover {
      background: var(--Dark-mode-400, $dark-light-shade2);

      &:first-child() {
        border-top-left-radius: 8px;
      }
    }

    .notfound {
      color: $black;
    }
  }
}

.user-checklist {

  & .emaildata,
  & .phonedata {
    word-wrap: break-word;
  }
}

.checkbox {
  margin-right: 0;
  padding-left: 1.75rem;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 20px;
  height: 20px;

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  input[type='checkbox']:focus {
    &~span {
      border: 2px solid #aab0b9;
    }

    &:checked {
      &~span {
        border: 2px solid $light-primary-shade2;
      }
    }
  }

  input[type='checkbox']:checked {
    &~span {
      color: #ffffff;
      background: $light-primary-shade2 url('../../../images/check.svg') 50% 40% no-repeat;
      border: 2px solid $light-primary-shade2;
    }
  }

  span {
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    background-color: transparent;
    border: 2px solid #d4d7dc;
    // pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.selected {
  background: #f4fbfc !important;
}

.admin-profile {
  &__container {
    display: flex;
    flex-grow: 1;
    background: var(--Dark-mode-800, $dark-primary);
    width: 100%;
  }

  & .card.profile-card {
    margin: 2px;
  }

  & .admin-title {
    color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    display: flex;
    gap: 8px;
    padding-bottom: 4px;

    & .admin-users {
      display: flex;
      padding: 2px 8px;
      align-items: center;
      border-radius: 4px;
      border: 1px solid var(--Primary-Indigo-400, $light-primary-shade5);
      color: var(--Primary-Indigo-400, $light-primary-shade5);
      text-align: center;
      font-family: Mulish;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
    }
  }

  & .admin-text {
    color: var(--Neutral-Grey-200, var(--Neutral-Grey200, #B8C5CC));
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  & .user-filter {
    padding: 8px 14px 8px 8px;
    height: 44px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    &.filter-active {
      border-radius: 8px;
      background: var(--Darkmode-700, #131F2E);
      border: 4px solid var(--Darkmode-800, #001023);
      ;
    }

    & .toolbar-eye-btn {
      display: flex;
      margin-left: 0;
      padding: 4px 4px 4px 14px;

      .filter-icon {
        width: 30px;
        height: 30px;
      }

      .chevron-icon {
        width: 10px;
        height: 5px;
        left: 80%;
      }
    }
  }

  & .admin-first-column {
    border-right: 1px solid var(--Dark-mode-400, $dark-light-shade2);
  }

  .user-checklist {
    ul {
      border-right: none !important;
    }
  }

  & .admin-profile-users {
    border-radius: 6px;
    border: none;
    background-color: var(--Dark-mode-700, #131F2E) !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #001023;
    filter: unset;

    & .dot {
      background-color: #f7f9f9;
    }
  }
}

.ddl-menu.user-filter-container {
  border-radius: 8px;
  border: 1px solid var(--Dark-mode-400, #4A5463);
  background: var(--Darkmode-800, #001023);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  position: absolute;
  top: 44px;
  width: auto;
  min-width: 250px;
  max-width: 356px;
  height: auto;
  z-index: 9;

  & .user-filter-block {
    & .user-filter-header {
      padding: 10px 16px;
      border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
    }

    & .user-filter-search {
      padding: 8px;

      input {
        width: 100%;
        padding: 8px 14px;
        border-radius: 8px;
        border: 1px solid var(--Dark-mode-400, #4A5463);
      }
    }

    & .user-toggle-container {
      height: auto;
      max-height: 380px;
      overflow-y: auto;
      width: 100%;
      padding: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & .toggle-switch-items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 4px;

        h2 {
          display: flex;
          justify-content: flex-start;
          margin: 8px;
        }

        label {
          display: flex;
          justify-content: flex-end;

          &.switch {
            margin: 0;
          }
        }
      }
    }
  }
}