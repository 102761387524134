@import '../../../styles/darktheme/utilities/_variables.scss';

.profile-status-header-user{
  margin-top: 63px;
}

.profile-container {
  background-color: transparent;
}
.profile-status-header {
  background-color: $white;
  width: 100%;
  .group-status-content {
    padding-bottom: 15px;
  }
  .ui-button-toolbar {
    padding: 1rem 1rem 0 2rem;
  }
}
.profile-block {
  width: 100%;
  .divider {
    height: 0.7px;
  }
}
.profile-card {
  width: 97%;
  margin: 15px auto;
  .body {
    padding: 2rem;
    &.body-height{
height: 100%;
    }
  }
  .title {
    color: $black;
    font-size: 18px;
  }
  .header {
    gap: 4px;
    padding: 1.1rem 1.5rem;
    .title {
      font-weight: 600;
      font-size: 1.3rem;
      color: $black;
    }
  }
}
.profile-info {
  display: flex;
  flex-wrap: wrap;
  .protext {
    width: 30%;
    padding-right: 10px;
    .text {
      font-size: 16px;
    }
    & p {
      max-width: 260px;
      color: $black;
    }
  }
  .card {
    width: 50%;
    padding: 15px;
    max-width: 45%;
    padding-top: 25px;
  }
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -11px;
}
.form-col {
  margin: 0 0 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 50%;
  padding: 0 11px;
  &.m0 {
    margin-bottom: 0;
  }
  &.full-col {
    width: 100%;
  }
  & label {
    margin: 0 0 5px;
    font-weight: 500;
    color: $neutral-dark-shade1;
  }
}
.col60 {
  width: 64%;
}
.col40 {
  width: 36%;
}
.custom-input {
  border: 1px solid #ddd;
  border-radius: 8px;
  height: 35px;
  padding: 5px 10px;
  max-width: 100%;
  width: 100%;
  &:focus {
    border-color: #e5e7eb;
    outline: 2px solid rgb(51, 181, 232);
    box-shadow: 0 0 7px rgb(51, 181, 232);
  }
}
.input-group {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  .custom-input {
    padding-left: 32px;
  }
}
.icon-block {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 30px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 4px;
  img {
    max-width: 16px;
    max-height: 100%;
  }
}
.user-card {
  width: 97%;
  margin: 20px auto;
  .body {
    padding: 2rem;
  }
}
.header-text {
  flex: 1 0 0;
  color: var(--neutral-grey-800, #191f23);
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
}
.header-divider {
  display: flex;
  width: 645px;
  height: 1px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  background: var(--neutral-grey-100, #d0d8dd);
}
.card {
  &.user-card {
    .body {
      .title {
        padding-left: 100px;
        font-weight: 600;
        font-size: 1.3rem;
        color: $black;
      }
      .job-role {
        padding-left: 100px;
      }
    }
  }
}
.user-table {
  border-collapse: collapse;
  width: 30%;
}
.user-table th,
.user-table td {
  border: 1px solid #ddd;
  padding: 8px;
}
.checkbox-cell {
  text-align: center;
  border-right: none;
}
.add-block {
  .header-bar button {
    padding: 0.5rem;
    color: $white;
    img {
      margin-right: 0.5rem;
      display: inline-block;
      transform: rotate(180deg);
    }
  }
  .form-control {
    label {
      min-width: 12rem;
    }
    input, textarea {
      flex-grow: 1;
      border: 1px solid $primary-dark-border
    }
  }
}
.companyname {
  color: var(--Neutral-Grey-200, var(--Neutral-Grey200, #B8C5CC));
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.active-list {
  background: var(--darkmode-400, $primary-dark-border) !important;
  color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
  border-left: 3px solid $light-secondary-shade3;
  & .namedata {
    color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
  & .companyname {
    color: var(--Neutral-Grey-200, var(--Neutral-Grey200, $neutral-light-shade3));
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.username-card {
  & .user-dropdown {
    & .selected-option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
    }
    & .active-user-actions {
      border-radius: 8px;
      background: var(--Dark-mode-700, #131F2E);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #001023;
      border: none;
    }
    & .dropdown-list {
      border-radius: 8px;
      border: 1px solid var(--Dark-mode-400, #4A5463);
      background: var(--Darkmode-800, #001023);
      box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      width: 200px;
      position: absolute;
      right: 0;
      left: unset;
      top: 38px;
      li {
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
        padding: 10px;
        &:hover, &:focus {
          border-radius: 6px;
          background: var(--Darkmode-700, #131F2E);
        }
      }
    }
  }
  & .user-arrow-icon {
    margin-left: 8px;
  }
}

