.control-room-ticket-filter {

    .filter-btn {
        background: #131F2E;
        border: 1px solid #4A5463;
        box-shadow: 0px 1px 2px 0px #1018280D;
        color: #F7F9F9;
        height: 40px;
        padding: 8px 14px;
        border-radius: 8px;
        gap: 8px;
        cursor: 'pointer';
    }

    .control-room-ticket.right-modal {
        width: 567px;
        background: #001023;
        // height: auto;
        max-height: 100vh;
        min-height: 100vh;
        display: flex;
        flex-direction: column;

        .ticket-modal-header {
            height: 52px;
            padding: 12px 24px;
            border-bottom-width: 1px;
            font-weight: 700;
            font-size: 23px;
            letter-spacing: -4%;
            color: var(--Neutral-Grey25, #F7F9F9);
        }

        .ticket-modal-content {
            min-height: calc(100vh - 104px);
            max-height: calc(100vh - 104px);
            // height: auto;
            padding: 18px 24px;
            overflow-y: auto;

            // overflow: visible;
            &.overflow-visible {
                overflow: visible !important;
            }

            .selected-filter-section {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;
                max-height: 100px;
                overflow-y: auto;

                .selected-filter {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: space-between;
                    gap: 8px;
                    height: 22px;
                    border-radius: 4px;
                    padding: 2px 8px 2px 6px;
                    border: 1px solid #60BFDA;
                    font-family: Mulish;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 0%;
                    color: #60BFDA;
                    margin-bottom: 5px;


                }
            }

            .ticket-filter-date-picker {
                position: relative;
                justify-content: start !important;
                flex-direction: column;
                align-items: start !important;
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0%;
                color: #F7F9F9;
                gap: 16px;
                overflow: visible;
                z-index: 100;

                .ticket-filter-range .date-picker__container {
                    position: absolute;
                    left: unset !important;
                    right: 12px;
                    top: 50px;
                    z-index: 100;
                    right: 102%;
                }
            }
        }

        .ticket-modal-footer {
            height: 62px;
            width: 100%;
            right: 0px !important;

        }
    }
}