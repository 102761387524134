.control-room-tickets {

    width: 100%;
    height: calc(100vh - 110px);
    padding: 24px 32px;

    .card-layout {
        background: linear-gradient(181.71deg, #253240 1.42%, rgba(37, 48, 64, 0.4) 15.14%);
        border: 1px solid #4A5463;
        box-shadow: 0px 1px 2px 0px #1018280D;
        border-radius: 8px;
        height: 100%;

        .control-room-card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 78px;
            min-height: 78px;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
            color: #F7F9F9;
            border-bottom: 1px solid #4A5463;
            padding-left: 24px;
            padding-right: 24px;

            .add-ticket {
                display: flex;
                gap: 8px;
                height: 36px;
                padding: 8px 14px 8px 14px;
                border-radius: 8px;
                background: #00789E;
                border: 1px solid #005F7C;
                box-shadow: 0px 1px 2px 0px #1018280D;
                font-size: 14px;
                font-weight: 700;
                color: #FFFFFF;
            }
        }

        .control-room-card-body {
            display: flex;
            flex-direction: row;
            flex: 1;
            // max-height: calc(100% - 80px);
            overflow: hidden;

            .ticket-tags {
                width: 271px;
                height: 100%;
                display: flex;
                flex-direction: column;

                .ticket-tag-container {
                    max-height: calc(100% - 120px);
                    min-height: calc(100% - 120px);

                    .tag-list {
                        max-height: calc(100% - 65px);
                        min-height: calc(100% - 65px);
                        overflow-y: auto;
                        overflow-x: hidden;

                        & .alltag {
                            .tag-count {
                                height: auto;

                                & .loader-container {
                                    position: static;
                                    height: auto;
                                    padding: 2px 0;
                                    background: transparent;

                                    & .loader {
                                        height: 25px;
                                        width: 25px;
                                    }
                                }
                            }
                        }
                    }



                }

                .ticket-tag-container .loader-container {
                    position: absolute;
                    height: 100%;
                }


                .tag-header {
                    font-size: 16px;
                    line-height: 24px;
                    text-align: left;
                    color: #F7F9F9;
                    border-bottom: 1px solid #4A5463;
                    padding-left: 12px;
                    padding-right: 12px;

                    .tags {
                        font-weight: 700;
                    }

                    .count {
                        font-weight: 600;
                    }

                    .tag-button-section {
                        padding: 16px 0px;


                    }
                }

                .tag-div {
                    width: 271px;
                    padding: 21px 30px 20px 12px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background: #131F2E;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: left;
                    color: #F7F9F9;
                    border-bottom: 1px solid #4A5463;

                    .tag {
                        font-weight: 700;
                        width: 175px;
                        word-break: break-all;

                    }

                    .count {
                        font-weight: 600;
                    }

                    .tag-count {
                        // width: 29px;
                        height: 21px;
                        border-radius: 2px;
                        padding-right: 6px;
                        padding-left: 6px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    &.active {
                        background: #253040;
                        border-left: 2px solid #DC4405;
                        border-radius: 0px;

                        .tag-count {
                            background: var(--Darkmode-700, #131F2E);
                            padding: 0px 6px;

                        }
                    }

                    &.clickable {
                        cursor: pointer;
                    }
                }
            }

            .ticket-table {
                border-left: 1px solid #4A5463;
                flex: 1;
                overflow: hidden;
                display: flex;
                flex-direction: column;

                .ellipsis {
                    flex-wrap: nowrap;
                    overflow: hidden;
                }

                .ticket-action.disabled {
                    pointer-events: none;
                }

                .ticket-filter {
                    height: 112px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    padding-left: 24px;
                    padding-right: 24px;

                    .bulk-actions {
                        display: flex;
                        align-items: center;
                        height: 40px;
                        padding: 10px 16px 10px 16px;
                        gap: 8px;
                        border-radius: 8px;


                        &.disabled {
                            cursor: not-allowed;
                            pointer-events: none;
                            border: 1px solid #4A5463;
                            box-shadow: 0px 1px 2px 0px #1018280D;
                            color: #4A5463;

                            img {
                                svg {
                                    fill: #4A5463;
                                }
                            }
                        }

                        &.enabled {
                            border: 1px solid #4A5463;
                            box-shadow: 0px 1px 2px 0px #1018280D;
                            color: #F7F9F9;
                            cursor: pointer;
                        }

                        img {
                            width: 12px;
                            height: 12px;
                        }



                    }

                    .dropdown-options {
                        right: -123% !important;
                        z-index: 11 !important;
                    }

                    .filter-section {
                        .search-div {
                            width: 400px;

                            input {
                                width: 100%;
                                padding-left: 30px;
                            }
                        }
                    }
                }

                .ticket-table-wrapper {
                    flex: 1;
                    overflow: hidden;

                    .ticket-table-container {
                        height: 100%;
                        max-height: 100% !important;
                    }

                    table {
                        overflow: auto;
                        max-height: 100%;

                        thead {
                            position: sticky;
                            top: 0px;
                        }
                    }

                }

                .ticket-table-wrapper .loader-container {
                    position: absolute;
                    height: 100%;
                }
            }
        }
    }


}

.cancel-btn,
.create-btn {
    height: 36px;
    gap: 8px;
    border-radius: 8px;
    border-width: 1px;
    padding: 8px 14px;
    color: #F7F9F9;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
}

.create-btn {
    background: #00789E;
    border: 1px solid #005F7C;
}

.cancel-btn {
    background: #131F2E;
    border: 1px solid #4A5463;
}