@import '../../../styles/darktheme/utilities/variables';

.child-count {
  margin-left: auto;
  padding: 3px 8px;
  border-radius: 4px;
  background-color: $dark-tertiary;
  display: inline-block;
  text-align: center;
  line-height: 1;
  color: #ffffff;
  margin-right: 8px;
}

.navigation-bar-items .navigation-header:hover {
  background-color: $neutral-dark-shade2;
  cursor: pointer;
}

.navigation-bar-items .navigation-header:hover .child-count {
  background-color: $dark-tertiary;
  cursor: pointer;
  color: $dark-text-primary;
}

.selected-row {
  background-color: $dark-tertiary;
}

.selected-row .navigation-content-frame-item-text {
  color: $light-primary-shade5;
}

.navigation-bar-items {
  position: absolute;
  & .navigation-header {
    &.expanded-two {
        color: $light-primary-shade5;
        background: var(--Dark-mode-600, $dark-tertiary);
        border-radius: 6px;
        & .navigation-content-frame-item-carrot {
          img {
            color: $light-primary-shade5;
            background-color: $dark-tertiary;
            border: 1px solid $light-primary-shade5 !important;
          }
        }
        .navigation-content-frame-item-text {
            color: $light-primary-shade5;
        }
        .child-count {
            background-color: $light-primary-shade5 !important;
            cursor: pointer;
            color: $light-primary-shade2;
        }
    }
    padding: 8px 0;
    &:focus,
    &:hover {
      color: $dark-text-primary;
      border-radius: 6px;
      background: var(--Dark-mode-700, $dark-secondary);
      & .navigation-content-frame-item-carrot {
        img {
          background-color: $dark-tertiary;
          border: 1px solid $dark-light-shade2;
        }
      }
    }

  }
  & .navigation-child-container {
    height: auto;
    // max-height: 500px;
    // overflow: auto;
    overflow-x: hidden;
    & .navigation-content-frame-asset {
      display: flex;
      padding: 4px 12px 4px 48px;
      min-height: 40px;
      &:hover,
      &:focus {
        background: var(--Dark-mode-700, $dark-secondary) !important;
        border-radius: 6px;
        .navigation-content-frame-item-text {
          color: $dark-text-primary !important;
        }
      }
      &:active {
        color: $light-primary-shade5;
        background: var(--Dark-mode-600, $dark-tertiary);
        border-radius: 6px;
        .navigation-content-frame-item-text {
            color: $light-primary-shade5;
        }
      }
      .navigation-content-frame-item-text {
        line-height: 24px;
      }
    }
  }

  .navigation-content-frame-item-content {
    width: 100%;
  }

  .navigation-content-frame-item-carrot {
    .child-count {
      margin-right: 8px;
    }
    img {
      border: 1px solid $dark-light-shade2;
      border-radius: 4px;
      padding: 5px;
      margin-right: 12px;
      &.chevron-down {
        width: 20px;
        height: 20px;
      }
    }
    &:hover,
    &:focus {
      img {
        color: $dark-text-primary;
        background-color: $dark-tertiary;
        border: 1px solid $primary-dark-border;
      }
    }
  }
  .navigation-header.expanded.sticky.top-0.z-9999{
    background-color: #001023;
    z-index: 99999;
  }
  .data-flow-icon {
    width: 18px;
    height: 20px;
    display: flex;
  }
}
