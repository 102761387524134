@import '../../../styles/darktheme/utilities/variables';

.cancel-modal {
    &__background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    &__container {
        position: absolute;
        background-color: $dark-secondary;
        border: 1px solid $primary-dark-border;
        border-radius: 8px;
        width: 27%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__header {
        padding: 1.25rem 0px 0px 0px;
    }

    &__body {
        padding: 1rem 1rem 1.5rem;
    }

    &__separator {
        border: 1px solid $primary-dark-border;
        width: 100%;
    }

    &__title {
        font-size: 19px;
        font-weight: 600;
        padding-bottom: 5px;
        text-align: center;
        line-height: 23px;
        color: $dark-text-primary;
    }

    &__content {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $neutral-light-shade3;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
    }

    &__footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        button {
            display: flex;
            justify-content: center;
            width: 50%;
            color: $white;
        }
    }

}