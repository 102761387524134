.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
  backdrop-filter: blur(5px); /* Apply blur effect */
  z-index: 9999; /* Ensure the overlay is above other content */
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(255, 255, 255, 0.1); /* Change the border color if needed */
  border-left-color: #333;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: rgba(0, 0, 0, 0); /* Semi-transparent black background */
  backdrop-filter: blur(5px); /* Apply blur effect */
}

.loader {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(255, 255, 255, 0.1); /* Change the border color if needed */
  border-left-color: #4a5463;
  border-radius: 50%;
  animation: load 1s linear infinite;
}

@keyframes load {
  to {
    transform: rotate(360deg);
  }
}
