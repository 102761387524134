@import "/src/styles//darktheme/utilities/variables";

.build-watchlist-modal.modal-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

    .modal-content {
        border-radius: 12px;
        border: 1px solid var(--Darkmode-400, #4A5463);
        background: var(--Darkmode-700, #131F2E);
        box-shadow: 0px 223px 62px 0px rgba(0, 0, 0, 0.00), 0px 143px 57px 0px rgba(0, 0, 0, 0.02), 0px 80px 48px 0px rgba(0, 0, 0, 0.07), 0px 36px 36px 0px rgba(0, 0, 0, 0.12), 0px 9px 20px 0px rgba(0, 0, 0, 0.13);
        padding: 20px;
        width: 768px;
        // overflow: auto;
        position: relative;
        min-height: 400px;
        padding: 22px;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .modal-title {
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 34px;
            letter-spacing: -1.12px;

            color: var(--Primary-Indigo-25, var(--Primary-Indigo25, #F4FBFC));
            margin: 0;
        }

        .close-btn {
            background: none;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 25px;
            right: 15px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            transition: background-color 0.3s;

            &:hover {
                background-color: var(--Darkmode-600, #1D2A3E);
            }
        }
    }

    .modal-body {
        overflow: auto;
        max-height: 500px;
        margin-top: 48px;

        .form-group {
            margin-bottom: 20px;

            .form-label {
                color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
            }

            .form-control {
                border-radius: 8px;
                border: 1px solid var(--Darkmode-400, #4A5463);
                background-color: var(--Darkmode-800, #001023) !important;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                height: unset !important;
                margin-bottom: 24px;

                &:focus {
                    border-color: #000000 !important;

                }

                &:focus-visible {
                    border-color: #000000 !important;
                    background-color: #000000;
                }

            }

            textarea.form-control {
                margin-bottom: 8px;
            }



            .form-control:focus-visible,
            .form-control:focus {
                border-color: #000000 !important;
            }


            .max-desc-count {
                color: var(--Neutral-Grey-400, var(--Neutral-Grey400, #91A4AA));
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 24px;
            }
        }
    }

    .modal-footer {
        border-top: 1px solid var(--Darkmode-400, #4A5463);
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        padding-top: 35px;
        width: 100%;

        .CR-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 140px ; 
        }

    }
}