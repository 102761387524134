@import "../../../../styles/darktheme/utilities/_variables";


.header-notify {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.toggle-wrap {
  display: flex;
}

.toogle-heading {
  width: 35%;

  .title {
    font-size: 16px;
    font-weight: 600;
    color: $black;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: $white;
  border-radius: 8px;
  width: 600px;
  text-align: center;
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #333;
}

.time-block {
  display: flex;
  padding: 20px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid $dark-light-shade2;
}

label {
  color: $black;
}

.time-input-field {
  width: 396px;
  padding: 7px;
  border-radius: 8px;
  border: 1px solid #ddd;

  &:focus {
    border-color: #e5e7eb;
    outline: 2px solid rgb(51, 181, 232);
    box-shadow: 0 0 7px rgb(51, 181, 232);
  }
}

.form-control {
  width: 100%;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
}

.btn-notify {
  display: flex;
  justify-content: end;
  gap: 14px;
  margin-top: 20px;
}

.security-card {
  margin: 23px auto;
}

.security-card .header {
  padding: 1em;
  border-bottom: 1px solid $neutral-light-shade4;
}

.notify-card {
  width: 100%;

  .header {
    border-bottom: 1px solid $neutral-light-shade4;
    margin-bottom: 16px;
    gap: 10px;
    padding: 15px 20px 15px 20px;
  }

  .form-body {
    padding: 0 24px 15px 24px;
  }

  .selected-btn {
    border-radius: 8px;
    border: 1px solid var(--primary-indigo-700, $light-primary-shade2);
    background: var(--primary-indigo-600, $light-primary-shade3);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: $white;
  }



  .cust-drop {
    .custom-dropdown {
      border: none !important;
    }
  }

  .error-message {
    padding-top: 12px;
    padding-bottom: 0px;
  }

  .full-col {

    .rmdp-container {
      height: 35px !important;

      svg {
        color: white;
        left: 2.2px;
      }

      .rmdp-wrapper {
        background-color: var(--darkmode-700, #131f2e);
        color: white;

        .rmdp-input {
          height: 36px !important;
          padding-left: 25px !important;
          width: 100%;
        }

        .rmdp-calendar {
          padding: 20px 24px;

          .rmdp-header {
            font-weight: bold;
            height: 36px;

            .rmdp-header-values {
              font-family: Mulish;
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              text-align: center;

              color: white;

            }

            .rmdp-arrow {
              border: solid white;
              border-width: 0 2px 2px 0;
              width: 10px;
              height: 10px;
            }

            .rmdp-arrow-container {
              margin: 0px 14px;
            }

            .rmdp-arrow-container:hover {
              background-color: #0094BD;
            }
          }

          .rmdp-week-day {
            color: white;
            font-weight: bold;
            width: 40px;
            height: 40px;
            padding: 10px 8px 10px 8px;
            gap: 0px;
            border-radius: 20px 0px 0px 0px;
            //styleName: Paragraph 1 [P1]/Bold;
            font-family: Mulish;
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;
            text-align: center;


          }

          .rmdp-day {
            width: 40px;
            height: 40px;
            padding: 10px 8px 10px 8px;
            gap: 0px;
            border-radius: 20px 0px 0px 0px;
            //styleName: Paragraph 1 [P1]/Medium;
            font-family: Mulish;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: center;
            color: white;

          }

          .rmdp-deactive {
            color: gray;
          }

          .rmdp-day.rmdp-selected span:not(.highlight),
          .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
            background-color: #0094BD;
          }



        }

      }

      .rmdp-border {
        border: 1px solid #4a5463;
      }



      .rmdp-month-picker,
      .rmdp-year-picker {
        background-color: var(--darkmode-700, #131f2e) !important;
      }
    }
  }
}

p {
  color: $black;
}

.schedule {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}



.dropdown-container {
  position: relative;
  display: inline-block;
  float: right !important;
}

.dropdown-btn {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1078px;
}

.dot {
  height: 4px;
  width: 4px;
  background-color: #333;
  border-radius: 50%;
  margin: 2px 0;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9;
  width: 300px;
  border-radius: 8px;
  overflow: hidden;
}

.dropdown-options button {
  width: 100%;
  padding: 8px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  border-bottom: 1px solid #d0d8dd;

}

.dropdown-options button:hover {
  background-color: #f1f1f1;
}

.calendar-block {
  display: flex;
  align-items: center;
}

.calendar-inner {
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 12px;
  margin-right: 12px;
}

.schedule-text {
  font-size: 16px;
  font-weight: 700;
  text-wrap: nowrap;
}

.days-text {
  font-size: 14px;
  font-weight: 400;
}

.d-flex {
  display: flex;
  flex-direction: column;
}

.schedule-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dash-card {
  width: 100% !important;
  max-width: 100% !important;
}

.notification-preferences {
  display: flex;
  flex-grow: 1;
  margin-top: 1.25rem;

  & .btn-primary {
    margin-top: 12px;
  }

  & .notification-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--Dark-mode-400, $dark-light-shade2);
    background: linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.40) 35.14%);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin: 0;
    width: 100%;

    & .header {
      padding: 0.5rem 1.25rem !important;
      border-bottom: 1px solid var(--Dark-mode-400, $dark-light-shade2);
      ;
    }
  }

  & .notification-header {
    & .title {
      color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary)) !important;
      font-family: Mulish;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      border: none;
      padding-left: 0;
    }

    & .notification-text {
      color: var(--Neutral-Grey-200, var(--Neutral-Grey200, $neutral-light-shade3));
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      word-break: break-word;
    }
  }

  & .notification-no-data {
    display: flex;
    height: 64px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid var(--Dark-mode-400, $dark-light-shade2);
    background: var(--Dark-mode-800, #001023);
    margin: 1.25rem 1.5rem;

    p {
      color: var(--Dark-mode-200, #6F7887);
      font-family: Mulish;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  &.external {
    padding-bottom: 1.25rem;

    & .header {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      border-bottom: 1px solid var(--Dark-mode-400, $dark-light-shade2);
      ;
    }

    & .external-body {
      width: 100%
    }

    & .toggle-wrap {
      display: flex;
      align-items: flex-start;
      gap: 64px;
      padding: 1.25rem 1.5rem;

      & .toogle-heading {
        width: 30%;

        & .title {
          color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          border: none;
          padding-left: 0;
        }

        & .notification-text {
          color: var(--Neutral-Grey-200, var(--Neutral-Grey200, $neutral-light-shade3));
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }

      & .toggle-container {
        width: 70%;
      }
    }
  }

  & .scheduled-body {
    border-radius: 8px;
    border: 1px solid var(--Dark-mode-400, $dark-light-shade2);
    background: var(--Dark-mode-800, #001023);
    width: 100%;
  }

  &__profile-block {
    width: 100%;

    &:nth-child(1) {
      padding: 20px;
    }

    padding: 0 20px 20px;

    & .dropdown-btn {
      padding: 4px;
      width: 20px;
    }

    & .dropdown-active {
      border-radius: 8px;
      background: var(--Dark-mode-400, #4A5463);
      filter: none;

      & .dot {
        background-color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
      }
    }

    & .dropdown-header {
      padding: 12px 16px;
      border-bottom: 1px solid var(--Dark-mode-400, $dark-light-shade2);
      color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
      font-family: Mulish;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }

    & .dropdown-options {
      button {
        border: none;
        padding: 10px;

        &.last-btn {
          border-top: 1px solid var(--Dark-mode-400, $dark-light-shade2);

          &:hover,
          &:focus {
            border-radius: 6px;
            background: var(--Dark-mode-600, #253040);
            border: none;
          }
        }

        &:hover,
        &:focus,
        &.snooze-active {
          border-radius: 6px;
          background: var(--Dark-mode-600, #253040);
        }
      }

      & .chevron-down {
        margin-left: 162px;
      }

      & .chevron-active {
        margin-left: 162px;
        transform: rotate(180deg);
      }

      & .snooze-options {
        margin: 2px 2px 4px 2px;
        border-radius: 8px;
        background: var(--Darkmode-700, #131F2E);
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      }
    }
  }

  & .dash-card {
    margin-bottom: 1.2em;
    overflow: visible;
  }

  & .dropdown-options {
    button {
      display: flex;
      gap: 12px;
    }
  }
}

.schedule-modal {
  border-radius: 8px;
  width: 600px;
  text-align: center;
  position: relative;

  @media #{$largeDesktop},
  #{$xxlargeDesktop} {
    width: 877px;

  }

  &__container {
    display: flex;
    flex-grow: 1;
    width: 100%;
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid var(--Dark-mode-400, $dark-light-shade2);
    background: var(--Darkmode-700, $dark-secondary);
    box-shadow: 0px 223px 62px 0px rgba(0, 0, 0, 0.00), 0px 143px 57px 0px rgba(0, 0, 0, 0.02), 0px 80px 48px 0px rgba(0, 0, 0, 0.07), 0px 36px 36px 0px rgba(0, 0, 0, 0.12), 0px 9px 20px 0px rgba(0, 0, 0, 0.13);
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    padding: 20px 24px;
    border-bottom: 1px solid var(--Dark-mode-400, $dark-light-shade2);
  }

  &__title {
    color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary)) !important;
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  &__text {
    color: var(--Neutral-Grey-200, var(--Neutral-Grey200, $neutral-light-shade3)) !important;
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  &__main-container {
    width: 100%;
  }

  &__time-block {
    margin: 0 24px 20px;
    border-radius: 8px;
    border: 1px solid var(--Dark-mode-400, $dark-light-shade2);
    background: var(--Darkmode-700, $dark-secondary);
    box-shadow: 0px 223px 62px 0px rgba(0, 0, 0, 0.00), 0px 143px 57px 0px rgba(0, 0, 0, 0.02), 0px 80px 48px 0px rgba(0, 0, 0, 0.07), 0px 36px 36px 0px rgba(0, 0, 0, 0.12), 0px 9px 20px 0px rgba(0, 0, 0, 0.13);
  }

  &__form-control {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 64px;
    width: 100%;
    padding: 1.25rem;
  }

  &__label {
    display: flex;
    justify-content: flex-start;
    width: 40%;
    color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  &__time-input-field {
    display: flex;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    width: 60%;
    border-radius: 8px;
    border: 1px solid var(--Dark-mode-400, $dark-light-shade2) !important;
    background: var(--Dark-mode-800, #001023) !important;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary)) !important;
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  &__btn-container {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
  }

  &__repeat-btn {
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--Neutral-Grey-200, $neutral-light-shade3);
    background: var(--Dark-mode-700, $dark-secondary);
    color: var(--Primary-Indigo-400, #60BFDA);
    font-family: Mulish;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;

    &:hover,
    &:focus {
      background: var(--primary-indigo-600, $light-primary-shade3);
      color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
    }
  }

  .selected-btn {
    border-radius: 8px;
    border: 1px solid var(--primary-indigo-700, $light-primary-shade2);
    background: var(--primary-indigo-600, $light-primary-shade3);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--Neutral-Grey-25, var(--Neutral-Grey25, $dark-text-primary));
    padding: 10px 16px;
    margin: 0;
  }

  &__error-message {
    color: $error-dark-shade5;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 8px 24px;
  }

  & .btn-notify {
    margin: 20px 24px;
  }

  /* Styles for WebKit browsers (Chrome, Safari) */
  input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }

  /* Styles for Firefox */
  input[type="time"]::-moz-time-picker-indicator {
    filter: invert(1);
  }
}