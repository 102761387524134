.outer-container {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  /* padding-left: 68px; */
  height: 100%;
  width: 100%;
  display: flex;
  /* min-height: calc(100vh - 0px);; */
  min-height: 100vh ;
}
 
 
.switch {
  margin-left: 100px;
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  margin-bottom: 12px;
}
 
.switch input {
  opacity: 0;
    width: 0;
    height: 0;
}
 
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: background-color 0.3s;
  border-radius: 15px;
}
 
.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 1px;
  bottom: 2px;
  background-color: white;
  transition: transform 0.3s;
  border-radius: 50%;
}
 
input:checked + .slider {
  background-color: #4caf50;
}
 
input:focus + .slider {
  box-shadow: 0 0 1px #4caf50;
}
 
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
 
/* Style the h2 label text */
h2 {
  margin-left: 50px;
  margin-top: -20px;
  font-size: 14px;
  font-weight: 700;
  color: black;
}
 
.theme-toggle{
  position: absolute;
  width: auto;
  padding: 0;
  z-index: 999;
  margin: 0;
  right: 30px;
  top: 20px;
}

.assetreduxpage{
  position: relative;
  padding-left: 10px;
  padding-top: 12px;
}