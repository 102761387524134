.notification-wrapper {
  .comment-box {
    position: relative;
    height: 127px;
    padding: 8px;
    width: 100%;
    border: 1px solid #d0d8dd;
    border-radius: 4px;
    margin-bottom: 8px;
    span {
      margin-left: 10px;
      font-size: 14px;
      color: #4b5e68;
    }
    a {
      color: #00789e;
    }
    h5,
    h4 {
      margin: 0px;
    }
    p {
      padding: 4px 8px;
      border-radius: 2px;
      border: 1px solid #d0d8dd;
      height: 48px;
      font-size: 12px;
      background-color: #f7f9f9;
    }
    .notification-circle {
      position: absolute;
      right: 8px;
    }
  }
  .notify-buttons {
    margin-top: 8px;
    a {
      margin-left: 0px;
      margin-right: 8px;
      padding: 4px 8px;
      border-radius: 4px;
    }
  }
  .notify-buttons.red {
    a {
      border: 1px solid #f04438;
      color: #f04438;
      background: #fef3f2;
    }
  }
  .notify-buttons.blue {
    a {
      border: 1px solid #0094bd;
      color: #0094bd;
      background: #f4fcff;
    }
  }
  .notitification-with-buttons {
    height: 80px;
    p {
      background: transparent;
      border: 0;
      padding: 0;
      margin: 6px 0px;
    }
  }
}
