.health-score-container {
    // min-width: calc(100% - 68px);
    // padding: 32px 16px;
    padding: 0px;
    gap: 16px;
    height: 100%;
    flex-wrap: unset;

    .header1 {
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
        text-align: left;
        color: #F7F9F9;
    }

    .health-score-table {
        .table-container {
            max-height: calc(100vh - 380px);
        }
    }

    .health-score-chart-container {
        height: 302px;
        padding: 25px 0px;
        padding-right: 20px;

        .health-score-chart {
            width: 300px;

            .health-score-chart-wrapper {
                height: 100%;
                width: 100%;
            }
        }

        .progress-bar-container {
            flex: 1;

            .progress-div {
                height: 68px;
                display: flex;
                flex-direction: column;
                gap: 18px;
                padding: 16px;
                padding-left: 0px;
            }

        }

        .info-button {
            .button {
                width: 36px;
                height: 36px;
                margin-top: -10px;
            }

            .button:focus {
                background-color: var(--darkmode-700, #131f2e);
                border-radius: 4px;
                opacity: 0.5;
            }

            .tooltip-tip {
                text-wrap: wrap;
                width: 446px;
                padding: 8px 12px 8px 12px;
                background: #001023;
                font-size: 12px;
                font-weight: 600;
                line-height: 18px;
                text-align: left;
                color: #F7F9F9;
                transform: translateX(-90%);
                bottom: calc(30px * -1.8)
            }

            .tooltip-tip.bottom::before {
                border-bottom-color: #001023;
                left: 93%;
            }
        }
    }

    .health-score-table {
        flex: 1;
        padding: 25px;
        width: 100%;
        height: 100%;

        .heading {
            margin-bottom: 25px;
        }

        .health-score-heading {
            background: none !important;
        }

        .health-score-date-range {
            top: 8px;
            right: 20px;
        }

        .health-score-round-div {
            width: 40px;
            height: 40px;
            padding: 8px 0px 8px 0px;
            border-radius: 200px;
            border: 2px solid var(--Success-Green500, #12B76A);
            background: var(--Darkmode-700, #131F2E);
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            text-align: center;
            color: #F7F9F9;
        }

        .health-score-impact-status {
            // height: 24px;
            padding: 2px 10px 2px 8px;
            border-radius: 4px;

            &.up {
                border: 2px solid #32D583;
                color: #32D583;
            }

            &.down {
                border: 2px solid #F97066;
                color: #F97066;
            }

            &.none {
                border: 2px solid #B8C5CC;
                color: #B8C5CC;
            }
        }

    }
}