@import '../../../../styles/darktheme/utilities/_variables';

.overlay-section {
  .table-header-section {
    justify-content: flex-start;

    &>img {
      margin-right: 1rem;
    }
  }
}

.sticky-table-header {
  // position: sticky;
  // top: 0;
  background-color: $dark-background;
}

.grow {
  display: flex;
  align-items: center;
}

.table-header-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px 0 16px;

  .title {
    color: var(--Primary-Indigo-25, var(--Primary-Indigo25, #F4FBFC));
    font-family: Mulish;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    padding: 0 0 0 5px;
    border: none;
  }
}

.ticket-details-card {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--Dark-mode-300, #5D6675);
  background: linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 332px;
  height: 367px !important;
  & .no-data {
    margin-top: 0;
  }
  & .separator {
    border-top: 1px solid var(--Dark-mode-400, #4A5463);
    margin: 0 16px;
  }
}

.table-container-dashboard {
  background-color: $dark-background;
  padding: 0 16px;
  border-bottom: 1px solid var(--Dark-mode-400, #4A5463);

  &.v-scroll {
    max-height: 260px;
    overflow-y: scroll;
    border-bottom: 1px solid $dark-light-shade2;
  }

  .table-data {
    background-color: $dark-background;
    display: table;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;

    thead {
      tr {
        th {
          pointer-events: visible;
          cursor: pointer;
        }
      }
    }

    & .table-header-cell {
      display: flex;
      width: 100%;
      gap: 8px;
      & .table-header-item {
        white-space: nowrap;
      }
    }

    tbody {
      display: block;
      max-height: 215px;
      overflow-y: auto;
      overflow-x: hidden;
      height: auto;
      min-height: 215px;

      tr {
        display: flex;
        width: 100%;
        gap: 8px;
        height: auto;
        max-height: 60px;
        max-width: unset;
      }

      td {
        padding-right: 0;
      }
    }

    & .well-name {
      width: 30%;
      font-weight: 700;
    }

    & .subject {
      width: 15%;
    }
    & .assigned {
      width: 25%;
    }

    & .ticket {
      width: 15%;
    }

    & .operator {
      width: 15%;
    }

    & .status {
      width: 15%;
    }
  }

  .filter-ticket {
    .table-header-item {

      &.well-name,
      &.subject {
        width: 30%;
      }

      &.ticket {
        width: 20%;
      }

      &.status {
        width: 20%;
      }
    }
  }

  .table-header-cell {
    background: transparent;
    border-top: 1px solid var(--neutral-grey-100, #d0d8dd);

    border-bottom: 1px solid var(--neutral-grey-100, #d0d8dd);

    th{
      display: flex;
    }

    .sort-img-show-hover{
      display: none;
    }

    th:hover .sort-img-show-hover{
      display: block;
    }

  }

  .TableHeadCell-Content-Labels {
    display: flex;
    align-items: center;
  }

  .view-all {
    display: flex;
    // margin-top: 12px;
    // margin-bottom: 12px;

    p {
      color: $light-primary-shade5;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.view-all {
  padding: 1rem !important;
}

.w-100 {
  width: 100%;

  th,
  td {
    word-break: break-word;
  }
}

.header-icon {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-right: 0.75rem;

  .img-border {
    width: 20px;
    height: 20px;
    margin-right: 0.75rem;
  }
}

.img-border {
  filter: contrast(0.1);
}

.ticket-count {
  // margin-top: 5px;
  padding-left: 16px;
  height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

table {
  thead {
    tr {
      th {
        pointer-events: visible;
        cursor: pointer;
      }
    }
  }

  .pointer {
    cursor: pointer;
  }

  // tbody {
  //   tr {
  //     td {
  //       pointer-events: none;
  //     }
  //   }
  // }
}

.ticket-details-modal.ticket-details-style {
  & .well-name {
    width: 30%;
    font-weight: 700;
  }

  & .operator {
    width: 15% !important;
  }

  & .subject {
    width: 25%;
  }

  & .assigned {
    width: 25%;
  }
  & .status,
  & .ticket {
    width: 15%;
  }
}

#subject {
  position: relative;
  &[data-title]:hover::after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
    height: auto;
    min-height: 44px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  } 
  
  &[data-title]::after {
    background-color: #001023;
    color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
    content: attr(data-title);
    border-radius: 8px;
    padding: 8px;
    position: absolute;
    z-index: 9;
    top: 14px;
    right: 0;
    opacity: 0;
    visibility: hidden;
    white-space: normal;
    word-wrap: break-word;
    word-break: break-all;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    margin-right: 20px;    
    min-width: 100px;
    width:  max-content;
    max-width: 320px;
  }
  
  &[data-title] {
    position: relative;
  }
}

.new-ticket-details {
  overflow: visible !important;
  table {
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
    & .table-header-cell {
      display: flex;
      width: 100%;
      gap: 0;
    }

    tbody {
      height: auto;
      min-height: auto;
      max-height: 182px;
      overflow-y: auto;
      display: block;
      tr {
        display: flex;
        width: 100%;
        gap: 8px;
        height: auto;
        max-height: 60px;
        max-width: unset;
      }
    }
  }
  & .well-name, & .subject {
    width: 30%;
  }

  & .ticket, & .status {
    width: 20%;
  }
}