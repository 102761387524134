.search-comment-sort-container {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    margin: 25px 0;
    .search-container {
      justify-content: left;
  
      .block {
        margin-right: 0;
      }
    }
  
    .comment-sort-container {
      display: flex;
      // align-items: center;
      gap: 20px;
      justify-content: right;

      
    .comment-container {
        position: relative;
        z-index: 8;
  
        & #comment-btn {
          width: 160px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          height: 44px;
          margin: 0px;
          padding: 10px 16px;
          border-radius: 8px;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          cursor: pointer;
          color: #b8c5cc;
        }
      }
  
      .sort-container {
        position: relative;
        z-index: 8;
  
        & #sort-btn {
          width: 130px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          height: 44px;
          margin: 0px;
          padding: 10px 16px;
          border-radius: 8px;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          cursor: pointer;
          color: #b8c5cc;
        }
      }
  
      .comments-sort-modal-container {
        display: flex;
        width: 220px;
        flex-direction: column;
        align-items: flex-start;
        gap: 1px;
        border-radius: 8px;
        border: 1px solid var(--Dark-mode-400, #4a5463);
        background: var(--Darkmode-800, #001023);
        box-shadow:
          0px 4px 8px -2px rgba(16, 24, 40, 0.1),
          0px 2px 4px -2px rgba(16, 24, 40, 0.06);
        position: absolute;
        z-index: 999;
        top: 46px;
        right: 0px;
  
        .comments-sort-modal-header {
          padding: 12px 16px;
          color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #f7f9f9));
          /* Paragraph 1 [P1]/Semibold */
          font-family: Mulish;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
        }
  
        .comments-sort-modal-body {
          width: 100%;
          border-top: 1px solid var(--Dark-mode-400, #4a5463);
          border-bottom: 1px solid var(--Dark-mode-400, #4a5463);
          padding: 5px 10px;
          .sort-option-list {
            color: #fff; 
            line-height: 40px; 
            font-size: 16px; 
            cursor: pointer;
          }
        }
      }
    }
  }

  .new-comment-box {
    border-top: 1px solid #4a5463;
    border-bottom: 1px solid #4a5463;
    background-color: transparent;
    padding: 1rem;
    border-radius: 0rem;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  
    .comment-title {
      font-size: 20px;
      color: #F7F9F9;
      margin-bottom: 20px;
      margin-top: 5px;
    }
  
    .subject,
    .description {
      width: 100%;
      padding:10px 12px;
      margin-bottom: 0.8rem;
      border: 1px solid #4a5463;
      border-radius: 0.5rem;
      background-color: #131F2E;
      color: #b8c5cc;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      font-size: 16px;
    }

    .invalid {
      border: 1px solid #f87171;
    }

    .subject{
      height: 43px;
    }

    .description{
      height: 125px;
    }
  
    .footer-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .attachment {
        display: flex;
        align-items: center;
        padding: 0.5rem;
        color: #fff;
        background-color: transparent;
        border-radius: 0.5rem;
      }
        .attachment-container {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: 10px;
  
      .attachment-preview {
        max-width: 100%;
        .attachment-name {
          font-size: 14px;
          color: #00789e;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
          max-width: 100%;
          display: inline-block;
          white-space: normal;
        }
      }
    }
  
      .action-buttons {
        display: flex;
        gap: 0.5rem;
  
        .cancel {
          padding: 8px 14px;
          border: 1px solid #4A5463;
          color: #f7f9f9;
          background-color: transparent;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          border-radius: 0.5rem;
          font-size: 14px;
        }
  
        .submit {
          padding: 8px 14px;
          border: 1px solid #00789e;
          background: #005f7c;
          color: #f7f9f9;
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          border-radius: 0.5rem;
          font-size: 14px;
        }
      }
    }
  }

  .comment-attachment-link {
    margin-bottom: 10px;
    button, a {
      font-size: 14px;
      color: #00789e;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .comments-list-container {
    position: relative;
    .loader-container {
      // top: 20%;
      position: absolute;
      width: 100%;
      margin-top: 800px;
    }
    .no-data-found {
      // top: 20%;
      position: absolute;
      width: 100%;
      margin-top: 250px;
    }
  }

  .reply-comments {
    input {
      padding: 0.75rem 0.5rem;
      width: 100%;
      border-radius: 8px;
      color: #f7f9f9;
      padding-left: 1rem;
      margin-bottom: 1rem;
  }

  textarea {
    width: 100%;
    padding: 10px 12px;
    margin-bottom: 0.8rem;
    border: 1px solid #4a5463;
    border-radius: 0.5rem;
    background-color: #131F2E;
    color: #b8c5cc;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    font-size: 16px;
}

  .action-buttons {
    display: flex;
    justify-content: end;
    gap: 0.5rem;
  }

  .cancel {
    padding: 8px 14px;
    border: 1px solid #4A5463;
    color: #f7f9f9;
    background-color: transparent;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
    font-size: 14px;
  }

  .submit {
    padding: 8px 14px;
    border: 1px solid #00789e;
    background: #005f7c;
    color: #f7f9f9;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
    font-size: 14px;
  }
  }
  
