@import '../../../../styles/darktheme/utilities/variables';

.asset-document-details {
    padding-bottom: 0;
    width: calc(100% - 68px);
    margin-left: 68px;
    .asset-control-room-header + .ui-button-toolbar {
        background-color: #202c3a!important;
        margin: 25px 32px 0 32px;
        border: 1px solid #4a5463!important;
        border-radius: 7px!important;
        // background: transparent;
        // padding: 1rem 2.5rem 0 3.5rem;
    }
    .ui-button-toolbar {
        background-color: #202c3a!important;
    }
    .asset-documents {
        background-color: $dark-primary;
        .profile-card {
            .header {
                padding: 0.5rem 1.5rem;
                border: none;
                & .asset-documnet-loader {
                    position: static;
                    width: 35px;
                    height: 35px;
                    & .loader {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .user-details-body {
                padding: 0px 1.5rem 1.5rem;
                .adminuser-block {
                    overflow: hidden;
                }
            }
        }
        .divider {
            margin: 0 -1.5rem;
        }
        .title {
            border: none;
            padding: 2rem 0px 1.25rem;
        }
        .flex {
            display: flex;
            justify-content: center;
            align-items: center;
            &.doc-title{
                justify-content: space-between;
            }
        }
        .justify-end {
            display: flex;
            justify-content: flex-end;
        }
        .custom-dropdown {
            border-radius: 8px;
            border: 1px solid var(--Dark-mode-400, #4A5463);
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            & .selected-option {
                display: flex;
                justify-content: space-evenly;
            }
            & .category-checkboxes {
                width: 288px;
                border-radius: 8px;
                border: 1px solid var(--Dark-mode-400, #4A5463);
                background: var(--Darkmode-800, #001023);
                box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
                height: auto;
                & .category-option {
                    padding: 10px 16px;
                    &:nth-child(8) {
                        border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
                    }
                }
                div.flex-row {
                    display: flex;
                    justify-content: flex-end;
                }
            }
            & .category-header {
                color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
                font-family: Mulish;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                padding: 12px 16px;
                border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
            }
        }
        .selected-option {
            border: none;
        }
        .relative.block {
            margin-right: 0.75rem;
        }
        .upload-containter {
            width: 100%;
        }
        .category-checkboxes {
            .checkbox-checkmark {
                position: absolute !important;
                left: 0 !important;
            }
            input {
                position: absolute !important;
                left: 0 !important;
            }
        }
        .filename-container, .category-checkboxes {
            .checkbox-container {
                display: block;
                position: relative;
                cursor: pointer;
                font-size: 16px;
                &:hover, &:focus {
                    background-color: var(--Dark-mode-800, $dark-primary);;
                }
                input {
                    position: absolute;
                    left: -8px;
                    opacity: 0;
                    cursor: pointer;
                    width: 20px;
                    height: 20px;
                }
                .checkbox-checkmark {
                    &::after {
                        left: 7px;
                        top: 3px;
                        width: 5px;
                        height: 10px;
                        border: solid white;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }
                }
            }
            .checkbox-checkmark {
                position: absolute;
                top: 0;
                left: -8px;
                height: 20px;
                width: 20px;
                border-radius: 4px;
                border: 1px solid var(--Dark-mode-300, $dark-light-shade3);
                background: var(--Dark-mode-800, $dark-primary);
                &::after {
                    content: "";
                    position: absolute;
                    display: none;
                    border: none;
                }
            }
        }

        & .custom-text-input {
            &:focus-visible, &:focus, &:active {
                outline: 2px solid #33b5e8;
                box-shadow: 0 0 7px #33b5e8;
            }
        }

        .document-table-filter-container{
            display: flex;
            align-items: center;
        }

        .selected-filters-text{
            font-size: 15px;
            font-weight: 500;
            text-wrap: nowrap;
        }

        .selected-category-label{
            margin-left: 20px;
        }
        .selected-category-text{
            margin-left: 10px;
        }

        .selected-category-document-parent{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 16px;
            margin-left: 25px;
            width: 100%;
            
        }
        
        .selected-category-document{
            padding: 6px;
            border: 1px solid #91A4AA;
            display: flex;
            align-items: center;
            border-radius: 4px;
            background-color: #253040;
        }

        .selected-category-document button{
            margin-left: 3px;
        }

        .selected-category-document .filter-test-category{
            color: #B8C5CC;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
        }
        
    }
}

.checkbox-container input:checked~.checkbox-checkmark {
    border-radius: 4px !important;
    border: 1px solid var(--Primary-Indigo-700, $light-primary-shade2) !important;
    background: var(--Primary-Indigo-600, $light-primary-shade3) !important;
}

.checkbox-container input:checked~.checkbox-checkmark::after {
    display: block !important;
}

.asset-tab-container {
    border: none !important;
}

.asset-documnet-loader {
    top: 50%;
    position: absolute;
    margin-left: -50px;
}