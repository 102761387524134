@import "../../styles/global.scss";


$tooltip-text-color: white;
$tooltip-background-color: #001027;


.onboarding-tooltip-wrapper {
  display: inline-block;
  position: relative;

  button.ui-icon-button-toolbar-button {
    svg {
      max-height: 18px;
    }

    svg path {
      stroke: #fff;
    }

    svg circle,
    svg ellipse {
      fill: #fff;
    }
  }

  button.ui-icon-button-toolbar-button.ui-icon-button-toolbar-button-active {
    svg path {
      stroke: #60bfda;
    }

    svg circle,
    svg ellipse {
      fill: #60bfda;
    }
  }

  .onboarding-tooltip-tip {
    @include captionBold;

    position: absolute;
    border-radius: 4px;
    left: 10%;
    transform: translateX(-50%);
    padding: 8px 12px;
    color: $tooltip-text-color;
    background: $tooltip-background-color;
    z-index: 100;
    white-space: nowrap;
  }

  .onboarding-tooltip-tip::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: 6px;
    margin-left: calc(6px * -1);
  }

  .onboarding-tooltip-tip.top {
    top: calc(30px * -1.5);
    left: calc(10% + 10px);
  }

  .onboarding-tooltip-tip.top::before {
    top: 100%;
    border-top-color: $tooltip-background-color;
  }

  .onboarding-tooltip-tip.right {
    left: 145%;
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  .onboarding-tooltip-tip.right::before {
    left: calc(6px * -0.9);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: $tooltip-background-color;
  }

  .onboarding-tooltip-tip.bottom {
    bottom: calc(30px * -1);
  }

  .onboarding-tooltip-tip.bottom::before {
    bottom: 100%;
    border-bottom-color: $tooltip-background-color;
  }

  .onboarding-tooltip-tip.left {
    left: auto;
    right: calc(50% + 30px);
    top: 50%;
    transform: translateX(0) translateY(-50%);
  }

  .onboarding-tooltip-tip.left::before {
    left: auto;
    right: calc(6px * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: $tooltip-background-color;
  }
}