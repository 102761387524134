@import '../../styles/darktheme/utilities/variables';

.layout {
  color: $white;
  background-color: $dark-primary;
  border-radius: 0 0 8px 8px;
}

.grid-item {
  color: $white;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100px;
  width: 100px;
  border-radius: 8px;
  padding: $padding-md;
  background: $white;
  border: 1px solid $neutral-light-shade4;
}

.gap {
  grid-gap: 0.75rem;
}

.grid-item-text {
  color: $white;
}

.profile-status-header {
  background-color: $white;
  width: $full-unit;
}

.header-status-frame {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}

.header-status-frame .text-para {
  font-size: 14px;
  font-weight: 400;
  color: $black;
}

.block-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}

.dashboard-count {
  display: flex;

  &.ui {
    h1.text-para-dash {
      font-size: 32px;
      font-weight: 700;
      line-height: 80px;
      letter-spacing: normal;
      margin-bottom: 0;
    }

    .active-button {
      background-color: $dark-primary;
      color: $white;
      border-radius: 0.5rem;
      cursor: pointer;
    }
  }

  h1 {
    font-size: $font-size * 2;
    line-height: normal;
  }
  .ticket-container {
    & img{
      width: 24px;
      height: 24px;
      margin-left: 7px;
    }
  }
  p {
    font-size: $font-size;
    display: flex;
    font-size: 18px;
    font-weight: 700;
    align-items: center;

    &.text-para-dash.link {
      padding: 0;
      width: auto;
      pointer-events: visible;

      &:hover,
      &:focus,
      &:active {
        background-color: $dark-primary;
        color: $white;
      }

      img {
        width: 12px;
        height: 6px;
      }
    }
  }

  .margin-right {
    margin-right: $margin-md * 3;
    text-align: center;
  }
}

.dashboard-first-block {
  .top-header {
    background-color: $white;
    padding: 1.5rem;
    position: fixed;
    width: calc(100% - 4.25rem);
    top: 0;
    z-index: 99;


    .page-title {
      color: #131F2E;
      font-size: 1.6rem;
      display: inline-block;
      &:hover {
        cursor: pointer;
      }

      a {
        color: $light-primary-shade5;
      }
    }

    .select-name {
      display: inline-block;
    }

    .flex.justify-end {
      cursor: pointer;
      // margin-right: 4.5rem;
      padding-right: 1rem;

      //border-right: 1px solid $neutral-light-shade3;
      .text-timestamp {
        padding-right: 1rem;
      }
    }
  }

  .dash-content {
    display: flex;
    padding: 0.5rem 1rem;
    align-items: center;

    .img-pro {
      border-radius: 100px;
      border: 5px solid var(--primary-indigo-400, $light-primary-shade5);
      overflow: hidden;
      margin-right: 12px;
      width: 120px;
      height: 120px;
      border-radius: 120px;
      img {
        // width: 65px;
      }
    }
  }

  .para-dashboard p {
    font-size: $font-size;
    font-weight: 400;
  }
}

.para-dashboard h1 {
  font-size: $font-size * 2;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: normal;
}

.card {
  // margin: 1.2em 1.2em 0 1.2em;
  background: $white;
  border: 1px solid $neutral-light-shade4;
  border-radius: 8px;
}

.flex-table-card {
  display: flex;
  width: $full-unit;
}

.flex-table-card .w-50 {
  width: 50%;
}

.flex-table-card .table-header-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px 0 24px;
}

.flex-table-card .table-header-section .title {
  font-weight: 600;
  font-size: 1.2em;
  color: #458CA2;

}

.flex-table-card .table-container-dashboard {
  padding: 12px 24px 24px 24px;
}

.flex-table-card .table-container-dashboard .table-header-cell {
  background: transparent;
  border-top: 1px solid var(--neutral-grey-100, $neutral-light-shade4);
}

.flex-table-card .table-container-dashboard .TableHeadCell-Content-Labels {
  display: flex;
  align-items: center;
}

.flex-table-card .table-container-dashboard .view-all {
  color: #60bfda;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 700;
}

.text-para-dash {
  color: #000;
}

.card-performance.dashboard-card-first .text-para-dash{
  font-size: 18px;
}

.height-full {
  height: 145vh;
}

.performanceSnapshot {
  width: 100%;
  border-radius: 8px;
  background: $white;
  border: 1px solid $neutral-light-shade4;
  // overflow: hidden;

  .block-wrap {
    padding: 1rem 0;
  }
}


.pie-header-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table-header-section {
  .title {
    font-weight: 600;
    font-size: 18px;
    color: #458CA2;
  }
}

.pie-section {
  padding: 0px 0px 0px 8px;
}
.pie-container-dashboard {
  display: flex;
  align-items: center;
  padding:10px 12px 0px 12px;
}
hr {
  color: var(--dark-mode-400, $dark-light-shade2);
  opacity: 0.4;
}
.total-count {
    text-align: center;
  & p {
    margin-bottom: 24px;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-wrap: nowrap;
  }
}
.total-count {
  & h2 {
    margin: 0;
    color: #000;
    font-size: 50px;
    font-weight: 700;
  }
}
.chart-wrapper {
  width: $full-unit;
  height: 220px
}


@media #{$largeDesktop} {
  .para-dashboard h1 {
    font-weight: 700;
    line-height: 60px;
    letter-spacing: normal;
  }
  .img-pro img {
    width: 120px;
  }

  .performanceSnapshot {
    .tab-content {
      width: 100%;
    }
  }
}

.grid-container {
  background-color: var(--Darkmode-800, #001023);
  border-radius: 0 0 8px 8px;
}

// Performance Snapshot modal css:
.performance-snapshot-modal-table-wrap {
  height: auto !important;
  // max-height: 220px !important;
  overflow-x: overlay;
  table{
    border-collapse: collapse;
    &.shutdown-max-wrap {
      width: max-content;
      & .operator {
        width: 180px;
      }
  
      & .other-column {
        width: 145px;
        justify-content: center;
      }
    }
    &.shutdown-normal-wrap {
      width: 100%;
      display: table;
      table-layout: fixed;
      & .operator {
        width: 25%;
      }
  
      & .other-column {
        width: 15%;
      }
      thead {
        tr {
          display: flex;
          gap: 8px;
          th {
            &:not(:first-child) {
              display: flex;
              align-items: center;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:not(:first-child) {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    @media #{$mediumtolargeDesktop} {
      width: 100%;
    }
    th, td {
      padding: 8px;
      text-align: center;
      color: var(--Neutral-Grey-25, #f7f9f9);
      text-wrap: nowrap;
    }
    th:first-child {
      text-align: left;
    }
    td:first-child {
      text-align: left;
      color: #60BFDA;
    }
    thead {
      tr {
        display: flex;
        width: 100%;
        gap: 8px;
        border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
      }
    }
    tbody {
      display: block;
      max-height: 180px;
      height: auto;
      overflow-x: overlay;
      tr {
        display: flex;
        width: 100%;
        gap: 8px;
        border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
        td {
          &:not(:first-child) {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}

.widget-modal {
  table {
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    thead {
      tr {
        display: flex;
        width: 100%;
        gap: 8px;
        border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
      }
    }
    tbody {
      height: auto;
      overflow-y: auto;
      display: block;
      @media #{$xxlargeDesktop}, #{$largeDesktop} {
        max-height: 200px;
      }
      @media #{$tabletMiniDesktop} {
        max-height: 170px;
      }
      @media #{$miniDesktop}, #{$mediumDesktop} {
        max-height: 110px;
      }
      tr {
        display: flex;
        width: 100%;
        gap: 8px;
        border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
      }
    }
    & .well-by-status, & .well-name {
      width: 30%;
    }
    & .running, & .disabled, & .shutdown, & .oil, & .gas, & .water, & .comm-issues, & .offline {
      width: 14%;
      justify-content: center;
    }
    & .uptime, & .downtime {
      width: 30%;
      justify-content: center;
    }
    th, td {
      padding: 8px;
      text-align: center;
      color: var(--Neutral-Grey-25, #f7f9f9);
      text-wrap: nowrap;
    }
    th:first-child {
      text-align: left;
    }
    td:first-child {
      text-align: left;
      color: #60BFDA;
    }
  }
}
.btn-disabled {
  opacity: 0.6; 
  cursor: not-allowed; 
  pointer-events: none; 
}

.well-by-status-table, .uptime-downtime-table, .shutdown-by-reason-table {
  .table-header-flex {
    th {
      display: flex;
    }
  }
}

.well-by-status-table .table-header-flex th .sort-img-show-hover {
  visibility: hidden;
}

.well-by-status-table .table-header-flex th:hover .sort-img-show-hover {
  visibility: visible;
}

.uptime-downtime-table .table-header-flex th .sort-img-show-hover {
  visibility: hidden;
}

.uptime-downtime-table .table-header-flex th:hover .sort-img-show-hover {
  visibility: visible;
}

.shutdown-by-reason-table .table-header-flex th .sort-img-show-hover {
  visibility: hidden;
}

.shutdown-by-reason-table .table-header-flex th:hover .sort-img-show-hover {
  visibility: visible;
}