@import '../../../../styles/darktheme/utilities/variables';

.asset-analysis-title {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #F7F9F9;
}

.left-section {
    width: 35%;
    min-height: 774px;
    padding: 0px 0px 69px 0px;
    gap: 10px;
    border-radius: 8px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    .wrapper-location.asset-details {
        width: 100%;
    }
    &.cust-ui {
        .ui-button-toolbar.tab-header {
            padding-left: 0;
        }
    }

    .ui-button-toolbar.tab-header {
        border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
        width: 400px;
        @media #{$largeDesktop}, #{$xxlargeDesktop}, #{$mediumtolargeDesktop} {
            width: 448px;
        }
    }
}

.right-section {
    width: 65%;
    display: flex;
    flex-direction: column;
    & .chart-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    & .select-buttons {
        display: flex;
        height: 44px;
        align-items: flex-start;
        border-radius: 8px;
        border: 1px solid var(--Dark-mode-400, #4A5463);
        background: var(--Dark-mode-700, #131F2E);
        width: 150px;
        overflow: hidden;
        button {
            &:nth-child(1) {
                border-right: 1px solid var(--Dark-mode-300, #5D6675);
                color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
                font-family: Mulish;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                padding: 10px 16px;
                border-radius: 0;
            }
            &:nth-child(2) {
                padding: 10px 16px;
                color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
                font-family: Mulish;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px; 
            }
            &.active, &:hover, &:focus {
                color: var(--Primary-Indigo-400, #60BFDA);
            }
        }
    }
    .capacity {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 100%;
        max-width: 100%;
        position: relative;
    }

    .ipr {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 100%;
        max-width: 100%;
        position: relative;
    }
}

@media #{$mediumtolargeDesktop}, #{$largeDesktop}, #{$xxlargeDesktop} {
    .left-section {
        width: 30%;
    }
    .right-section {
        width: 70%;
        .capacity {
            max-width: 100%;
        }
    }
}

.capacity-curve {
    color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    padding-left: 2rem;
    position: absolute;
    top: -36px;
}

.depletion-curve {
    color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    padding-left: 2rem;
}

.capacity-graph .no-data {
    top: 50%;
    margin-top: 300px;
    position: absolute;
}

.ipr-graph .no-data {
    top: 50%;
    margin-top: 300px;
    position: absolute;
}

.capacity-graph-title {
    color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    padding-left: 4rem;   
}