.all-wells-table-body {
    padding: 0.125rem 1.5rem 25px !important;
}

.all-wells-table-body .loader-container{
    position: absolute;
    height: calc(100% - 75px);
}
.loader-container-watch-list{
    min-height: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.all-wells-table-block {
    border: 1px solid #4a5463;
    border-radius: 8px;
    background: llinear-gradient(182deg, #253240 1.42%, rgba(37, 48, 64, 0.40) 15.14%);
    .removepadding{
     padding: 0px;
    }
  .dropdown-container{
    position: relative;
  }
  th:nth-child(2), td:nth-child(2) {
    border-right: 1px solid #4a5463;
  }
  .table-container.w-full {
    min-height: 500px !important;
    height: 100%;
    max-height: calc(100vh - 380px);
}
}
.all-wells-section {
    margin-top: 2rem;
    .header {
        padding: 20px 24px 20px 24px;
        display: flex;
        justify-content: space-between;
        .title {
            padding: 0;
            border: none;
            width: auto;
            
        }

        .active-color{
            color: #60BFDA !important;
            cursor: pointer;
        }
    }
}

.all-wells-section .serch-filter-section {
    padding: 0.75rem 1.5rem;
    justify-content: right;
    .block {
        margin-right: 0;
    }
}

.all-wells-table-block {
    .table-container .document-head {
        background: #1c2837 !important;
        .intake-pressure__dropdown-input-container {
            background: transparent!important;
            border-color: transparent!important;
            input {
                background: transparent!important;
            }
        }
    }
}
.headerline{
    flex-direction: unset!important;
}

.allWellListTimeseries {
    width: 30px;
    height: 30px;
    margin: 0 20px 0 10px;
    & .loader-container {
        position: static !important;
        & .loader {
            width: 30px;
            height: 30px;
        }
    }
  }