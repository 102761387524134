@import "/src/styles//darktheme/utilities/variables";

.control-room-well {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 24px 32px;

    .loader-container {
        position: absolute;
        width: 100%;
    }

    .watch-wells-list-container {
        width: 100%;
        height: 213px;
    }

    .watch-wells-list-container .loader-container {
        position: absolute;
        width: unset;
        height: unset;
        pointer-events: none;
    }

    .watch-wells-list {
        gap: 24px;
        margin: 0px 0px 0px 24px;
        padding-right: 24px;
        overflow-x: scroll;
        min-height: 213px;
        align-items: center;

        &.blur-background {
            filter: blur(4px);
        }

        &.visibility-hidden {
            visibility: hidden;
        }

        .sticky-card {
            position: sticky;
            left: 0;
            z-index: 5;
            background: #162333;
        }
    }

    .d-none {
        display: none !important;
    }

    .watch-list-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 417px;

        min-width: 417px;
        height: 157px;
        border-radius: 8px;
        border: 1px solid var(--Darkmode-400, #4A5463);
        background: var(--Dark-Mode-Gradient-Cards, linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.40) 35.14%));
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        padding: 11px 17px;
        cursor: pointer;
        position: relative;
    }

    .watch-list-card-active {
        border-color: #60BFDA !important;
    }

    .watch-list-card .watch-list-card-title {
        color: #F7F9F9;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
    }

    .watch-list-card .watch-list-card-description {
        color: #F4FBFC;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 9px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;


    }

    .watch-list-card .watch-list-count h3 {
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
        font-size: 39px;
        font-weight: 700;
        line-height: 47px;
        letter-spacing: -1.56px;
        text-align: right;
        padding-right: 7px;
    }

    .header-right {
        display: flex;
        height: 36px;
    }
}

.modal.delete-modal {
    background-color: unset;
}

.watchlist-no-data {
    justify-content: center;
    width: 100%;

    .no-data {
        margin-top: 0;
    }
}

.watch-list-count .loader-container {
    position: unset !important;
    display: flex;
    justify-content: end;
}

.watch-list-count .loader-container .loader {
    width: 30px;
    height: 30px;
}

.watch-wells-list-loader .loader-container {
    z-index: 99;
}