@import '../../../styles/darktheme/utilities/variables';


.refresh-status-modal .refresh-well-status-date {
    background: #131f2e;
    border: 1px solid var(--Dark-mode-400, #4a5463);
    color: #f7f9f9;
    height: 36px !important;
    padding-left: 26px !important;
    border-radius: 5px;
    margin: 1px 0;
    padding: 2px 5px;
}

.refresh-status-modal .refresh-date-label {
    color: #fff !important;
    font-size: 1.2rem;
    font-weight: 400;
    padding-bottom: .5rem;
}

// .profile-card {
//   width: 97%;
//   margin: 15px auto;
//   .body {
//     padding: 2rem;
//   }
//   .title {
//     color: $black;
//     font-size: 18px;
//   }
//   .header {
//     gap: 4px;
//     padding: 1.1rem 1.5rem;
//     .title {
//       font-weight: 600;
//       font-size: 1.3rem;
//       color: $black;
//     }
//   }
// }

.body-height {
    height: 100%;
}