.manage-asset-container {
    height: 100%;

    .content-block {
        min-height: calc(100% - 89px);
        // max-height: calc(100% - 89px);
        padding: 0px;
        overflow-y: auto;
    }

    .diabled-input {
        pointer-events: none;
    }

    .manage-asset-form-control {
        display: flex;
        flex-direction: column;
        gap: 6px;

        .time-input-field {
            width: 100%;
            padding: 1rem;
            color: #b8c5cc;

            &.disabled {
                background-color: #131f2e;
                cursor: default !important;
                pointer-events: none;
            }
        }

        .backup-container {
            margin-left: 0px !important;

            .backup-dropdown {
                background: var(--dark-mode-800, #001023);
                height: 100%;

                .selected-option {
                    padding: 1rem;
                    color: #b8c5cc;

                }

                &.disabled {
                    background-color: #131f2e;
                    pointer-events: none;
                }
            }
        }


        textarea.time-input-field {
            border: 1px solid #4A5463;
            height: 114px;
        }
    }

    .button {
        display: flex;
        flex-direction: row;
        padding: 8px 14px 8px 14px;
        gap: 8px;
        border-radius: 8px;
        border: 1px 0px 0px 0px;
        border: 1px solid #4A5463;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;
        color: #F7F9F9;
        align-items: center;

        &.disabled {
            pointer-events: none;
            color: #4A5463;

            svg {
                fill: #4A5463;
            }
        }

        &.stop {
            background: #D92D20;
            border: 1px solid #D92D20
        }

        &.stop:hover {
            background: #D92D20;
        }
    }

    .button:hover {
        background: #131F2E;
    }

    .speed-description {

        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        color: var(--Neutral-Grey200, #B8C5CC);
        display: flex;
        gap: 20px;
        justify-content: center;

        .pipe {
            border-left: 1px solid #B8C5CC;
        }

    }

    .event-block {
        padding: 0px;
        border-top: 1px solid #4A5463;
        padding-top: 20px;

    }

    .disabled-block {
        pointer-events: none;

        .label {
            color: #647980;
        }
    }

    .button-block {
        height: 89px;
        border-top: 1px solid #4A5463;
        margin: 0px -32px;
        // padding: 0px 32px;
        padding-left: 32px;
        padding-right: 32px;
        margin-top: 32px;
    }

}