/*Dark theme colors*/
$white: #ffffff;
$black: #000000;

$dark-primary: #001023;
$dark-secondary: #131f2e;
$dark-tertiary: #253040;
$dark-light-shade1: #384252;
$dark-light-shade2: #4a5463;
$dark-light-shade3: #5d6675;
$dark-light-shade4: #6f7887;
$dark-light-shade5: #828a99;
$dark-background: #142132;

/*Neutral text colors*/
$dark-text-primary: #f7f9f9;
$dark-text-secondary: #f1f4f5;
$dark-text-tertiary: #e7ecee;
$neutral-dark: #0d1011;
$neutral-dark-shade1: #191f23;
$neutral-dark-shade2: #323f46;
$neutral-dark-shade3: #4b5e68;
$neutral-dark-shade4: #647980;
$neutral-light-shade1: #91a4aa;
$neutral-light-shade2: #a0b1bb;
$neutral-light-shade3: #b8c5cc;
$neutral-light-shade4: #d0d8dd;

/*Responsiveness sass Media Queries*/
/* $mq-breakpoints: (
  mobile: 375px,
  tablet: 768px,
  desktop: 992px,
  tabletLandscape: 1025px,
  wide: 1300px,
  desktopAd: 810px,
  mobileLarge: 580px,
  mobileLandscape: 480px,
);
 */
/* $show-breakpoints: (mobile, mobileLandscape, tablet, desktop, wide, desktopAd, mobileLarge, mobileLandscape);

@use 'path/to/mq' with (
  $breakpoints: $mq-breakpoints,
  $show-breakpoints: $show-breakpoints
); */

$desktop: 'only screen and (min-width: 1024px)';
$miniDesktop: 'only screen and (min-width: 1024px) and (max-width: 1280px)';
$tabletMiniDesktop: 'only screen and (min-width: 1281px) and (max-width: 1549px)';
$mediumDesktop: 'only screen and (min-width: 1201px) and (max-width: 1370px)';
$largeDesktop: 'only screen and (min-width: 1550px) and (max-width: 1920px)';
$xxlargeDesktop: 'only screen and (min-width: 1920px)';
$miniDesktoptwo: 'only screen and (min-width: 1024px) and (max-width: 1280px)';
$mediumtolargeDesktop: 'only screen and (min-width: 2431px)';
$mediumVerylargetolargeDesktop: 'only screen and (min-width: 4098px)';

/*Fonts*/
$primary-font-stack: 'Mulish', sans-serif;

/*section containers*/
$max-width: 1920px;
$md-width: 1200px;

/*light theme colors*/
$light-primary: #002430;
$light-primary-shade1: #003747;
$light-primary-shade2: #005f7c;
$light-primary-shade3: #00789e;
$light-primary-shade4: #0094bd;
$light-primary-shade5: #60bfda;
$light-primary-shade6: #86cfe3;
$light-primary-shade7: #a6dbea;
$light-primary-shade8: #c3e7f1;
$light-primary-shade9: #dcf1f7;
$light-primary-shade10: #f4fbfc;
$light-primary-shade10: #eaf9ff;

$light-secondary: #661913;
$light-secondary-shade1: #912018;
$light-secondary-shade2: #bf3b04;
$light-secondary-shade3: #dc4405;
$light-secondary-shade4: #fa6020;
$light-secondary-shade5: #fb743c;
$light-secondary-shade6: #ff8f61;
$light-secondary-shade7: #fca682;
$light-secondary-shade8: #fdc3ab;
$light-secondary-shade9: #fee1d5;
$light-secondary-shade10: #fff5f1;

/*error colors*/
$error-dark: #7a271a;
$error-dark-shade1: #991a0f;
$error-dark-shade2: #b42318;
$error-dark-shade3: #d92d20;
$error-dark-shade4: #f04438;
$error-dark-shade5: #f97066;
$error-light-shade1: #fda29b;
$error-light-shade2: #fecdca;
$error-light-shade3: #fee4e2;
$error-light-shade4: #fef3f2;
$error-light-shade5: #fffbfa;

/*warning colors*/
$warning-dark: #7a2e0e;
$warning-dark-shade1: #93370d;
$warning-dark-shade2: #b54708;
$warning-dark-shade3: #dc6803;
$warning-dark-shade4: #f79009;
$warning-dark-shade5: #fdb022;
$warning-light-shade1: #fec84b;
$warning-light-shade2: #fedf89;
$warning-light-shade3: #fef0c7;
$warning-light-shade4: #fffaeb;
$warning-light-shade5: #fffcf5;

/*success colors*/
$success-dark: #054f31;
$success-dark-shade1: #05603a;
$success-dark-shade2: #027a48;
$success-dark-shade3: #039855;
$success-dark-shade4: #12b76a;
$success-dark-shade5: #32d583;
$success-light-shade1: #6ce9a6;
$success-light-shade2: #a6f4c5;
$success-light-shade3: #d1fadf;
$success-light-shade4: #ecfdf3;
$success-light-shade5: #f6fef9;

/*cyan colors*/
$cyan-dark: #012b33;
$cyan-dark-shade1: #004654;
$cyan-dark-shade2: #00768e;
$cyan-dark-shade3: #3295a9;
$cyan-dark-shade4: #5dabbb;
$cyan-dark-shade5: #7fbdc9;
$cyan-light-shade1: #9cccd6;
$cyan-light-shade2: #b6d9e0;
$cyan-light-shade3: #cde5ea;
$cyan-light-shade4: #e2f0f3;
$cyan-light-shade5: #f6fafb;

/*light teal colors*/
$lightteal-dark: #2e655c;
$lightteal-dark-shade1: #3a7e73;
$lightteal-dark-shade2: #45978a;
$lightteal-dark-shade3: #52afa0;
$lightteal-dark-shade4: #6bbbae;
$lightteal-dark-shade5: #84c6bc;
$lightteal-light-shade1: #9cd2c9;
$lightteal-light-shade2: #b5ddd7;
$lightteal-light-shade3: #cee8e4;
$lightteal-light-shade4: #e6f4f2;
$lightteal-light-shade5: #f7fbfb;

/*teal colors*/
$teal-dark: #004843;
$teal-dark-shade1: #036a64;
$teal-dark-shade2: #00857d;
$teal-dark-shade3: #289891;
$teal-dark-shade4: #55aea8;
$teal-dark-shade5: #7abfba;
$teal-light-shade1: #98ceca;
$teal-light-shade2: #b3dbd8;
$teal-light-shade3: #cbe6e4;
$teal-light-shade4: #e1f1f0;
$teal-light-shade5: #f5fafa;

/*light orange colors*/
$lightorange-dark: #9f6a00;
$lightorange-dark-shade1: #c78500;
$lightorange-dark-shade2: #ef9f00;
$lightorange-dark-shade3: #ffb217;
$lightorange-dark-shade4: #ffbf3f;
$lightorange-dark-shade5: #ffd072;
$lightorange-light-shade1: #ffd98c;
$lightorange-light-shade2: #ffe5b2;
$lightorange-light-shade3: #ffeecc;
$lightorange-light-shade4: #fff6e5;
$lightorange-light-shade5: #fffbf2;

/*dark blue colors*/
$darkblue-dark: #001f46;
$darkblue-dark-shade1: #002f6c;
$darkblue-dark-shade2: #003984;
$darkblue-dark-shade3: #004eb3;
$darkblue-dark-shade4: #006dfa;
$darkblue-dark-shade5: #4294ff;
$darkblue-light-shade1: #89bcff;
$darkblue-light-shade2: #b8d7ff;
$darkblue-light-shade3: #d0e4ff;
$darkblue-light-shade4: #e7f2ff;
$darkblue-light-shade5: #f1f7ff;

/*primary input ui colors*/
$primary-light-border: #ddd;
$primary-dark-border: #4A5463;

/*primary units sizes*/
$root-unit: 0;
$unit-10px: 10px;
$full-unit: 100%;
$default-px: 12px;
$default-rel: 1rem;
$font-size: 16px;
$margin-xs: 5px;
$margin-md: 10px;
$padding-xs: 5px;
$padding-md: 10px;
