.scrollable-container {
  display: flex;
  width: 1853px;
  padding: 20px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.performance-curve-frame {
  display: flex;
  height: 665px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-grey-50, #e7ecee);
  background: var(--shades-white, #fff);

  /* Shadows/sm */
  box-shadow:
    0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.card-header {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}

.card-header-content {
  display: flex;
  padding: 20px 24px 12px 24px;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}

.card-header-frame {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
}

.card-header-text-container {
  display: flex;
  width: 1111.962px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}

.card-header-text {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--neutral-grey-800, #191f23);
  flex: 1 0 0;
  /* Paragraph 3 [P3]/Semibold */
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 155.556% */
}

.divider {
  display: flex;
  height: 1px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  height: 1px;
  background: var(--neutral-grey-100, #d0d8dd);
}

.performance-curve-content {
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.performance-curve-graph {
  display: flex;
  width: 1207px;
  padding: 12px 0px;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  flex-direction: column;
}

.vertical-divider {
  background: var(--neutral-grey-100, #d0d8dd);
  align-self: stretch;
  width: 1px;
}

.header {
  display: flex;
  padding: 0px 24px 20px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  align-self: stretch;
}

.content-header-frame {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.gl-analysis-card {
  display: flex;
  height: 496px;
  padding: 0px 24px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 95%;
  margin-left: 15px;
}
.curve-graph {
  width: 100%;
}
.data-frame {
  display: flex;
  padding: 8px 0px 20px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}

.tabs {
  display: flex;
  padding: 8px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.performance-data-frame {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--neutral-grey-50, #e7ecee);
  background: var(--shades-white, #fff);

  /* Shadows/sm */
  box-shadow:
    0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}

.data-container {
  display: flex;
  padding: 20px 20px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.container1 {
  display: flex;
  width: 1183px;
  align-items: flex-start;
  gap: 16px;
}

.input-container {
  display: flex;
  height: 356px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.output-container {
  display: flex;
  height: 356px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}

.xd-analysis-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  background: var(--shades-white, #fff);
  width: 100%;
}

.analysis-result-item-header-container {
  width: 100%;
}
.card-date-item-container {
  width: 100%;
}
.tab-content {
  width: 100%;
}
