.equipment-modal {
.header-modal {
    padding: 20px 24px 0px 24px;
    .header-title {
        font-size: 16px !important;
        padding-bottom: 10px;

    }
}
.border-line{   
    border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
}

.modal-content {
    display: flex;

    .content-left {
        display: flex;
        justify-content: center;
        align-items: center; 
        flex-direction: column;
        height: 100%; 
        width: 30%;
        padding-top: 70px;   
    
        .equipment-image-section {
            display: flex;
            flex-direction: column;
            align-items: center; 
            text-align: center; 
            border: 1px solid #4A5463; 
            border-radius: 10px; 
            padding: 15px;            
            width: 100%;
            .img-heading {
                color: #fff;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
                padding-bottom: 10px;
            }
    
            img {
                margin-top: 15px;
            }
        }
        .diagram-description {
            display: flex;
            color: #fff;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
            padding-top: 15px;
            span {
                padding-left: 10px;
            }
        }
    }
    .content-right {
        width: 70%;
        padding-top: 70px;   
       .curv-graph .curv-heading {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        padding-top: 15px;
        padding-left: 110px
        }
    }
}
}