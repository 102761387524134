
@font-face {
    font-family: "Mulish";
    src:
        url("Mulish-200-ExtraLight.ttf")  format("truetype"),
        url("Mulish-200-ExtraLight.eot")  format("embedded-opentype"),
        url("Mulish-200-ExtraLight.woff") format("woff");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src:
		url("Mulish-200-ExtraLightItalic.ttf")  format("truetype"),   
		url("Mulish-200-ExtraLightItalic.eot")  format("embedded-opentype"),
		url("Mulish-200-ExtraLightItalic.woff") format("woff");
	font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "Mulish";
    src:
		url("Mulish-300-Light.ttf")  format("truetype"),
		url("Mulish-300-Light.eot")  format("embedded-opentype"),
		url("Mulish-300-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src:
		url("Mulish-300-LightItalic.ttf")  format("truetype"),
		url("Mulish-300-LightItalic.eot")  format("embedded-opentype"),
		url("Mulish-300-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Mulish";
    src:
		url("Mulish-400-Regular.ttf")  format("truetype"),
		url("Mulish-400-Regular.eot")  format("embedded-opentype"),
		url("Mulish-400-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src:
		url("Mulish-400-RegularItalic.ttf")  format("truetype"),
		url("Mulish-400-RegularItalic.eot")  format("embedded-opentype"),
		url("Mulish-400-RegularItalic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Mulish";
    src:
		url("Mulish-500-Medium.ttf")  format("truetype"),
		url("Mulish-500-Medium.eot")  format("embedded-opentype"),
		url("Mulish-500-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src:
		url("Mulish-500-MediumItalic.ttf")  format("truetype"),
		url("Mulish-500-MediumItalic.eot")  format("embedded-opentype"),
		url("Mulish-500-MediumItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Mulish";
    src:
		url("Mulish-600-SemiBold.ttf")  format("truetype"),
		url("Mulish-600-SemiBold.eot")  format("embedded-opentype"),
		url("Mulish-600-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src:
		url("Mulish-600-SemiBoldItalic.ttf")  format("truetype"),
		url("Mulish-600-SemiBoldItalic.eot")  format("embedded-opentype"),
		url("Mulish-600-SemiBoldItalic.woff") format("woff");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Mulish";
    src:
		url("Mulish-700-Bold.ttf")  format("truetype"),
		url("Mulish-700-Bold.eot")  format("embedded-opentype"),
		url("Mulish-700-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src:
		url("Mulish-700-BoldItalic.ttf")  format("truetype"),
		url("Mulish-700-BoldItalic.eot")  format("embedded-opentype"),
		url("Mulish-700-BoldItalic.woff") format("woff");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Mulish";
    src:
		url("Mulish-800-ExtraBold.ttf")  format("truetype"),
		url("Mulish-800-ExtraBold.eot")  format("embedded-opentype"),
		url("Mulish-800-ExtraBold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src:
		url("Mulish-800-ExtraBoldItalic.ttf")  format("truetype"),
		url("Mulish-800-ExtraBoldItalic.eot")  format("embedded-opentype"),
		url("Mulish-800-ExtraBoldItalic.woff") format("woff");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "Mulish";
    src:
		url("Mulish-900-Black.ttf")  format("truetype"),
		url("Mulish-900-Black.eot")  format("embedded-opentype"),
		url("Mulish-900-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Mulish";
    src:
		url("Mulish-900-BlackItalic.ttf")  format("truetype"),
		url("Mulish-900-BlackItalic.eot")  format("embedded-opentype"),
		url("Mulish-900-BlackItalic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
}

