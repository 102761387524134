.horizontal-tabs {
  display: flex;
  height: 52px;
  padding: 8px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border-bottom: 1px solid var(--neutral-grey-50, #e7ecee);
  background: var(--Grey-A25, #f1f4f5);
}

.tab {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}

.active {
  border-radius: 6px;
  background: var(--shades-white, #fff);

  /* Tab shadow */
  box-shadow:
    0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    1px 1px 2px 0px rgba(0, 0, 0, 0.1),
    2px 3px 4px 0px rgba(0, 0, 0, 0.09),
    5px 6px 5px 0px rgba(0, 0, 0, 0.05),
    9px 11px 6px 0px rgba(0, 0, 0, 0.01),
    14px 17px 6px 0px rgba(0, 0, 0, 0);
}

.inactive {
  border-radius: 6px;
}
