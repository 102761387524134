.card-date-item-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d0d8dd;
  min-width: 240px;
  height: 55px;
  min-height: 55px;
  background: var(--shades-white, #fff);
}
.card-date-item-item-container-active {
  background: var(--neutral-grey-25, #f7f9f9);
}
.card-date-item-item-container {
  padding: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.card-date-item-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 36px;
}
.card-date-item-text {
  font-size: 12px;
  min-height: 1.125rem;
  line-height: 1.125rem;
}
.card-date-item-card-type-text {
  color: #191f23;
  font-weight: 600;
}
.card-date-item-card-date-text {
  color: #4b5e68;
}
.card-date-item-chart-color {
  min-width: 2px;
  height: 100%;
  border-radius: 4px;
}

.brush-icon-container {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  background: var(--shades-white, #fff);
}

.brush-icon {
  width: 24px;
  height: 24px;
}
