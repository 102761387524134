.image-view-component .data-overlay-layer { position: absolute; transform-origin: top left; }

.image-view-component .data-overlay 
{ 
    position:absolute;
    cursor: default; 
    border: 1px solid silver; 
    border-radius:4px; 
    box-shadow: 2px 2px 4px #ddd;
    background-color: rgb(255,255,255); 
}

.image-view-component .data-overlay .data-value { display: table-row; }
.image-view-component .data-overlay .data-value:hover { background-color: rgba(255, 255, 0, 0.25); }

.image-view-component .data-overlay .data-value .label, 
.image-view-component .data-overlay .data-value .value { display:table-cell; padding: 0.5em 1em; white-space: nowrap; }            

.image-view-component .data-overlay .data-value .value {text-align: right; color: #458CA2; font-weight: 500; }

/* Data overlay 'states' */
.image-view-component .data-overlay:has(.data-value.emphasis) { border-color: #458CA2; }
.image-view-component .data-overlay .data-value.emphasis .label,
.image-view-component .data-overlay .data-value.emphasis .value { color: #458CA2; background-color: #DCF1F7; font-weight: bold; } 

.image-view-component .data-overlay:has(.data-value.alarm) { border-color: #DC4405; }
.image-view-component .data-overlay.alarm .data-value .label,
.image-view-component .data-overlay.alarm .data-value .value { color: #DC4405; background-color: #FEF5F5; font-weight: bold; } 

.image-view-component .data-overlay:has(.data-value.good) { border-color: #12B76A; }
.image-view-component .data-overlay .data-value.good .label,
.image-view-component .data-overlay .data-value.good .value { color: #12B76A; background-color: #ECFDF3; font-weight: bold; } 
