@import "/src/styles//darktheme/utilities/variables";

.ReactModalPortal {
    & .ReactModal__Overlay--after-open {
        .performaceSnapshotModal {
            width: 65% !important;
            max-width: 98% !important;
            & .modal-header {
                border-bottom: none;
            }
            & .modal-content {
                height: 100%;
                padding-bottom: 15px;
            }
        }
    }
}

@media (max-width: 992px){
    .ReactModalPortal {
        & .ReactModal__Overlay--after-open {
            & .ReactModal__Content {  
                &.performaceSnapshotModal {
                    height: 100%;
                    max-height: 280px;
                }             
            }
        }
    } 
}

@media (max-width: 765px){
    .ReactModalPortal {
        & .ReactModal__Overlay--after-open {
            & .ReactModal__Content {  
                &.performaceSnapshotModal {
                    height: 100%;
                    max-height: 250px;
                }             
            }
        }
    } 
}

@media (min-width: 1200px){
    .ReactModalPortal {
        & .ReactModal__Overlay--after-open {
            & .ReactModal__Content {  
                &.performaceSnapshotModal {
                    height: 100%;
                    max-height: 450px;
                }             
            }
        }
    } 
}

@media (min-width: 1350px){
    .ReactModalPortal {
        & .ReactModal__Overlay--after-open {
            & .ReactModal__Content {  
                &.performaceSnapshotModal {
                    max-height: 480px;
                }             
            }
        }
    } 
}


@media (min-width: 1520px){
    .ReactModalPortal {
        & .ReactModal__Overlay--after-open {
            & .ReactModal__Content {  
                &.performaceSnapshotModal {
                    max-height: 570px;
                }             
            }
        }
    } 
}

@media (min-width: 1920px){
    .ReactModalPortal {
        & .ReactModal__Overlay--after-open {
            & .ReactModal__Content {  
                &.performaceSnapshotModal {
                    max-height: 680px;
                }             
            }
        }
    } 
}

@media (min-width: 2420px){
    .ReactModalPortal {
        & .ReactModal__Overlay--after-open {
            & .ReactModal__Content { 
                &.performaceSnapshotModal {
                    max-height: 850px;
                }            
            }
        }
    } 
}

@media (min-width: 2820px){
    .ReactModalPortal {
        & .ReactModal__Overlay--after-open {
            & .ReactModal__Content { 
                &.performaceSnapshotModal {
                    max-height: 1250px;
                }            
            }
        }
    } 
}

@media (min-width: 3900px){
    .ReactModalPortal {
        & .ReactModal__Overlay--after-open {
            & .ReactModal__Content { 
                &.performaceSnapshotModal {
                    max-height: 1600px;
                }            
            }
        }
    } 
}