.toolbar-eye-btn {
  display: flex;
  padding: 10px;
  gap: 4px;
  border: none;
  background: white;
  cursor: pointer;
  position: relative;
  width: 45px;
}
.download-btn {
  display: flex;
  padding: 10px;
  margin-left: 8px;
  gap: 4px;
  border: none;
  background: white;
  cursor: pointer;
  position: relative;
  width: 45px;
}
.chevron-icon {
  position: absolute; /* Position the centered items absolutely */
  top: 18px; /* Center vertically */
  left: 85%; /* Center horizontally */
}
