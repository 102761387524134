@import '../../../styles//darktheme/utilities/variables';


.red-color div  {
  color: red !important;
}

.white-color{
  color: white;
}
.asset-list {
  &__cell-one {
    &.lastInfluxData {
      width: 230px;
    }
    &.lastXSPOCData{
      width: 250px; 
    }
  }
  &__cell {
    &.lastInfluxData {
      width: 230px;
    }  
    &.lastXSPOCData{
      width: 250px; 
    }
  }

  @media #{$mediumtolargeDesktop} {
    &__cell-one{
      &.lastInfluxData{
        width: 300px;
      }
      &.lastInfluxData{
        width: 300px;
      }
    }
    &__header {
      &.bodywidth {
        width: 130% !important;
        margin-left: 16px;
      }
    }
    &__table {
      &.bodywidth {
        width: 130% !important;
      }
    }
    &__cell {
      &.lastInfluxData {
        width: 300px;
      }
      &.lastXSPOCData{
        width: 300px; 
      }
      &.center{
        width: 4.5% !important;
      }
    }
  }
}
.loadermoving .loader-container{  
  top: 48%;
}