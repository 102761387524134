.reassign-ticket-modal {
    width: 768px;
    height: 456px;
    min-height: 456px;
    transform: none !important;
    inset: unset !important;
    top: 50% !important;
    left: 50% !important;
    margin-top: -200px;
    margin-left: -320px;

    .reassign-ticket {
        height: 368px;

        .reassign-ticket-header {
            height: 85px;
            padding: 22px 25px;
            font-weight: 500;
            font-size: 28px;
            line-height: 34px;
            letter-spacing: -4%;
            color: var(--Primary-Indigo25, #F4FBFC);

        }

        .reassign-ticket-content {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .assignee {
                height: 76px;
            }

            .reassign-label {
                margin-bottom: 6px;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0%;
                color: #F7F9F9;
            }

            .notes {
                height: 155px;
                margin-bottom: 34px;
                display: flex;
                flex-direction: column;
                padding: 0px 15px;
            }

            .reassign-ticket-input {
                background: var(--Darkmode-800, #001023);
                border: 1px solid #4A5463;
                color: #F7F9F9;
            }

            .reassign-teaxtarea {
                resize: none;
                background: var(--Darkmode-800, #001023);
                border: 1px solid #4A5463;
                border-radius: 8px;
                padding: 10px 12px;
                height: 129px;
                color: var(--Neutral-Grey-25, #F7F9F9);
                font-family: Mulish;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;

            }
        }
    }


    .reassign-ticket-footer {
        height: 88px;
        padding: 20px 24px;
        bottom: 0px;
        border-top: 1px solid #4A5463;

        button {
            border-radius: 8px;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            display: flex;
            transform: rotate(-0.11deg);
            padding: 12px 18px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            width: 140px;
            height: 46px;
            font-family: Mulish;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        }

        .cancel-btn {
            border: 1px solid var(--Dark-mode-400, #4A5463);
        }

        .create-btn {
            border-radius: 8px;
            border: 1px solid var(--Primary-Indigo-700, #005F7C);
            background: var(--Primary-Indigo-600, #00789E);
        }

        .create-btn:disabled {
            background-color: #6c757d;
            color: #fff;
            border-color: #6c757d;
            cursor: not-allowed;
            opacity: 0.6;
        }
    }
}