@import '../../../../styles/darktheme/utilities/variables';

.details-block {
    width: 100%;
    padding: 24px 32px;
    background-color: $dark-primary;
    & .one {
        margin: 0;
        cursor: pointer;
    }
    & .two {
        margin-left: 0;
        margin-right: 0;
    }
    & .asset-container{
        & h1 {
            font-size: 18px;
            font-weight: 600;
        }
    }
    & .details-container {
        & .header-container{
            & .header-item{
                font-weight: 600;
                color: #F7F9F9;
            }
        }
        & .item-container {
            color: #F7F9F9;
        }
    }
    & .trends-container-date-range {
        & #filter-btn {
            height: 40px;
        }
    }
    
}
.details-header-section {
    & .title {
        & h1 {
            font-weight: 600;
        }
    }
}

.asset-expand-btn {
    display: flex;
transform: rotate(-0.11deg);
padding: 8px;
justify-content: center;
align-items: center;
gap: 8px;
margin-right: 15px;

border-radius: 4px;
border: 1px solid var(--Primary-Indigo-700, #005F7C);
background: var(--Primary-Indigo600, #00789E);

/* Shadows/xs focused 4px primary - 50 */
box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #001023;
}

.asset-container {
    width: 100%;
    height: 58px;
    display: flex;
    align-items: center;
    padding: 15px;
}

.detail-table-container {
    max-height: 315px;
    padding: 16px;
    gap: 16px;
    // border: 1px solid red;
    border:0;
    display: flex;
    background-color: #001023;
    // flex-direction: row;
    justify-content: space-between;
    min-height: 315px;
    border-radius: 0 0 8px 8px;
}