.analysis-result-header-container {
  display: flex;
  width: 100%;
  padding: 0.6875rem 0.8125rem;
  align-items: center;
  gap: 0.9375rem;
  background: var(--shades-white, #fff);
  height: 55px;
}

.analysis-result-button {
  display: flex;
  transform: rotate(-0.11deg);
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid var(--primary-indigo-700, #005f7c);
  background: var(--primary-indigo-700, #005f7c);

  /* Shadows/xs focused 4px primary - 50 */
  box-shadow:
    0px 0px 0px 4px #dcf1f7,
    0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.analysis-result-image {
  stroke: white;
}

.analysis-result-title-text {
  flex: 1 0 0;
  color: var(--primary-indigo-700, #005f7c);

  /* Paragraph 2 [P2]/Semibold */
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.analysis-result-description-text {
  flex: 1 0 0;
  color: var(--neutral-grey-700, #323f46);
  text-align: right;

  /* Caption/Regular */
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.125rem;
}
