.control-room-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #F7F9F9;
    height: 66px;
    padding: 0 32px;
    border-bottom: 1px solid #4A5463;
    background-color: #131F2E;


    .control-room-header-title {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
    }

}

.header-icon {
    align-items: center;
    display: flex;
    gap: 20px;
    margin-right: .75rem;
}
