@import '../../../../../styles/darktheme/utilities/variables';

.tab-header {
    background-color: inherit !important;
}

.analysis-tab-content {
    width: 400px;
    padding: 10px 16px 10px 16px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #4A5463;
    background-color: #131F2E;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #F7F9F9;
    margin-bottom: 16px;
    @media #{$largeDesktop}, #{$xxlargeDesktop}, #{$mediumtolargeDesktop} {
        width: 448px;
    }
}

.analysis-content {
    width: 400px;
    padding: 10px 16px 10px 16px;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #4A5463;
    background-color: #131F2E;
    color: #F7F9F9;
    margin-bottom: 16px;
    @media #{$largeDesktop}, #{$xxlargeDesktop}, #{$mediumtolargeDesktop} {
        width: 448px;
    }
}

.analysis-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin-bottom: 10px;
}

.cus-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #4A5463;
    padding: 12px;
    &:last-child {
        border-bottom: none;
    }
}

.padding-left-0 {
    padding-left: 0;
}