@import '../../styles/darktheme/utilities/variables';


.grid-item{
    &.wellcontrolroom-graphs {
        border-color: #4A5463;
    }
}

.well-graph-card {
    padding-left: 10px;
    padding-right: 10px;
    & .pie-section, & .title {
        padding: 0;
    }
    & .header-icon {
        margin-right: 20px;
    }

}
.wellChartModal {
    & .modal-content {
        height: 100%;
    }
}
.wellChart-container-dashboard {
    & .chart-wrapper {
        width: 50%;
    }
    &.nodata{
        height: 100%;
    }
}
.tempChart-container-dashboard {
    & .chart-wrapper {
        width: 33.33%;
    }
}
.speedChart-container-dashboard {
    & .chart-wrapper {
        width: 50%;
    }
}

.switch-block{
    & .toggle-switch {
        justify-content: flex-end;
        display: flex;
        align-items: center;
        & label {
            order: 2;
            margin: 8px 0;
        }
        & h2 {
            margin: 0 10px 0 10px;
            order: 1;
        }
    }
}

.wellChartSection {
    height: 100%;
    max-height: 100%;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    & .no-data {
        margin-top: 0;
    }
}

@media (min-width: 1920px){
    .ReactModalPortal {
        & .ReactModal__Overlay--after-open {
            & .ReactModal__Content {
                &.speedChartModal, &.temperatureChartModal, &.backPressureModal {
                    width: 80rem !important;
                    height: 100%;
                    max-height: 700px;
                }   
                &.ampsModal, &.prodChartModal, &.casingChartModal, &.intakeChartModal {
                    width: 70% !important;
                    height: 100%;
                    max-height: 800px;
                }             
            }
        }
    } 
}

@media (min-width: 2420px){
    .ReactModalPortal {
        & .ReactModal__Overlay--after-open {
            & .ReactModal__Content {
                &.speedChartModal, &.backPressureModal {
                    width: 90rem !important;
                    max-height: 800px;
                }   
                &.temperatureChartModal {
                    width: 130rem !important;
                    max-height: 800px;
                }    
                &.ampsModal, &.prodChartModal, &.casingChartModal, &.intakeChartModal {
                    max-height: 900px;
                }            
            }
        }
    } 
}