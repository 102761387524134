@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

.toolbar-dropdown-container {
  display: flex;
  align-items: center;
  gap: 12px;
  background: white;
  width: 112px;
  height: 32px;
}
html {
  overflow-y: auto;
}
.main-content {
  flex-grow: 1;
  background-color: #F1F4F5;
  padding-top: 64px;
  overflow-x: hidden;
  }
  .openDrawer-content {
    padding-top: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 0;
    & .dashboard-first-block {
      & .top-header {
        width: 100%;
        position: relative;
      }
    }
    & .nav-margin-top {
      margin-top: 0;
    }
  }

  img {
    -webkit-user-drag: none;
  }

  .ui-button-toolbar {
    & .ui-button-toolbar-button {
      font-weight: 600;
    }
  }

