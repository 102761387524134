 .onboarding-container {
   .onboard-number-input {
     width: 100%;
     display: flex;
     flex-direction: row;
     gap: 10px;

     .code-input {
       width: 40px !important;
     }

     .number-input {
       flex: 1;
     }
   }

   .add-user-icon {
     display: flex;
     width: 33px;
     height: 33px;
     cursor: pointer;
     border: none;
     align-items: center;
     justify-content: center;
     border-radius: 8px;
   }

   .add-user-icon:hover {
     cursor: pointer;
     background-color: #384252;
   }

   .add-user-form {
     display: flex;
     flex-direction: column;
   }

   .error {
     color: red;
     font-size: 12px;
     margin-top: 5px;
   }

   .input-error {
     border: 1px solid red !important;
   }

   .form-group-inline {
     display: flex;
     align-items: center;

     label {
       margin-right: 10px;
     }

     .checkbox-input {
       margin-right: 90%;
     }
   }

   .form-group-password-check {
     margin-top: 8px;
     display: flex;
     gap: 5px;

     .checkbox-input {
       width: 4% !important;
     }
   }

   .flex-row {
     display: flex;
     align-items: center;
   }

   .modal-overlay {
     z-index: 1000;
   }

   .change-password-button {
     background: #005f7c;
     border: 1px;
     border-radius: 8px;
     color: #fff;
     padding: 8px 30px;
     width: 90%;
     padding: 10px 14px 10px 14px;
     gap: 8px;
     margin-top: 25px;
   }

   .change-password-button:disabled {
     background-color: #6c757d;
     color: #fff;
     border-color: #6c757d;
     cursor: not-allowed;
     opacity: 0.6;
   }

   .change-password-button:hover {
     cursor: pointer;
   }

   .settings-form-group {
     flex: 1;
     display: flex;
     justify-content: start;
     align-items: center;
     gap: 5px;
   }

   .required-asterisk {
     color: red;
     margin-left: 0.3rem;
   }


   .alert-icon-container {
     display: flex;
   }

   .form-change-password-button {
     width: 50%;
   }

   .settings-checkbox-input {
     margin-top: 2px;
   }

   .settings-checkbox-input:hover {
     cursor: pointer;
   }

   .onboard-user-security {
     display: flex;
     flex-direction: column;
     justify-content: left;
     gap: 10px;

     label {
       text-align: left;
     }

     .form-user-roles {
       display: flex;
       justify-content: left;
     }
   }

 }