.card-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 1px solid #4A5463;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background: var(--Dark-Mode-Gradient-Cards, linear-gradient(182deg, #253240 1.42%, rgba(37, 48, 64, 0.40) 15.14%));



    .card-layout-header {
        display: flex;
        justify-content: space-between;
        height: 76px;
        border-bottom: 1px solid #4A5463;
        width: 100%;
        padding: 20px 24px;

        .header-title {
            color: #F7F9F9;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 28px;
        }

    }
}

.card-layout .content{
    position: relative;
}