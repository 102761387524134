@import '../../../../../styles/darktheme/utilities/variables';

.doc-wrapper {
    width: 100%;
    position: relative;
    .table-container.w-full {
        min-height: 30rem;
        
    }
}

.pagination-link {
    color: azure;
    padding: 2px;
}

.active {
    background-color: #131F2E;
    padding: 2px;
}

.search-icon-lg {
    width: 50px;
    max-width: auto;
    margin: 1rem auto;
}

.upload-containter {
    position: relative;
    overflow: hidden;
}

.upload-field {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
}

.filename-container {
    margin-top: 12px;
    margin-left: 5px;
}

.document-table {
    & .document-head {
        position: sticky;
        z-index: 1;
    }

    .document-head th div {
        display: flex;
        align-items: center;
        gap: 5px;
    }

    .document-head th .sort-img-show-hover {
        visibility: hidden;
    }

    .document-head th:hover .sort-img-show-hover {
        visibility: visible;
    }

    .checkbox-container {
        display: block;
        position: relative;
        cursor: pointer;
        font-size: 16px;

        &:hover,
        &:focus {
            background-color: $dark-primary;
        }

        input {
            position: absolute;
            top: -10px !important;
            opacity: 0;
            cursor: pointer;
            width: 20px;
            height: 20px;
        }

        .checkbox-checkmark {
            &::after {
                left: 7px;
                top: 3px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }
    }

    .checkbox-checkmark {
        position: absolute;
        top: -10px !important;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 4px;
        border: 1px solid var(--Dark-mode-300, $dark-light-shade3);
        background: var(--Dark-mode-800, $dark-primary);

        &::after {
            content: "";
            position: absolute;
            display: none;
            border: none;
        }
    }
}

.checkbox-container input:checked~.checkbox-checkmark {
    border-radius: 4px;
    border: 1px solid var(--Primary-Indigo-700, $light-primary-shade2);
    background: var(--Primary-Indigo-600, $light-primary-shade3);
}

.checkbox-container input:checked~.checkbox-checkmark::after {
    display: block;
}

.notify-card {
    .rmdp-container {
        padding: 0px 4px !important;

        .rmdp-input {
            height: 36px !important;
            padding-left: 26px !important;
        }
    }
}

.rmdp-container {
    height: 35px !important;

    svg {
        color: white;
        left: 2.2px;
    }

    .rmdp-wrapper {
        background-color: var(--darkmode-700, #131f2e);
        color: white;

        .rmdp-calendar {
            padding: 20px 24px;

            .rmdp-header {
                font-weight: bold;
                height: 36px;

                .rmdp-header-values {
                    font-family: Mulish;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 24px;
                    text-align: center;

                    color: white;

                }

                .rmdp-arrow {
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    width: 10px;
                    height: 10px;
                }

                .rmdp-arrow-container {
                    margin: 0px 14px;
                }

                .rmdp-arrow-container:hover {
                    background-color: #0094BD;
                }
            }

            .rmdp-week-day {
                color: white;
                font-weight: bold;
                width: 40px;
                height: 40px;
                padding: 10px 8px 10px 8px;
                gap: 0px;
                border-radius: 20px 0px 0px 0px;
                //styleName: Paragraph 1 [P1]/Bold;
                font-family: Mulish;
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
                text-align: center;
            }

            .rmdp-day {
                width: 40px;
                height: 40px;
                padding: 10px 8px 10px 8px;
                gap: 0px;
                border-radius: 20px 0px 0px 0px;
                //styleName: Paragraph 1 [P1]/Medium;
                font-family: Mulish;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                text-align: center;
                color: white;

            }

            .rmdp-deactive {
                color: gray;
            }

            .rmdp-day.rmdp-selected span:not(.highlight),
            .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover {
                background-color: #0094BD;
            }



        }

    }

    .rmdp-border {
        border: 1px solid #4a5463;
    }



    .rmdp-month-picker,
    .rmdp-year-picker {
        background-color: var(--darkmode-700, #131f2e) !important;
    }
}

.delete-modal-page {
    .file-list {
        ul {
            list-style: disc;
            padding: 0px 20px;
            max-height: 150px;
            overflow-y: scroll;
        }

        li {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #b8c5cc !important;
            text-align: center;
            padding: 0px;
            margin: 10px 0px;
        }
    }
}