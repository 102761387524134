.confirmation-content .modal {
  width: max-content;
  padding: 10px;
}

.confirmation-dialog {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.confirmation-message {
  display: flex;
  gap: 10px;
  align-items: center;
}

.confirmation-buttons-content {
  display: flex;
  justify-content: space-between;
  align-self: end;
  width: 110px;
}
.confirmation-button {
  background: #005f7c;
  border: 1px;
  border-radius: 8px;
  color: #fff;
  height: 38px;
  padding: 8px;
  width: 50px;
}

.confirmation-button:hover {
  cursor: pointer;
}
