@import '../../../../styles/darktheme/utilities/variables';

.details-container {
    width: 100%;
    padding: 4px 0px 24px;
    // background-color: $dark-primary;
    background: transparent;
    min-height: 350px;
}

.mx-height {
    max-height: 350px !important;
}

.mx-height.o-flow {
    overflow-y: auto;
}

.min-height {
    min-height: 600px !important;
}

.header-container {
    display: flex;
    border-top: 1px solid #4A5463;
    border-bottom: 1px solid #4A5463;
    padding: 10px 0px;
}

.item-container {
    display: flex;
    border-bottom: 1px solid #4A5463;
    padding: 10px 0px;
}

.header-item {
    // width: 16.6%;
    width: 20%;
    padding: 0px 20px;
}

.trends-container-date-range {
    .filter-modal-container .contain_dropdown {
        width: 188px;
    }
    .asset_event__dropdown-list {
        max-height: 170px;
        overflow-y: scroll;
    }
    .body-input-container {
        margin-bottom: 18px!important;
    }
}