@import '../../../../styles/darktheme/utilities/variables';

.wrapper-location{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ui-button-toolbar{
        border-bottom: 0;
      }
}
.asset-details {
    padding-top: 12px;
    padding-bottom: 12px;
    & .ui-button-toolbar {
        background: transparent;
    }
}
.block{
    display: flex;
    align-items: center;
    margin-right: 26px;
}
.show-alram-traffic{
    display: flex;
    align-items: center;
}
.show-alram-traffic 
{
    .switch{
        margin-left: 18px;
        margin-bottom: 28px;
        margin-top: 0;
    }

}
.map-wrapper{
    border-radius: 8px;
    border: 1px solid var(--Dark-mode-400, #4A5463);
    background: var(--Shades-White, #FFF);
    margin: 12px;
    img{
        height: 300px;
        object-fit: cover;
    }
}

.card.mb-1 {
    margin-bottom: 1rem!important;
}

.block-wrap {
    padding: 1rem 1rem 1rem 0 !important;
}

.asset-alarms {
    & .header {
      display: flex;
      align-items: flex-start;
      & .title {
        border: none;
        padding-left: 0;
      }
      & .normal-text {
        color: var(--Neutral-Grey-200, var(--Neutral-Grey200, $neutral-light-shade3));
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
      }
    }
  }

  .asseListTimeseries {
    width: 30px;
    height: 30px;
    margin: 0 20px 0 10px;
    & .loader-container {
        & .loader {
            width: 30px;
            height: 30px;
        }
    }
  }