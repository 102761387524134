@import '../../../styles/darktheme/utilities/variables';

.asset-control-room-drawer {
  z-index: 99;
  background: #001023;
  border-left: 1px solid #4a5463;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  right: -200%;
  visibility: hidden;
  opacity: 0;
  top: 0;

  &.assetDrawerOpen {
    right: 0;
    visibility: visible;
    opacity: 1;
  }

  &.width-length {
    width: 48%;
  }

  .heading {
    border-bottom: 1px solid #4a5463;
    background-color: var(--Dark-mode-800, #001023);
    position: sticky;
    top: 0%;
    z-index: 9999;
    display: flex;
    gap: 32px;
    padding: 15px 32px;

    .title-text {
      font-size: 23px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: -0.04em;
      text-align: left;
      color: #f7f9f9;
    }
  }

  .drawer-content {
    padding: 32px;
    height: calc(100% - 60px);

    .ui-button-toolbar {
      background: transparent;
      padding: 0;
    }

    .details-section-heading {
      padding: 30px 0 20px 0;
    }
  }
}

/* @media #{$mediumVerylargetolargeDesktop} {
  .asset-control-room-drawer {
    height: calc(100vh* 0.66)
  }
} */

@media (max-width: 1959px) {
  .overflow-hide {
    overflow-y: unset;
    position: relative;

    &.width-length {
      width: 50%;
    }
  }
}