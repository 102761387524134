@import "../../../../styles/darktheme/utilities/mixins";
@import "../../../../styles/darktheme/utilities/variables";

.security-card {
  width: 100%;
  margin: 15px auto;

  .body {
    padding: $default-rel*2;
    // min-height: calc(100vh - 223px);
  }

  .header {
    gap: 4px;
    padding: $default-rel+0.1rem $default-rel*1.5 !important;

    .title {
      font-weight: 600;
      font-size: $default-rel+0.3rem;
      color: $black;

      h2 {
        margin: 0;
      }
    }
  }

  .security-info {
    display: flex;
    flex-wrap: wrap;

    .protext {
      width: 30%;
      padding-right: $padding-md;
      .text{
        font-size: $font-size;
      }
    }
  }

  .profile-info {
    .protext {
      width: 30%;
      padding-right: $padding-md;

      .text {
        font-size: $font-size;
      }
    }
  }

  .card {
    width: 50%;
    padding: $padding-md * 1.5;
    max-width: 45%;

    .form-row {
      display: flex;
      flex-wrap: wrap;
      margin: $root-unit;
      gap: $default-px;

      & button {
        margin-left: $root-unit;
        display: flex;
        align-items: center;
      }

      .img-invert {
        filter: invert(1);
      }
    }
  }

  .form-col {
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 50%;
    padding: 0;

    &.full-col {
      width: $full-unit;
    }

    & label {
      margin: 0 0 $margin-xs;
      font-weight: 500;
    }
  }

  .username-custom-input {
    @include input-styling;
  }

  .custom-input {
    @include input-styling;

    &:focus {
      border-color: $light-primary-shade5;
      outline: 2px solid $light-primary-shade5;
      box-shadow: 0 0 7px $light-primary-shade5;
    }
  }

  .password-form {
    .link-teal {
      margin-top: $margin-md*1.5;
      color: $cyan-dark-shade4;
      font-weight: bold;
    }
  }

  .forgot-group {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .password-form-buttons {
      margin-top: $margin-md*1.5;
      margin-left: $root-unit;
    }
  }
}

.input-placeholder {
  position: relative;

  &>a {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
  }
}

.profile-info {
  &.ui {
    &>div {
      width: 100%;
      max-width: 100%;
    }

    .security-info {
      margin-top: 0.5rem;

      .card {
        width: 100%;
        max-width: 100%;

        .toggle-item {
          .switch {
            margin-bottom: 0;
          }

          margin-bottom: 1.5rem;

          &:last-child {
            margin-bottom: 0;
          }

          p {
            padding-left: 3.5rem;
          }
        }
      }

      .description {
        margin-left: 29px
      }


    }

    & .p-1 {
      & .card {
        padding: 1.25rem;
      }
    }
  }


}

.radio-container {
  display: block;
  position: relative;
  cursor: pointer;
  padding-left: 2rem;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  left: 0;
  top: -10px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.radio-checkmark {
  position: absolute;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid var(--Dark-mode-300, #5d6675);
  background: var(--Dark-mode-800, #001023);
}

.radio-container input:checked~.radio-checkmark {
  border-radius: 50%;
  border: 1px solid var(--Primary-Indigo600, #00789e);
  background: var(--Darkmode-800, #001023);
}

.radio-checkmark::after {
  content: "";
  position: absolute;
  display: none;
  border: none;
}

.radio-container input:checked~.radio-checkmark::after {
  display: block !important;
}

.radio-container .radio-checkmark::after {
  left: 4px;
  top: 4px;
  width: 10px;
  height: 10px;
  background: var(--Primary-Indigo600, #00789e);
  border-radius: 50%;
}