.wellheader {
    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
   
    & .wellheaders {
        &.wellheaders-left {
            margin-left: 3%;
        }
    }

    & .page-title {
        font-family: "Mulish", system-ui;
    }

    & .running-status {
        font-family: "Mulish", system-ui;
    }

    & .linebar {
        position: relative;
        display: inline-block;
        width: 1px;

        &::after {
            content: '';
            width: 1px;
            height: 42px;
            position: absolute;
            left: 0;
            top: 50%;
            background: #A0B1BB;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
        }
    }


    .well-status {
        font-family: Mulish;
    }

    & .running {
        color: #32D583;
        border: 1px solid #32D583
    }

    & .idle {
        color: #F97066;
        border: 1px solid #F97066
    }

    & .blank {
        color: #FDB022;
        border: 1px solid #FDB022
    }
}
.wellheader-right {
    margin-right: 49%;
}