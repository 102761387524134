@import '../../../../../styles/darktheme/utilities/variables';
.field-dropdown.control-room-ticket-dropdown {
    background-color: #001023;
    border: 1px solid #4a5463;
    margin-top: 8px;
    min-height: 50px;
    padding: 12px 13px;
        cursor: pointer;
    display: flex;
        flex-wrap: wrap;
        gap: 6px;
   
    border-radius: 8px;
    .selected-dropdown-ticket {
            padding: 3px 4px 3px 8px;     
            display: flex;      
            // margin: 0px 6px;
            align-items: center;
            flex-direction: row;
            justify-content: flex-start;
            border: 1px solid var(--Neutral-Grey-400, #91A4AA);
            background: var(--Dark-mode-600, #253040);
            color: #fff;
            gap: 4px;
            border-radius: 4px;
            font-family: Mulish;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
       
      
    }

    .dropdown-field {
        display: flex;
        top: 100%;
        left: 0;
        background-color: #001023;
        border: 1px solid #4a5463;
        width: 100%;
        z-index: 10;
        border-radius: 8px;
        display: none;
        max-height: 250px; 
        overflow-y: auto; 
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    }

    .ticket-filter-option {
        margin-top: 10px;
        padding: 12px 20px;
        width: 100%;
        display: flex;
        flex-direction: column; 
        gap: 10px; 
        align-items: flex-start;
        justify-content: flex-start;
        font-weight: 400;
        font-size: 16px;
        color: #F7F9F9;
        cursor: pointer;
    
        &:hover {
            background: var(--darkmode-700, #131f2e); 
               }
    }
    
}










