@import '../../../../styles/darktheme/utilities/variables';

.equipment-container {
  display: flex;
  min-height: calc(100vh - 115px); // Adjust the height as needed for your header/footer
  background: var(--Darkmode-800, #001023);

  .profile-card {
    width: 100%;
    // margin-bottom: 20px;
  }

  .equipment-details-container {
    display: flex; // Ensure this container is also a flexbox
    // width: 70%; // Takes the remaining space
    flex-direction: column; // Align children horizontally
    margin: 20px 24px;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    border: 1px solid var(--Dark-mode-400, #4A5463);
    border-radius: 8px 8px 0 0;
    height: 100%;

    .equipment-details {
      &__header {
        display: flex;
        height: 65px;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        flex-shrink: 0;
        align-self: stretch;
        border-bottom: 1px solid var(--darkmode-400, #4A5463);
        padding: 20px 24px;
      }

      &__text {
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
        font-family: Mulish;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
      }

      &__card-contianer {
        border: 1px solid var(--darkmode-400, #4A5463);
        border-radius: 8px;
        margin: 0 24px 16px 24px;
        width: 94%;
      }

      &__detail-card {
        display: flex;
        height: 44px;
        align-items: center;
        flex-direction: column;
        flex: 1 0 0;
        width: 100%;
        height: 100%;
      }

      &__details {
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding: 9px 12px;
        border-bottom: 1px solid #4a5463;
        width: 100%;
        height: 44px;
        // pointer-events: none;

        &:last-child {
          border-bottom: none;
        }

        &.active-detail {
          background: var(--Dark-mode-800, #001023);
          color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
          ;
        }
      }
    }


    .equipment-titles {
      width: 50%; // Half of the equipment details container
      //overflow-y: auto; // Allows scrolling if there are many titles
      border-right: 1px solid $neutral-light-shade3;
      color: white !important;
      margin-top: 20px !important;
    }

    .selected-equipment-details {
      width: 50%; // The other half for showing selected equipment details
      overflow-y: auto;
      padding: 1rem;
    }
  }

  .equipment-color {
    color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
    // pointer-events: none;
    margin-top: 20px;
    padding: 0 24px 20px 24px;
    span {
      color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
      font-family: Mulish;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
  }
}

.equipment-main-container {
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--darkmode-400, #4A5463);
  margin: 20px 24px;
  overflow: hidden;
  height: calc(100vh - 115px);

  .equipment-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 100%;
    width: 400px;
    border-right: 1px solid var(--Dark-mode-400, #4A5463);
    background: var(--Dark-mode-700, #131f2e);

    & .equipment-list-container {
      display: flex;
      align-items: center;
      align-self: stretch;
      flex-direction: column;
      width: 400px;

      & .equipment-list-item {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid var(--Dark-mode-400, #4A5463);
        padding: 12px 24px;
        transition: all 0.2s ease-in-out;
        width: 100%;
        max-width: 100%;
        position: relative;
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
        font-family: Mulish;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        cursor: pointer;

        &:hover,
        &:focus {
          background: var(--Dark-mode-400, #4A5463);
        }

        &:first-child {
          border-top-left-radius: 8px;
        }
      }

      .namelabel {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 0;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }

      .namedata {
        font-weight: 700;
        font-size: 1.02rem;
        word-wrap: break-word;
      }

      .info {
        word-wrap: break-word;
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .equipment-details {
    width: 100%;
    height: 100%;
    .equipment-loader {
      height: 100%;
      .loader-container {
        margin-top: -20px;
      }
    }

    & .error-image-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      & .main-text {
        color: $dark-text-primary;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        padding-bottom: 8px;
      }
      & .normal-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $neutral-light-shade3;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}

.active-list {
  background: var(--darkmode-400, #4A5463) !important; // Assumed selection color
  color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #F7F9F9));
  border-left: 3px solid #DC4405;
}

.equipment-details-container .equipment-titles .detail.active-detail {
  background-color: #001023;
  /*  background for the active item */

}

.item-details {
  background-color: #001023;
}