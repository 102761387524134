.group-status-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 8px;
  // border: 1px solid var(--neutral-grey-50, #e7ecee);
  background: var(--shades-white, #fff);
  /* Shadows/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  // padding-bottom: 20px;
  margin-left: 68px;
  width: calc(100% - 68px);
}

.chevron-vertical {
  height: 15px;
}

.group-status-header {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  display: flex;
  background: var(--Darkmode-700, #131F2E);

}

.group-status-header-admin{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.group-status-header-user{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.group-status-content {
  padding: 20px 24px 0px 24px;
  align-items: flex-start;
  gap: 16px;
}

.group-status-frame {
  display: flex;
  align-items: center;
  gap: 53px;
  flex: 1 0 0;
}

.text-and-badge {
  display: flex;
  width: 1111.962px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
}

.text {
  flex: 1 0 0;
  color: var(--neutral-grey-800, #191f23);
  /* Paragraph 3 [P3]/Semibold */
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  /* 155.556% */
}

.divider {
  display: flex;
  height: 1px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: var(--neutral-grey-100, #d0d8dd);
}

.group-status-sub-header {
  display: flex;
  padding: 0px 24px;
  justify-content: space-between;
  align-self: stretch;
}

.tabs {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.options {
  display: flex;
  gap: 8px;
}

.table-container {
  overflow: auto;
  border-radius: 4px;
  border: 1px solid var(--neutral-grey-100, #d0d8dd);
  margin: 2px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  // max-height: 500px;
  max-height: calc(100vh - 680px);
}
.table-header-cell {
  height: 44px;
  padding: 12px 8px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 4px 0px 0px 0px;
  border-bottom: 1px solid var(--neutral-grey-100, #d0d8dd);
  background: var(--neutral-grey-25, #f7f9f9);
}

.table-header-item {
  align-items: center;
  gap: 8px;
  // min-width: 80px;
  padding-right: 20px;
  //styleName: Paragraph 1 [P1]/Regular;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.table-row-name {
  height: 44px;
  padding: 12px 8px;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  align-self: stretch;
  border-bottom: 1px solid var(--neutral-grey-100, #d0d8dd);
  font-size: 12px;
  min-width: 100px;
}
.table-row-link {
  color: #00789e;
  transform: rotate(-0.11deg);
  text-decoration: none;
  //styleName: Paragraph 1 [P1]/Bold;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.table-row-link:hover {
  color: #005f7c;
  transform: rotate(-0.11deg);
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.table-row-general {
  height: 44px;
  padding: 12px 8px;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--neutral-grey-100, #d0d8dd);
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b5e68;
  min-width: 70px;
  max-width: 100px;
}

.table-row-alarms {
  height: 44px;
  padding: 12px 8px;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--neutral-grey-100, #d0d8dd);
  padding: 12px 8px;
}

.well-table {
  border-collapse: collapse !important;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  width: 100%;
}
.alarm-badges {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  flex-wrap: wrap;
  max-width: 150px;
}
.badge {
  display: flex;
  padding: 2px 10px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--error-red-700, #b42318);
  background: var(--error-red-50, #fef3f2);
  mix-blend-mode: multiply;
}
.badge-text {
  height: 20px;
  display: block;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #b42318;
}
.vertical-divider {
  border: 1px solid #a0b1bb;
}

.sort-image {
    vertical-align: middle;
}
