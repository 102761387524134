.ticket-status {
    .dropdown {
        position: relative;
        font-size: 18px;
        font-weight: 500;
        color: #191f23;
        margin: 0px !important;
        width: 100%;

        &.disabled {
            .dropdown-btn {
                background-color: #253040;
                color: #fff;
                height: 50px;
                cursor: not-allowed;
                border: none;
            }
        }
    }

    .dropdown .dropdown-btn {
        display: flex;
        flex-direction: row;
        padding: 10px 12px;
        height: 50px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--neutral-grey-100, #3b4656);
        background: var(--darkmode-800, #001023);
        color: #B8C5CC !important;
        font-size: 16px;
        justify-content: space-between;
        /* Shadow/xs */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        cursor: pointer;
        filter: none !important;
    }

    .dropdown-content {
        display: flex !important;
        flex-direction: column;
        align-items: center;
        flex: 1 0 0;
        z-index: 1;
        align-items: flex-start;
        border-radius: 8px;
        border: 1px solid var(--neutral-grey-50, #e7ecee);
        background: var(--darkmode-800, #001023);
        color: #f7f9f9;
        border-color: #4a5463;
        position: absolute;
        box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
            0px 12px 16px -4px rgba(16, 24, 40, 0.08);

        overflow-wrap: break-word;
        font-weight: normal;
        font-size: 14px;
        width: 100%;
        margin-top: 2px;


    }


    .dropdown-content::-webkit-scrollbar {
        width: 8px;
    }

    .dropdown-content::-webkit-scrollbar-track {
        background-color: #1c2838;
    }

    .dropdown-content::-webkit-scrollbar-thumb {
        background-color: #808080;
        border-radius: 6px;
    }

    .dropdown-content .item-selected {
        display: flex;
        background-color: #4a5463;
        justify-content: space-between;
    }

    .dropdown-content .item {
        width: 100%;
        padding: 10px;
        cursor: pointer;
    }

    .dropdown-content .item:hover {
        background: var(--Dark-mode-400, #4a5463) !important;
        ;
    }

}