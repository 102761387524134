@import '../../../styles/darktheme/utilities/variables';

.switch {
  margin-left: 0;
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
  margin-bottom: 12px;
  margin-top: 14px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: background-color 0.3s;
  border-radius: 15px;
  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 1px;
    bottom: 2px;
    background-color: $white;
    transition: transform 0.3s;
    border-radius: 50%;
  }
}

input {
  &:checked {
    + {
      .slider {
        background-color: $light-primary-shade5;
        &:before {
          transform: translateX(15px);
        }
      }
    }
  }
  &:focus {
    + {
      .slider {
        box-shadow: 0 0 1px #4caf50;
      }
    }
  }
}
.switch.off {
  .slider {
    background-color: #ccc;;
  }
  input {
    &:checked {
      + {
        .slider {
          &:before {
            transform: translateX(0);
          }
        }
      }
    }
  }
}

/* Style the h2 label text */
h2 {
  margin-left: 50px;
  margin-top: -32px;
  font-size: 14px;
  font-weight: 700;
  color: black;
}