.schedule-event-container {
    padding: 0px 20px;
    min-height: 58px;
    max-height: auto;
    gap: 15px;
    border-radius: 8px;
    background: linear-gradient(181.1deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%);
    border: 1px solid #4A5463;
    cursor: pointer;

    &.disabled {
        cursor: default !important;

        input {
            pointer-events: none;
            cursor: default !important;
        }

        .toggle-switch {
            pointer-events: none;
            cursor: default !important;
        }
    }

    .event-heading {
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        text-align: left;
        color: #F7F9F9;
    }

    .toggle-switch {
        display: flex;
        flex-direction: row-reverse;
        gap: 10px;

        h2 {
            margin-top: 15px;
        }
    }

    .schedule-content {
        padding-bottom: 20px;

        &.disabled {
            .time-input-field {
                background-color: #131f2e;
                pointer-events: none;
            }
        }
    }

    .manage-asset-dropdown {
        filter: none !important;
    }

    .gas-lock-recovery-container {
        .steps-table {
            border: 1px solid #4A5463;
            border-radius: 8px;

            .table-row {
                height: 44px;
                color: #F7F9F9;
                border-bottom: 1px solid #4A5463;

                .table-col {
                    display: flex;
                    align-items: center;
                    justify-content: start;
                    padding-left: 8px;

                    .backup-container {
                        margin-left: 0px !important;

                        .time-input-field {
                            width: 90% !important;

                            &.disabled {
                                background-color: #131f2e;
                                pointer-events: none;
                            }
                        }
                    }


                }
            }

            .table-row:last-child {
                border-bottom: none;
            }
        }
    }

    .rocking-start-container {
        .rocking-start-action-div {
            .rocking-start-action-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 36px;
                border-radius: 8px;
                gap: 8px;
                padding: 8px 14px;
                background: #00789E;
                border: 1px solid #005F7C;
                font-weight: 700;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0%;
                color: #F7F9F9;

                &.disabled {
                    background: #131F2E;
                    border: 1px solid #4A5463;
                    pointer-events: none;
                    color: #4A5463;
                }
            }
        }
    }
}