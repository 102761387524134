.horizontal-tabs-v2 {
  display: flex;
  padding: 0px 20px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.tab-v2 {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.active-v2 {
  border-radius: 6px;
  background: var(--primary-indigo-25, #f4fbfc);
}
.inactive-v2 {
  border-radius: 6px;
}
