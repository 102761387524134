
.controlRoomSwitcher-box {
    & p {
        &.description {
            color: #B8C5CC;
        }
    }
   
}
.controlRoomSwitcher-switcher {
    & .switch, & h2 {
        margin: 0;
    }
}
