@import '../../styles/darktheme/utilities/variables';

// Details Tab CSS
.details-main-section {
  height: 100px;
  .details-sections {
    max-height: 263px;
  }
  .details-section-heading {
    color: #fff;
    font-size: 20px;
    padding: 15px 0;
  }
  .details-section-table {
    width: 100%;
    tr {
      border-bottom: 1px solid #4a5463;
      .left {
        text-align: left;
        padding: 8px;
      }
      .right {
        text-align: right;
      }
      td {
        color: #fff;
        padding: 8px;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

// Events Tab CSS
.search-sort-filter-container {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  margin: 25px 0;
  .search-container {
    justify-content: left;

    .block {
      margin-right: 0;
    }
  }

  .sort-filter-container {
    display: flex;
    // align-items: center;
    gap: 20px;
    justify-content: right;

    .filter-container {
      position: relative;
      z-index: 8;

      & #filter-btn {
        width: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        height: 44px;
        margin: 0px;
        padding: 10px 16px;
        border-radius: 8px;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        cursor: pointer;
        color: #b8c5cc;
      }
    }

    .sort-container {
      position: relative;
      z-index: 8;

      & #sort-btn {
        width: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        height: 44px;
        margin: 0px;
        padding: 10px 16px;
        border-radius: 8px;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        cursor: pointer;
        color: #b8c5cc;
      }
    }

    .events-filter-modal-container {
      display: flex;
      width: 250px;
      flex-direction: column;
      align-items: flex-start;
      gap: 1px;
      border-radius: 8px;
      border: 1px solid var(--Dark-mode-400, #4a5463);
      background: var(--Darkmode-800, #001023);
      box-shadow:
        0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
      position: absolute;
      z-index: 999;
      top: 46px;
      right: 0px;

      .events-filter-modal-header {
        padding: 12px 16px;
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #f7f9f9));
        /* Paragraph 1 [P1]/Semibold */
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }

      .events-filter-modal-body {
        width: 100%;
        border-top: 1px solid var(--Dark-mode-400, #4a5463);
        border-bottom: 1px solid var(--Dark-mode-400, #4a5463);

        .checkbox-main-container {
          margin: 10px 16px;
        }

        .checkbox-container {
          display: block;
          position: relative;
          cursor: pointer;
          font-size: 16px;
          &:hover,
          &:focus {
            background-color: var(--Dark-mode-800, $dark-primary);
          }
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            width: 20px;
            height: 20px;
          }
          .checkbox-checkmark {
            &::after {
              left: 7px;
              top: 3px;
              width: 5px;
              height: 10px;
              border: solid white;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }
          }
        }
        .checkbox-checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          border-radius: 4px;
          border: 1px solid var(--Dark-mode-300, $dark-light-shade3);
          background: var(--Dark-mode-800, $dark-primary);
          &::after {
            content: '';
            position: absolute;
            display: none;
            border: none;
          }
        }
        .checkbox-label {
          margin-left: 26px;
        }
      }

      .events-filter-modal-footer {
        display: flex;
        width: 100%;
        height: 60px;
        padding: 10px 24px;
        justify-content: end;
        align-items: flex-end;
        gap: 12px;
        flex-shrink: 0;

        .footer-btn {
          color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #f7f9f9));
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
          padding: 10px 16px;
          border-radius: 8px;
          background: var(--Dark-mode-800, #001023);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          border: 1px solid var(--Dark-mode-400, #4a5463);
        }

        .footer-btn:last-child {
          background: var(--Primary-Indigo-600, #00789e);
        }

        .disable-btn {
          cursor: not-allowed;
        }
      }
    }

    // Equipment Tab CSS:

    .events-sort-modal-container {
      display: flex;
      width: 220px;
      flex-direction: column;
      align-items: flex-start;
      gap: 1px;
      border-radius: 8px;
      border: 1px solid var(--Dark-mode-400, #4a5463);
      background: var(--Darkmode-800, #001023);
      box-shadow:
        0px 4px 8px -2px rgba(16, 24, 40, 0.1),
        0px 2px 4px -2px rgba(16, 24, 40, 0.06);
      position: absolute;
      z-index: 999;
      top: 46px;
      right: 0px;

      .events-sort-modal-header {
        padding: 12px 16px;
        color: var(--Neutral-Grey-25, var(--Neutral-Grey25, #f7f9f9));
        /* Paragraph 1 [P1]/Semibold */
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
      }

      .events-sort-modal-body {
        width: 100%;
        border-top: 1px solid var(--Dark-mode-400, #4a5463);
        border-bottom: 1px solid var(--Dark-mode-400, #4a5463);
        padding: 5px 10px;
        .sort-option-list {
          color: #fff; 
          line-height: 40px; 
          font-size: 16px; 
          cursor: pointer;
        }
      }
    }
  }
}
 
.event-list-main-section {
  height: 10px;
.events-list-section {
  padding-bottom: 30px;

  .stepper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .step {
    position: relative;
    width: 90%;
    margin-left: 30px;
  }

  .step-indicator {
    position: absolute;
    left: -30px;
    top: 18px;
    border-radius: 50%;
  }

  .step-line {
    position: absolute;
    left: -19px;
    top: 30%;
    width: 3px;
    height: calc(100% + 20px);
    background-color: #b8c5cc;
    z-index: 0;
  }

  .event-card {
    border-bottom: 1px solid #b8c5cc;
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 0;

    &:last-child {
      border-bottom: none;
    }

    .event-header {
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      display: flex;
      justify-content: space-between;
      color: #f4fbfc;

      .event-date {
        font-size: 12px;
      }
    }

    .event-type {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-top: 8px;
      color: #00789e;
    }

    .event-description {
      margin-top: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .no-event-found {
    color: #b8c5cc;
    padding-left: 5px;
    font-size: 15px;
  }
}
.chart-loader {
  position: absolute;
  top: 50%;
}
.no-data-found {
  top: 40%;
  position: absolute;
  width: 100%;
  .heading {
    border: 0;
  }
}
}

// Equipment Tab CSS:

.equipment-section {
  color: #b8c5cc;
  border: 1px solid #4a5463;
  background: linear-gradient(181deg, #253240 -16.87%, rgba(37, 48, 64, 0.4) 35.14%);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: 10px;
  margin-top: 24px;
  .esp-equipment {
    font-size: 16px;
    color: #f4fbfc;
    padding: 18px 24px 10px 24px;
    border-bottom: 1px solid var(--Dark-mode-400, #4a5463);
  }
  .accordion {
    width: 100%;
    // max-width: 600px;
    margin: 0 auto;
    border-radius: 8px;
    overflow: hidden;
    font-family: Arial, sans-serif;
  }

  .accordion-item {
    border-bottom: 1px solid var(--Dark-mode-400, #4a5463);
    .rotate-180 {
      rotate: 180deg;
    }
  }

  .accordion-heading {
    padding: 12px 24px 12px 24px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;
    color: #f4fbfc;
    border-bottom: 1px solid var(--Dark-mode-400, #4a5463);
  }

  .accordion-title {
    flex-grow: 1;
  }

  .accordion-icon {
    font-size: 18px;
    transition: transform 0.3s ease;
  }

  .accordion-icon.open {
    transform: rotate(180deg);
  }

  .accordion-body {
    font-size: 14px;
    color: #f4fbfc;
    .description {
      font-size: 12px;
      padding: 11px 14px 11px 24px;
      line-height: 20px;
      color: #f4fbfc;
      border-bottom: 1px solid var(--Dark-mode-400, #4a5463);
      pointer-events: none; 
      // &:hover {
      //   cursor: pointer;
      // }
    }
    .loader-container {
      padding-top: 5px;
    }
    .no-data-found {
      color: #b8c5cc;
      font-size: 15px;
      text-align: center;
      padding-top: 20px;
      color: #f4fbfc;
      // border-bottom: 1px solid var(--Dark-mode-400, #4a5463);
    }
  }
}

// Map Section CSS:
.map-section {
  .weather-toggle-container {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 8px;
    .toggle-switch {
      display: inline-grid;
    }
  }
}

.details-main-section,
.events-main-container,
.equipment-section {
  .chart-loader {
    position: absolute;
    top: 50%;
    width: 100%;
  }
  .no-data-found {
    .heading {
      border: 0;
    }
  }
}
