.onboarding-page-layout {
  min-height: 100vh;
  height: 100vh;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.onboard-modal {
  max-height: calc(90vh - 100px);

  .onboard-modal-header {
    height: 63px;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 24px 20px;
    border-bottom: 1px solid #4A5463;

    .onboard-modal-title {
      color: #F7F9F9;
      font-size: 19px;
      font-weight: 600;
      line-height: 23px;
      letter-spacing: -0.02em;
      text-align: left;
    }
  }

  .onboard-modal-content {
    max-height: calc(90vh - 250px);
    overflow-y: auto;
    padding: 23px;
  }

  .onboard-modal-footer {
    border-top: 1px solid #4A5463;
    height: auto;
    padding: 20px 24px;
    display: flex;
    justify-content: end;
    gap: 10px;
    align-items: center;

    .add-user-button {
      background: #005f7c;
      border: 1px;
      border-radius: 8px;
      color: #fff;
      gap: 8px;
      height: 48px;
      padding: 10px 16px;
    }

    .add-user-button:hover {
      cursor: pointer;
    }

    .add-user-button:disabled {
      background-color: #6c757d;
      color: #fff;
      border-color: #6c757d;
      cursor: not-allowed;
      opacity: 0.6;
    }

    .cancel-button {
      border: 1px solid #5d6675;
      background: var(--darkmode-800, #001023);
      border-radius: 8px;
      color: #fff;
      height: 48px;
      padding: 10px 16px;

      &.disabled {
        background: #131F2E;
        cursor: not-allowed;
      }
    }

    .cancel-button:hover {
      cursor: pointer;
    }

  }
}

.onboarding-form {

  .mandatory-fields-message {
    font-size: 14px;
    color: #888;
    margin: 20px 0px;
    display: flex;
    justify-content: left;
  }

  .form-mandatory-message {
    display: flex;
    justify-content: space-between;
  }


  .onboard-form-group {
    margin-bottom: 10px;

    .date-time-picker .date-time-container .rmdp-container {
      width: 100% !important;

      .left-calendar {
        left: -104% !important;
        top: -50px !important;
      }

      .right-calendar {
        left: 91% !important;
        top: -50px !important;
      }
    }
  }

  .onboard-form-group label {
    display: block;
    font-family: Mulish;
    font-size: 14px;
    font-weight: var(--font-weight-bolder);
    letter-spacing: 0;
    line-height: 20px;
    margin: 5px 0;
    text-align: left;
  }

  .onboard-form-group input {
    color: #fff;
    width: 100%;
    padding: 10px 14px 10px 14px;
    gap: 8px;
    border-radius: 8px;
    border: 1px;
    border: 1px solid #3b4656;
    background-color: #001023;
    //float: left;

    &:disabled {
      background-color: #253040;
      color: #fff;
      cursor: not-allowed;
      border: 1px solid #3b4656;
      opacity: 0.75;
    }
  }

  .onboarding-checkbox-container {
    display: flex;
    gap: 10px;
  }

  .onboard-form-header {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1920px) {
  .add-user-container .onboard-modal-overlay {
    z-index: 999;
  }
  .onboard-modal {
    max-height: calc(100vh - 45px);    
    .add-user-form.onboarding-form {
      .onboard-modal-header {
          height: auto;
          padding: 12px 16px;
          min-height: 50px;
      }
    .onboard-modal-content {
        padding: 15px 16px 10px;
        max-height: calc(100vh - 200px);
        .gap-4 {
          row-gap: 0;
        }
      }
      .onboard-form-group {
        margin-bottom: 8px;
        label {
            font-size: 13px;
            line-height: 18px;
            margin: 0 0 2px;
        }
        input {
          padding: 10px 14px 10px 14px !important;
          min-height: 40px;
        }
      }
      .onboard-user-security {
        margin-top: 10px;
      }
      .mandatory-fields-message {
        margin: 10px 0;
      }
    }
    .onboard-modal-footer {
      padding: 10px 16px;
    }
    .onboard-modal-footer {
      .add-user-button,  .cancel-button {
        height: 35px;
        padding: 5px 10px;
        font-size: 12px;
      }
    }
   
  }
  .onboarding-container .add-customer-button {
    height: 35px  !important;
      padding: 5px 10px !important;
      font-size: 12px !important;
  }
}

@media (max-width: 1550px) {
  .onboard-modal .add-user-form.onboarding-form .onboard-form-group label {
    font-size: 12px;
    line-height: 16px;
  }
  .add-user-container .onboard-user-security label {
    font-size: 12px;
  }
  .onboard-modal .add-user-form.onboarding-form .mandatory-fields-message {
    margin: 6px 0;
  }
}

@media (max-width: 1450px) {
  .onboarding-form .dropdown .dropdown-btn {
    height: 32px !important;
    font-size: 12px !important;
    border-radius: 5px !important;
  }
  .onboarding-form .onboard-form-group input, .onboard-modal .add-user-form.onboarding-form .onboard-form-group input {
    padding: 6px 14px 6px 14px !important;
    border-radius: 5px;
    min-height: 32px;
  }
  .onboard-modal .add-user-form.onboarding-form .onboard-modal-header {
    padding: 7px 16px;
    min-height: 40px;
  }
  .onboard-modal .add-user-form.onboarding-form .onboard-user-security {
    margin-top: 3px;
  }
  .onboard-modal .add-user-form.onboarding-form .onboard-modal-content {
    max-height: calc(100vh - 145px);
}
  .add-user-container .onboard-modal-footer .add-user-button, .add-user-container .onboard-modal-footer .cancel-button {
    height: 30px !important;
    font-size: 12px !important;
    line-height: 12px !important;
  }
  .add-user-container .onboard-modal-overlay .modal {
    min-height: 90%;
  }
}